import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import PropTypes from "prop-types"
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { withRouter, Link } from "react-router-dom";
import { editGtwo, getGtwo, getOccm, editOccm } from '../../../../actions/api';

const animatedComponents = makeAnimated()

const compManufacturer = [
    { label: "Hamilton Sundstrand", value: "Hamilton Sundstrand" },
    { label: "Honeywell International", value: "Honeywell International" },
    { label: "Pratt&Whitney Canada", value: "Pratt&Whitney Canada" },
    { label: "Eaton Aerospace", value: "Eaton Aerospace" },
    { label: "Honeywell ASCA", value: "Honeywell ASCA" },
    { label: "CFM International", value: "CFM International" },
    { label: "BAe Systems", value: "BAe Systems" },
    { label: "Rockwell Collins", value: "Rockwell Collins" },
    { label: "Eldec Corporation", value: "Eldec Corporation" },
    { label: "Sensor Systems", value: "Sensor Systems" },
    { label: "GE Aviation Systems", value: "GE Aviation Systems" },
    { label: "BF Goodrich Rosemount", value: "BF Goodrich Rosemount" },
    { label: "Safran Landing Systems", value: "Safran Landing Systems" },
]

const compClass = [
    { label: "Active", value: "Active" },
    { label: "Obsolete", value: "Obsolete" },
    { label: "Superseded", value: "Superseded" },
    { label: "Alternative", value: "Alternative" },
]

const compClassLinkage = [
    { label: "Subassembly", value: "Subassembly" },
    { label: "Single", value: "Single" },
    { label: "Consumable", value: "Consumable" },
]

const aircraftTypes = [
    { label: "B737-700", value: "B737-700" },
    { label: "B737-800", value: "B737-800" },
    { label: "B737-900", value: "B737-900" },
    { label: "B737-900ER", value: "B737-900ER" },
    { label: "A320-200", value: "A320-200" },
    { label: "ATR72-500", value: "ATR72-500" },
    { label: "ATR72-600", value: "ATR72-600" },
]

const currency = [
    { label: "MYR", value: "MYR" },
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
]


const ViewOccmForm = (props) => {
    const { history } = props;
    const { match: { params } } = props

    const [form, setForm] = useState({});

    const compClassChangeDate = form.inst_date ? new Date(form.inst_date) : null;

    useEffect(() => {
        getOccm()
            .then(resp => {
                const filterOccm = resp.find(x => x.id === parseInt(params.id))
                setForm(filterOccm)
            })
    }, []);

    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const id = parseInt(params.id)
        //form.comp_build_out = compRow;
        editOccm(form, id)
            .then(async (resp) => {
                await getOccm()
                    .then(respo => {
                        const filterOccm = respo.find(x => x.id === id)
                        setForm(filterOccm)
                        history.push('/occm_panel')
                    })
            })
            .catch(err => {
                console.log("Error", err);
            })
    }

    const handleCancel = () => {
        history.push('/occm_panel')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>OCCM Form| Component</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="OCCM Form" breadcrumbItem="Component" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Component Part No
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={40} name="comp_part_no" value={form.comp_part_no}
                                                            onChange={e => handleChange(e)}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter Component Part No"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Serial No
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={40} name="serial_no" value={form.serial_no}
                                                            onChange={e => handleChange(e)}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter Serial No"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Description
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={999} name="description" value={form.description}
                                                            onChange={e => handleChange(e)}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter Description"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        POS
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={999} name="pos" value={form.pos}
                                                            onChange={e => handleChange(e)}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter POS"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        INST Date
                                                    </label>
                                                    <div className="col-md-9">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={compClassChangeDate}
                                                            onChange={(date) => setForm({ ...form, inst_date: date })}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        TSN
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={999} name="tsn" value={form.tsn}
                                                            onChange={e => handleChange(e)}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter TSN"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        CSN
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={999} name="csn" value={form.csn}
                                                            onChange={e => handleChange(e)}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter CSN"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        TSI
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={999} name="tsi" value={form.tsi}
                                                            onChange={e => handleChange(e)}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter TSI"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        CSI
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={999} name="csi" value={form.csi}
                                                            onChange={e => handleChange(e)}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter CSI"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row>
                                                    <Col className="d-flex flex-wrap gap-2 justify-content-center">
                                                        <Button className=" btn btn-success save-user w-md"
                                                            type="submit"
                                                        >
                                                            Update
                                                        </Button>
                                                        <Button type="reset" color="secondary" onClick={handleCancel}
                                                            className=" btn btn-secondary save-user w-md">
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

ViewOccmForm.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(ViewOccmForm)