import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
    Label,
    Button,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
  } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class ResponsiveTables extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>CAMO | AEROTREND</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Main Menu" breadcrumbItem="CAMO - Forecast" />

            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {/* <CardTitle className="h4">Example </CardTitle> */}
                    {/* <p className="card-title-desc">
                      This is an experimental awesome solution for responsive
                      tables with complex data.
                    </p> */}
                    <Row>
                        <Col>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns" >
                                    <Table id="tech-companies-1" className="table table-sm m-0 table-striped table-bordered" >
                                        <Thead>
                                            <Tr>
                                                <Th>Aircraft Regn</Th>
                                                <Th data-priority="1">Total FH</Th>
                                                <Th data-priority="3">Total FC</Th>
                                                <Th data-priority="3">Date</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Th>9M-MAA</Th>
                                                <Td>22957</Td>
                                                <Td>9509</Td>
                                                <Td>22-Aug-21</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>                        
                        <Col>
                            <div className="table-rep-plugin">
                                <div className="table-responsive mb-0" data-pattern="priority-columns" >
                                    <Table id="tech-companies-1" className="table table-sm table-striped table-bordered" >
                                        <Thead>
                                            <Tr>
                                                <Th>Engine Model</Th>
                                                <Th data-priority="1">Engine S/N</Th>
                                                <Th data-priority="3">EH/ EC</Th>
                                                <Th data-priority="1">Position</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Th></Th>
                                                <Td></Td>
                                                <Td></Td>
                                                <Td>Engine #01</Td>
                                            </Tr>
                                            <Tr>
                                                <Th></Th>
                                                <Td></Td>
                                                <Td></Td>
                                                <Td>Engine #02</Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {/* <Col lg={2}>
                            <div className="col-sm-auto">
                                <button type="button" className="btn btn-light" >
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    Load
                                </button>
                            </div>
                        </Col> */}
                        <Col lg={4}>
                            <div className="col-sm-auto">
                                <div className="row">
                                    <label htmlFor="example-date-input" className="col-md-5 col-form-label" >
                                        Show up to: Date
                                    </label>
                                    <div className="col-md-7">
                                        <input className="form-control" type="date"
                                            defaultValue="2021-08-26" id="example-date-input"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="col-sm-auto">
                                <div className="row">
                                    <label htmlFor="example-text-input" className="col-md-5 col-form-label" >
                                        Avg Utilization FH=
                                    </label>
                                    <div className="col-md-7">
                                        <input className="form-control" type="text" defaultValue="" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="col-sm-auto">
                                <div className="row">
                                    <Label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                                        FC=
                                    </Label>
                                    <div className="col-md-9">
                                        <Input className="form-control" id="autoSizingInput" type="text" defaultValue="" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {/* <Col lg={8}>
                            <Form className="row gy-2 gx-3 align-items-center"> */}
                                {/* <div className="col-sm-auto">
                                    <button type="button" className="btn btn-light" >
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                        Load
                                    </button>
                                </div> */}
                                {/* <div className="col-sm-auto">
                                    <div className="row">
                                        <label htmlFor="example-date-input" className="col-md-5 col-form-label" >
                                            Due as of: Date
                                        </label>
                                        <div className="col-md-7">
                                            <input className="form-control" type="date"
                                                defaultValue="2019-08-19" id="example-date-input"
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-auto">
                                    <div className="row">
                                        <label htmlFor="example-date-input" className="col-md-5 col-form-label" >
                                            Show up to: Date
                                        </label>
                                        <div className="col-md-7">
                                            <input className="form-control" type="date"
                                                defaultValue="2021-08-26" id="example-date-input"
                                            />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-auto">
                                    <Label className="visually-hidden" htmlFor="autoSizingInput">FH</Label>
                                    <Input type="text" className="form-control" id="autoSizingInput" placeholder="FH" />
                                </div>
                                <div className="col-sm-auto">
                                    <Label className="visually-hidden" htmlFor="autoSizingInput">FL</Label>
                                    <Input type="text" className="form-control" id="autoSizingInput" placeholder="FL" />
                                </div> */}
                                {/* <div className="col-sm-auto">
                                    <div className="row">
                                        <label htmlFor="example-text-input" className="col-md-5 col-form-label" >
                                            Avg Utilization FH=
                                        </label>
                                        <div className="col-md-7">
                                            <input className="form-control" type="text" defaultValue="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="row">
                                        <Label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                                            FC=
                                        </Label>
                                        <div className="col-md-9">
                                            <Input className="form-control" id="autoSizingInput" type="text" defaultValue="" />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-auto">
                                    <div className="row">
                                        <Label htmlFor="example-text-input" className="col-md-3 col-form-label" >
                                            Per:
                                        </Label>
                                        <div className="col-md-9">
                                            <Input className="form-control" id="autoSizingInput" type="text" defaultValue="" />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-auto">
                                    <div className="mb-3 row">
                                        <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                            FH:
                                        </label>
                                        <div className="col-md-10">
                                            <input className="form-control" type="text" defaultValue="FH" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="mb-3 row">
                                        <label htmlFor="example-text-input" className="col-md-2 col-form-label" >
                                            FL:
                                        </label>
                                        <div className="col-md-10">
                                            <input className="form-control" type="text" defaultValue="FL" />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="col-sm-auto">
                                    <Label className="visually-hidden" htmlFor="autoSizingInput">Name</Label>
                                    <Input type="text" className="form-control" id="autoSizingInput" placeholder="Jane Doe" />
                                </div>
                                <div className="col-sm-auto">
                                    <Label className="visually-hidden" htmlFor="autoSizingInputGroup">Username</Label>
                                    <InputGroup className="me-sm-3">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>@</InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" className="form-control" placeholder="Username" />
                                    </InputGroup>
                                </div>
                                
                                <div className="col-sm-auto">
                                    <div className="form-check">
                                    <Input className="form-check-input" type="checkbox" id="autoSizingCheck" />
                                    <Label className="form-check-label" htmlFor="autoSizingCheck">
                                        Remember me
                                    </Label>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <Button type="submit" color="primary" className="w-md">Submit</Button>
                                </div> */}
                                {/* </Form>
                        </Col> */}
                    </Row>

                    <Row className="mb-4 mt-2">
                        <Col lg={3}>
                            <div className="col-sm-auto">
                                <button type="button" className="btn btn-light" >
                                    <i className="bx bx-shuffle font-size-16 align-middle me-2"></i>
                                    Switch View
                                </button>
                            </div>
                        </Col>
                        {/* <Col lg={2}>
                            <div className="col-sm-auto">
                                <Label className="visually-hidden" htmlFor="autoSizingSelect">Select Task</Label>
                                <Input type="select" className="form-select" id="autoSizingSelect">
                                <option defaultValue>Select Task...</option>
                                <option>Option 1</option>
                                <option>Option 2</option>
                                <option>Option 3</option>
                                </Input>
                            </div>
                        </Col> */}
                        <Col lg={4}>
                            <div className="col-sm-auto">
                                <Label className="visually-hidden" htmlFor="autoSizingSelect">WO/WP Action</Label>
                                <Input type="select" className="form-select" id="autoSizingSelect">
                                <option defaultValue>WO/WP Action</option>
                                <option>Option 1</option>
                                <option>Option 2</option>
                                <option>Option 3</option>
                                </Input>
                            </div>
                        </Col>
                        {/* <Col lg={2}>
                            <div className="col-sm-auto">
                                <Label className="visually-hidden" htmlFor="autoSizingSelect">Others</Label>
                                <Input type="select" className="form-select" id="autoSizingSelect">
                                <option defaultValue>Others</option>
                                <option>Option 1</option>
                                <option>Option 2</option>
                                <option>Option 3</option>
                                </Input>
                            </div>
                        </Col> */}
                        <Col lg={4}>
                            <div className="col-sm-auto">
                                <div className="row">
                                    <Label htmlFor="example-text-input" className="col-md-4 col-form-label" >
                                        Filter:
                                    </Label>
                                    <div className="col-md-8">
                                    <Input type="select" className="form-select" id="autoSizingSelect">
                                        <option defaultValue>Show everything</option>
                                        <option>Option 1</option>
                                        <option>Option 2</option>
                                        <option>Option 3</option>
                                    </Input>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={1}>
                            <div className="col-sm-auto">
                                <button type="button" className="btn btn-light" >
                                    <i className="bx bx-exit font-size-16 align-middle me-2"></i>
                                    Exit
                                </button>
                            </div>
                        </Col>
                        {/* <Col lg={7}>
                            <Form className="row gy-2 gx-3 align-items-center"> */}
                                {/* <div className="col-sm-auto">
                                    <button type="button" className="btn btn-light" >
                                        <i className="bx bx-shuffle font-size-16 align-middle me-2"></i>
                                        Switch View
                                    </button>
                                </div> */}

                                {/* <div className="col-sm-auto">
                                    <Label className="visually-hidden" htmlFor="autoSizingSelect">Select Task</Label>
                                    <Input type="select" className="form-select" id="autoSizingSelect">
                                    <option defaultValue>Select Task...</option>
                                    <option>Option 1</option>
                                    <option>Option 2</option>
                                    <option>Option 3</option>
                                    </Input>
                                </div> */}

                                {/* <div className="col-sm-auto">
                                    <Label className="visually-hidden" htmlFor="autoSizingSelect">WO/WP Action</Label>
                                    <Input type="select" className="form-select" id="autoSizingSelect">
                                    <option defaultValue>WO/WP Action</option>
                                    <option>Option 1</option>
                                    <option>Option 2</option>
                                    <option>Option 3</option>
                                    </Input>
                                </div> */}

                                {/* <div className="col-sm-auto">
                                    <Label className="visually-hidden" htmlFor="autoSizingSelect">Others</Label>
                                    <Input type="select" className="form-select" id="autoSizingSelect">
                                    <option defaultValue>Others</option>
                                    <option>Option 1</option>
                                    <option>Option 2</option>
                                    <option>Option 3</option>
                                    </Input>
                                </div> */}

                                {/* <div className="col-sm-auto">
                                    <div className="row">
                                        <Label htmlFor="example-text-input" className="col-md-4 col-form-label" >
                                            Filter:
                                        </Label>
                                        <div className="col-md-8">
                                        <Input type="select" className="form-select" id="autoSizingSelect">
                                            <option defaultValue>Show everything</option>
                                            <option>Option 1</option>
                                            <option>Option 2</option>
                                            <option>Option 3</option>
                                        </Input>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="col-sm-auto">
                                    <button type="button" className="btn btn-light" >
                                        <i className="bx bx-exit font-size-16 align-middle me-2"></i>
                                        Exit
                                    </button>
                                </div> */}
                            {/* </Form>
                        </Col> */}
                    </Row>

                    <div className="table-rep-plugin">
                      <div
                        className="table-responsive mb-0"
                        data-pattern="priority-columns"
                      >
                        <Table responsive style={{OverflowX:"auto"}}
                          id="tech-companies-1"
                          className="table table-bordered table-sm m-0"
                        >
                          {/* <Thead>
                            <Tr>
                              <Th rowspan='2'>Tack ID</Th>
                              <Th data-priority="1" colspan='2' className="text-center">SI/C SI</Th>
                              <Th data-priority="3">Others</Th>
                              <Th data-priority="1">Hours</Th>
                              <Th data-priority="3">Cycles</Th>
                              <Th data-priority="1">Date</Th>
                              <Th data-priority="3">Others</Th>
                              <Th data-priority="1">Hours</Th>
                              <Th data-priority="3">Cycles</Th>
                            </Tr>
                          </Thead> */}
                          <Tbody style={{OverflowX:"auto"}}>
                            <Tr>
                              <Th rowspan='2' className="text-center"></Th>
                              <Th rowspan='2' className="text-center">TASK ID</Th>
                              <Th data-priority="1" colspan='5' className="text-center"></Th>
                              <Th data-priority="3" colspan='3' className="text-center">Threshold</Th>
                              <Th data-priority="1" colspan='3' className="text-center">Repeat</Th>
                              <Th data-priority="3" colspan='3' className="text-center">Last Done</Th>
                              <Th data-priority="1" colspan='3' className="text-center">Next Due</Th>
                              {/* <Th data-priority="3" rowspan='2' className="text-center">Remarks</Th> */}
                            </Tr>
                            <Tr>
                              <Th data-priority="1">AMM REF</Th>
                              <Th data-priority="3">DESCRIPTION</Th>
                              <Th data-priority="1">PART NO</Th>
                              <Th data-priority="3">SERIAL NO</Th>
                              <Th data-priority="1">LOCATION</Th>
                              <Th data-priority="3">FH</Th>
                              <Th data-priority="1">FC</Th>
                              <Th data-priority="3">Days</Th>
                              <Th data-priority="3">FH</Th>
                              <Th data-priority="1">FC</Th>
                              <Th data-priority="3">Days</Th>
                              <Th data-priority="3">FH</Th>
                              <Th data-priority="1">FC</Th>
                              <Th data-priority="3">Date</Th>
                              <Th data-priority="3">FH</Th>
                              <Th data-priority="1">FC</Th>
                              <Th data-priority="3">Date</Th>
                              {/* <Th data-priority="6">Remarks</Th> */}
                            </Tr>
                            <Tr>
                              <Th>
                                {/* <div className="form-check font-size-16"> */}
                                    <input type="checkbox" className="form-check-input" />
                                    {/* <label className="form-check-label" />
                                </div> */}
                              </Th>
                              <Th>27-102-00</Th>
                              <Td>12-22-41-600</Td>
                              <Td>{"Lubricate the stabilizer trim actuator and actuator gimbal pins and ballnut."}<br/>
                              {"INTERVAL NOTE: Whichever comes first."} <br/>{"Access: 311BL"}</Td>
                              {/* <Td></Td> */}                              
                              {/* <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>1600</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                              <Td>365</Td>
                              <Td>1600</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                              <Td>365</Td>
                              <Td>22420</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                              <Td>02-Feb-21</Td>
                              <Td>24020</Td>
                              <td className="table-info"></td>
                              {/* <Td></Td> */}
                              {/* <td style={{backgroundColor:"gray"}}>January</td> */}
                              <Td>02-Feb-22</Td>
                            </Tr>
                            <Tr>
                              <Th><input type="checkbox" className="form-check-input" /></Th>
                              <Th>29-230-00</Th>
                              <Td>29-21-00-700</Td>
                              <Td>{"Operationally check the standby rudder system. To include observing low pressure light illumination and then extinguishing."}<br/>
                              {"INTERVAL NOTE: Whichever occurs first."}</Td>
                              {/* <Td></Td>
                              <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>1200</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                              <Td>365</Td>
                              <Td>1200</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                              <Td>365</Td>
                              <Td>22678</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                              <Td>06-Jul-21</Td>
                              <Td>23878</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                              <Td>06-Jul-Feb-22</Td>
                            </Tr>
                            <Tr>
                              <Th><input type="checkbox" className="form-check-input" /></Th>
                              <Th>80-010-01</Th>
                              <Td>80-11-01-200</Td>
                              <Td>{"Detail inspection of the left engine starter magnetic chip detector for metal chips."}<br/>
                              {"Access: 413"}</Td>
                              {/* <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>1600</Td>
                              {/* <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>1600</Td>
                              {/* <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>9070</Td>
                              <Td>18-Feb-20</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                              <Td>10670</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                            </Tr>
                            <Tr>
                              <Th><input type="checkbox" className="form-check-input" /></Th>
                              <Th>80-010-02</Th>
                              <Td>80-11-01-200</Td>
                              <Td>{"Detail inspection of the left engine starter magnetic chip detector for metal chips."}<br/>
                              {"Access: 423"}</Td>
                              {/* <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>1600</Td>
                              {/* <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>1600</Td>
                              {/* <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>9070</Td>
                              <Td>20-Feb-20</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                              <Td>10670</Td>
                              {/* <Td></Td> */}
                              <td className="table-info"></td>
                            </Tr>
                            <Tr>
                              <Th><input type="checkbox" className="form-check-input" /></Th>
                              <Th>34-130-00</Th>
                              <Td>DIS</Td>
                              <Td>{"ISFD Battery Pack Replacement"}</Td>
                              <Td>312BS801-1</Td>
                              <Td>49982</Td>
                              {/* <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>1095</Td>
                              {/* <Td></Td>
                              <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>01-Mar-20</Td>
                              {/* <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>01-Mar-23</Td>
                            </Tr>
                            <Tr>
                              <Th><input type="checkbox" className="form-check-input" /></Th>
                              <Th>26-365-00</Th>
                              <Td>WT</Td>
                              <Td>Cargo Fire Bottle</Td>
                              <Td>473957-4</Td>
                              <Td>9820K</Td>
                              <Td>LH</Td>
                              {/* <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>1825</Td>
                              {/* <Td></Td>
                              <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>15-Sep-18</Td>
                              {/* <Td></Td>
                              <Td></Td> */}
                              <td className="table-info"></td>
                              <td className="table-info"></td>
                              <Td>14-Sep-23</Td>
                            </Tr>
                            {/* <Tr>
                              <Th></Th>
                              <Th></Th>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                              <Td></Td>
                            </Tr> */}
                            {/* <Tr>
                              <Th>
                                YHOO{" "}
                                <span className="co-name">Yahoo! Inc.</span>
                              </Th>
                              <Td>15.81</Td>
                              <Td>12:25PM</Td>
                              <Td>0.11 (0.67%)</Td>
                              <Td>15.70</Td>
                              <Td>15.94</Td>
                              <Td>15.79 x 6100</Td>
                              <Td>15.80 x 17000</Td>
                              <Td>18.16</Td>
                            </Tr> */}
                          </Tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ResponsiveTables
