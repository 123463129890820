import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

//Import config
import { facebook, google } from "../../config"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
import { apiError, loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import aviation from "../../assets/images/aviation.png"
import logo from "../../assets/images/logo.svg"
import lightlogo from "../../assets/images/logo-light.svg"
// import aviationLogo from "../../assets/images/aviation3.jpg"
import AviationLogo from "../../assets/images/section/logo.jpg"
import AeroLogo from "../../assets/images/aerotrendlogo2.png"
import { loginUsers } from "../../actions/api";
import { format } from "prettier"

const Login = (props) => {
  const [form, setForm] = useState({});
  const [errorResponse, setErrResponse] = useState(null);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const response = await loginUsers(form)
    // console.log("response", response)
    // if (response) {
    //   console.log("Olage barthide")
    //   props.history.push('/panelg1');
    // }
    await loginUsers(form)
      .then(resp => {
        console.log("response", resp)
        if (resp.position === 'admin') {
          props.history.push('/users');
        } else if (resp.position === 'System Admin') {
          props.history.push('/panelg1');
        } else {
          props.history.push('/mdashboard');
        }

      })
      .catch(err => console.log(err))
    //console.log("loginResp", response)
    // if (response.error) {
    //   setErrResponse(response.error)
    //   setForm({})
    // } else {
    //   setTimeout(() => {
    //     history.push('/dashboard');
    //   }, 2000);
    // }
  }

  return (
    <React.Fragment>
      <div className="account-pages pt-sm-5"
        style={{ backgroundImage: `url(${AviationLogo})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-9">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome to Aerotrend !</h5>
                        <p>Sign in to continue</p>
                      </div>
                    </Col>
                    <Col className="col-3 align-self-end">
                      <img src={aviation} height="80" width="80" alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={lightlogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={AeroLogo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e) => handleSubmit(e)}
                    >
                      {/* {props.error && props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null} */}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value={form.email}
                          onChange={handleChange}
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value={form.password}
                          onChange={handleChange}
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Log In
                        </button>
                      </div>



                      <div className="mt-5 mb-5 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> Forgot your
                          password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 mb-5 text-center">
                <p>Don&apos;t have an account ?
                  <Link
                    to="register"
                    className="fw-medium text-primary"
                  >
                    Signup Now
                  </Link>
                </p>
                {/* <p>
                    © {new Date().getFullYear()} Aviation. Crafted with
                    <i className="mdi mdi-heart text-danger" /> by Nu-pie
                  </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* </div> */}
    </React.Fragment>
  )
}

Login.propTypes = {
  // apiError: PropTypes.any,
  // error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  // socialLogin: PropTypes.func
}

// const mapStateToProps = state => {
//   const { error } = state.Login
//   return { error }
// }

// export default withRouter(
//   connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
// )
export default withRouter(Login)
