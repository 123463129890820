import React, { Component } from "react"
import MetaTags from 'react-meta-tags';
// import {
//   Container,
// } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | AEROTREND</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Aviation" />
            {/* <h4>Dashboard</h4> */}
            {/* <Row>
              <Col xl="2" sm="4">
                <button type="button" className="btn btn-primary w-lg" >
                  <i className="mdi mdi-download d-block font-size-24" style={{fontSize: '40px'}}></i>
                  Configuration 
                </button>
              </Col>
              <Col xl="2" sm="4">
                <button type="button" className="btn btn-warning w-xl" style={{fontSize: '40px !important'}}>
                  <i className="mdi mdi-download d-block " style={{fontSize: '40px !important'}}></i>
                  Flight Operations
                </button>
              </Col>
              <Col xl="2" sm="4">
                <button type="button" className="btn btn-info w-lg" >
                  <i className="mdi mdi-download d-block font-size-16"></i>
                  Air worthiness
                </button>
              </Col>
              <Col xl="2" sm="4">
                <button type="button" className="btn btn-primary w-lg" >
                  <i className="mdi mdi-download d-block font-size-16"></i>
                  Download
                </button>
              </Col>
              <Col xl="2" sm="4">
                <button type="button" className="btn btn-warning w-lg" >
                  <i className="mdi mdi-download d-block font-size-16"></i>
                  Download
                </button>
              </Col>
              <Col xl="2" sm="4">
                <button type="button" className="btn btn-info w-lg" >
                  <i className="mdi mdi-download d-block font-size-16"></i>
                  Download
                </button>
              </Col>
            </Row> */}
            
            <Row>
              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                        <i className="mdi mdi-cogs font-size-24 text-warning align-middle me-2" />{" "}
                        <span>Configuration</span>
                    </div>
                  </Label>
                </div>
              </Col>

              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                      <i className="mdi mdi-airport font-size-24 text-primary align-middle me-2" />{" "}
                        <span>Flight Operation</span>
                    </div>
                  </Label>
                </div>
              </Col>

              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                      <i className="mdi mdi-airplane-landing font-size-24 text-info align-middle me-2" />{" "}
                        <span>Airworthiness</span>
                    </div>
                  </Label>
                </div>
              </Col>

              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                        <i className="mdi mdi-bitcoin font-size-24 text-warning align-middle me-2" />{" "}
                        <span>Maintenance Control</span>
                    </div>
                  </Label>
                </div>
              </Col>

              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                      <i className="mdi mdi-video-vintage font-size-24 text-primary align-middle me-2" />{" "}
                        <span>Quality Assurance</span>
                    </div>
                  </Label>
                </div>
              </Col>

              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                      <i className="mdi mdi-account-group font-size-24 text-info align-middle me-2" />{" "}
                        <span>Human Resource</span>
                    </div>
                  </Label>
                </div>
              </Col>

              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                        <i className="mdi mdi-bitcoin font-size-24 text-warning align-middle me-2" />{" "}
                        <span>Stores</span>
                    </div>
                  </Label>
                </div>
              </Col>

              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                      <i className="mdi mdi-cart font-size-24 text-primary align-middle me-2" />{" "}
                        <span>Procurement</span>
                    </div>
                  </Label>
                </div>
              </Col>

              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                      <i className="mdi mdi-file-lock-outline font-size-24 text-info align-middle me-2" />{" "}
                        <span>Library Management System</span>
                    </div>
                  </Label>
                </div>
              </Col>

              <Col xl="2" sm="4">
                <div className="mb-3">
                  <Label className="card-radio-label mb-2">
                    {/* <Input type="radio" name="currency" id="buycurrencyoption1"
                      className="card-radio-input" defaultChecked readOnly /> */}
                    <div className="card-radio">
                      <i className="mdi mdi-bitcoin font-size-24 text-info align-middle me-2" />{" "}
                        <span>Accounts</span>
                    </div>
                  </Label>
                </div>
              </Col>


             
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Dashboard;
