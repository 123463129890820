import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import PropTypes from "prop-types"
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { postGtwo, getGtwo } from '../../../actions/api';

const animatedComponents = makeAnimated()

const compManufacturer = [
    { label: "Hamilton Sundstrand", value: "Hamilton Sundstrand" },
    { label: "Honeywell International", value: "Honeywell International" },
    { label: "Pratt&Whitney Canada", value: "Pratt&Whitney Canada" },
    { label: "Eaton Aerospace", value: "Eaton Aerospace" },
    { label: "Honeywell ASCA", value: "Honeywell ASCA" },
    { label: "CFM International", value: "CFM International" },
    { label: "BAe Systems", value: "BAe Systems" },
    { label: "Rockwell Collins", value: "Rockwell Collins" },
    { label: "Eldec Corporation", value: "Eldec Corporation" },
    { label: "Sensor Systems", value: "Sensor Systems" },
    { label: "GE Aviation Systems", value: "GE Aviation Systems" },
    { label: "BF Goodrich Rosemount", value: "BF Goodrich Rosemount" },
    { label: "Safran Landing Systems", value: "Safran Landing Systems" },
]

const compClass = [
    { label: "Active", value: "Active" },
    { label: "Obsolete", value: "Obsolete" },
    { label: "Superseded", value: "Superseded" },
    { label: "Alternative", value: "Alternative" },
]

const compClassLinkage = [
    { label: "Subassembly", value: "Subassembly" },
    { label: "Single", value: "Single" },
    { label: "Consumable", value: "Consumable" },
]

// const aircraftTypes = [
//     { label: "Type 1", value: "Type 1" },
//     { label: "Type 2", value: "Type 2" },
//     { label: "Type 3", value: "Type 3" },
//     { label: "Type 4", value: "Type 4" },
//     { label: "Type 5", value: "Type 5" },
//     { label: "Type 6", value: "Type 6" },
//     { label: "Type 7", value: "Type 7" },
//     { label: "Type 8", value: "Type 8" },
//     { label: "Type 9", value: "Type 9" },
//     { label: "Type 10", value: "Type 10" },
// ]

const aircraftTypes = [
    { label: "B737-700", value: "B737-700" },
    { label: "B737-800", value: "B737-800" },
    { label: "B737-900", value: "B737-900" },
    { label: "B737-900ER", value: "B737-900ER" },
    { label: "A320-200", value: "A320-200" },
    { label: "ATR72-500", value: "ATR72-500" },
    { label: "ATR72-600", value: "ATR72-600" },
]






const aircraftStatus = [
    { label: "New", value: "New" },
    { label: "Leased", value: "Leased" },
    { label: "Option 3", value: "Option 3" },
    { label: "Option 4", value: "Option 4" },
    { label: "Option 5", value: "Option 5" },
]

const noOfEngines = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
]

const apuManufacturer = [
    { label: "APU Maker 1", value: "APU Maker 1" },
    { label: "APU Maker 2", value: "APU Maker 2" },
    { label: "APU Maker 3", value: "APU Maker 3" },
    { label: "APU Maker 4", value: "APU Maker 4" },
    { label: "APU Maker 5", value: "APU Maker 5" },
    { label: "APU Maker 6", value: "APU Maker 6" },
    { label: "APU Maker 7", value: "APU Maker 7" },
    { label: "APU Maker 8", value: "APU Maker 8" },
    { label: "APU Maker 9", value: "APU Maker 9" },
    { label: "APU Maker 10", value: "APU Maker 10" },
]

const apuModels = [
    { label: "APU M1", value: "APU M1" },
    { label: "APU M2", value: "APU M2" },
    { label: "APU M3", value: "APU M3" },
    { label: "APU M4", value: "APU M4" },
    { label: "APU M5", value: "APU M5" },
]

const mainManufacturer = [
    { label: "MainLG Maker 1", value: "MainLG Maker 1" },
    { label: "MainLG Maker 2", value: "MainLG Maker 2" },
    { label: "MainLG Maker 3", value: "MainLG Maker 3" },
    { label: "MainLG Maker 4", value: "MainLG Maker 4" },
    { label: "MainLG Maker 5", value: "MainLG Maker 5" },
    { label: "MainLG Maker 6", value: "MainLG Maker 6" },
    { label: "MainLG Maker 7", value: "MainLG Maker 7" },
    { label: "MainLG Maker 8", value: "MainLG Maker 8" },
    { label: "MainLG Maker 9", value: "MainLG Maker 9" },
    { label: "MainLG Maker 10", value: "MainLG Maker 10" },
]

const mainModels = [
    { label: "MainLG M1", value: "MainLG M1" },
    { label: "MainLG M2", value: "MainLG M2" },
    { label: "MainLG M3", value: "MainLG M3" },
    { label: "MainLG M4", value: "MainLG M4" },
    { label: "MainLG M5", value: "MainLG M5" },
]

const noseManufacturer = [
    { label: "NoseLG Maker 1", value: "NoseLG Maker 1" },
    { label: "NoseLG Maker 2", value: "NoseLG Maker 2" },
    { label: "NoseLG Maker 3", value: "NoseLG Maker 3" },
    { label: "NoseLG Maker 4", value: "NoseLG Maker 4" },
    { label: "NoseLG Maker 5", value: "NoseLG Maker 5" },
    { label: "NoseLG Maker 6", value: "NoseLG Maker 6" },
    { label: "NoseLG Maker 7", value: "NoseLG Maker 7" },
    { label: "NoseLG Maker 8", value: "NoseLG Maker 8" },
    { label: "NoseLG Maker 9", value: "NoseLG Maker 9" },
    { label: "NoseLG Maker 10", value: "NoseLG Maker 10" },
]

const noseModels = [
    { label: "NoseLG M1", value: "NoseLG M1" },
    { label: "NoseLG M2", value: "NoseLG M2" },
    { label: "NoseLG M3", value: "NoseLG M3" },
    { label: "NoseLG M4", value: "NoseLG M4" },
    { label: "NoseLG M5", value: "NoseLG M5" },
]

const engineManufacturer = [
    { label: "Maker 1", value: "Maker 1" },
    { label: "Maker 2", value: "Maker 2" },
    { label: "Maker 3", value: "Maker 3" },
    { label: "Maker 4", value: "Maker 4" },
    { label: "Maker 5", value: "Maker 5" },
    { label: "Maker 6", value: "Maker 6" },
    { label: "Maker 7", value: "Maker 7" },
    { label: "Maker 8", value: "Maker 8" },
    { label: "Maker 9", value: "Maker 9" },
    { label: "Maker 10", value: "Maker 10" },
]

const currency = [
    { label: "MYR", value: "MYR" },
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
]


const PanelGtwoForm = (props) => {
    const { history } = props;
    const [gtwo, setGtwo] = useState([]);
    let gtwoComponent = [];
    const gtwoComp = gtwo?.map(n => n.comp_name)
    gtwoComp.forEach((n, i) => {
        gtwoComponent.push({
            label: n,
            value: n
        })
    })
    const [form, setForm] = useState({});
    console.log("form", form)
    const [compRow, setCompRow] = useState({})
    console.log("compRow", compRow)
    const [thresh, setThresh] = useState({})
    const [threshCount, setThreshCount] = useState({})

    useEffect(() => {
        getGtwo()
            .then(resp => {
                setGtwo(resp)
            })
    }, [])

    function handleThresholdChange(event) {
        const { name, value } = event.target;
        thresh[name] = value.length
        const remain_val = 40 - 35

        if (remain_val <= thresh[name]) {
            setThresh({ ...thresh, [name]: true })
        } else {
            setThresh({ ...thresh, [name]: false })
        }
        setThreshCount({ ...threshCount, [name]: event.target.value.length })
    }

    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    function handleAddRowNested(value) {
        // const modifiedRows = [...rows1]
        // modifiedRows.push({ comp_build_out: '' })
        // setrows1(modifiedRows)
        let modifiedRows = [];
        for (let i = 0; i < value; i++) {
            modifiedRows.push({ subComp: '' })
        }
        setCompRow(modifiedRows)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        form.comp_build_out = compRow;
        postGtwo(form)
            .then(async (resp) => {
                await getGtwo()
                    .then(respo => {
                        setGtwo(respo)
                        history.push('/panelg2')
                    })
            })
            .catch(err => {
                console.log("Error", err);
            })
    }

    const handleCancel = () => {
        history.push('/panelg2')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title> Fleet Configuration | Component List Setup</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Configuration" breadcrumbItem="Component List Setup" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Component Manufacturer
                                                    </label>
                                                    <div className="col-md-9">
                                                        <Select
                                                            value={form.comp_manufacturer}
                                                            onChange={(value) => {
                                                                setForm({ ...form, comp_manufacturer: value })
                                                            }}
                                                            options={compManufacturer}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Component Name
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={40} name="comp_name" value={form.comp_name}
                                                            onChange={e => {
                                                                handleThresholdChange(e)
                                                                handleChange(e)
                                                            }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter Component Name"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                        {thresh.comp_name ? (
                                                            <span className="badgecount badge bg-success">
                                                                {threshCount.comp_name} / 40{" "}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Re-Enter Component Name
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={40} name="confirm_comp_name" value={form.confirm_comp_name}
                                                            onChange={e => { handleChange(e) }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Wrong Component Name"
                                                            validate={{
                                                                required: { value: true },
                                                                match: { value: "comp_name" },
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Component Part No
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={40} name="comp_part_no" value={form.comp_part_no}
                                                            onChange={e => {
                                                                handleThresholdChange(e)
                                                                handleChange(e)
                                                            }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter Component Part No"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                        {thresh.comp_part_no ? (
                                                            <span className="badgecount badge bg-success">
                                                                {threshCount.comp_part_no} / 40{" "}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Re-Enter Component Part No
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={40} name="confirm_comp_part_no" value={form.confirm_comp_part_no}
                                                            onChange={e => { handleChange(e) }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Wrong Part No"
                                                            validate={{
                                                                required: { value: true },
                                                                match: { value: "comp_part_no" },
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        CMM No
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={40} name="cmm" value={form.cmm}
                                                            onChange={e => {
                                                                handleThresholdChange(e)
                                                                handleChange(e)
                                                            }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Enter CMM Number"
                                                            validate={{ required: { value: true } }}
                                                        />
                                                        {thresh.cmm ? (
                                                            <span className="badgecount badge bg-success">
                                                                {threshCount.cmm} / 40{" "}
                                                            </span>
                                                        ) : null}
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Re-Enter CMM
                                                    </label>
                                                    <div className="col-md-9">
                                                        <AvField type="text" maxLength={40} name="confirm_cmm" value={form.confirm_cmm}
                                                            onChange={e => { handleChange(e) }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                            errorMessage="Wrong CMM"
                                                            validate={{
                                                                required: { value: true },
                                                                match: { value: "cmm" },
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Component Classification
                                                    </label>
                                                    <div className="col-md-9">
                                                        <Select
                                                            value={form.comp_class}
                                                            onChange={(value) => {
                                                                setForm({ ...form, comp_class: value })
                                                            }}
                                                            options={compClass}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Component Classification Changed Date
                                                    </label>
                                                    <div className="col-md-9">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.comp_class_change_date}
                                                            onChange={(date) => setForm({ ...form, comp_class_change_date: date })}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Component Class Linkage
                                                    </label>
                                                    <div className="col-md-9">
                                                        <Select
                                                            value={form.comp_class_linkage}
                                                            onChange={(value) => {
                                                                setForm({ ...form, comp_class_linkage: value })
                                                            }}
                                                            options={compClassLinkage}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Row>

                                                {form?.comp_class_linkage?.value === 'Subassembly' ?
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            No of SubComponents
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" maxLength={10} name="sub_comp" value={form.sub_comp}
                                                                onChange={e => {
                                                                    handleChange(e)
                                                                    handleAddRowNested(e.target.value)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                autoComplete="off"
                                                            // errorMessage="Enter Component Part No"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                    : null
                                                }
                                                {form.sub_comp && (compRow || []).map((el, i) => (
                                                    <Row className="mb-3" key={i}>
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            SubComponent
                                                        </label>
                                                        <div className="col-md-9">
                                                            <Select
                                                                value={compRow.subComp}
                                                                onChange={(value) => {
                                                                    const tempValues = [...compRow];
                                                                    tempValues[i] = { ...tempValues[i], subComp: value };
                                                                    setCompRow(tempValues);
                                                                }}
                                                                options={gtwoComponent}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </div>
                                                    </Row>
                                                )
                                                )}


                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Compatibility to Aircraft Model
                                                    </label>
                                                    <div className="col-md-9">
                                                        <Select
                                                            value={form.aircraft_model}
                                                            isMulti={true}
                                                            onChange={(value) => {
                                                                setForm({ ...form, aircraft_model: value })
                                                            }}
                                                            // onChange={(value) => {
                                                            //     setForm({ ...form, aircraft_model: value })
                                                            // }}
                                                            options={aircraftTypes}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Estimated Price
                                                    </label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            value={form.est_cur}
                                                            onChange={(value) => {
                                                                setForm({ ...form, est_cur: value })
                                                            }}
                                                            options={currency}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <AvField type="number" maxLength={10} name="est_price" value={form.est_price}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-3 col-form-label"
                                                    >
                                                        Quotation Price
                                                    </label>
                                                    <div className="col-md-3">
                                                        <Select
                                                            value={form.quote_cur}
                                                            onChange={(value) => {
                                                                setForm({ ...form, quote_cur: value })
                                                            }}
                                                            options={currency}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <AvField type="number" maxLength={10} name="quote_price" value={form.quote_price}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                </Row>

                                                {/* <Row className="mb-3">
                                                    <div className="d-flex flex-wrap gap-2">
                                                        <Button type="submit" color="primary" className="" onClick={handleSubmit}>
                                                            Submit
                                                        </Button>{" "}
                                                        <Button type="reset" color="secondary" className="">
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </Row> */}

                                                <Row>
                                                    <Col className="d-flex flex-wrap gap-2 justify-content-center">
                                                        <Button className=" btn btn-success save-user w-md"
                                                            type="submit"
                                                        >
                                                            Submit
                                                        </Button>
                                                        <Button type="reset" color="secondary" onClick={handleCancel}
                                                            className=" btn btn-secondary save-user w-md">
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

PanelGtwoForm.propTypes = {
    history: PropTypes.object
}

export default PanelGtwoForm