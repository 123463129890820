import React, { useState } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import PropTypes from "prop-types"
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { postGone, getGone } from '../../../actions/api';

const animatedComponents = makeAnimated()


// const apuManufacturer = [
//     { label: "APU Maker 1", value: "APU Maker 1" },
//     { label: "APU Maker 2", value: "APU Maker 2" },
//     { label: "APU Maker 3", value: "APU Maker 3" },
//     { label: "APU Maker 4", value: "APU Maker 4" },
//     { label: "APU Maker 5", value: "APU Maker 5" },
//     { label: "APU Maker 6", value: "APU Maker 6" },
//     { label: "APU Maker 7", value: "APU Maker 7" },
//     { label: "APU Maker 8", value: "APU Maker 8" },
//     { label: "APU Maker 9", value: "APU Maker 9" },
//     { label: "APU Maker 10", value: "APU Maker 10" },
// ]

// const apuModels = [
//     { label: "APU M1", value: "APU M1" },
//     { label: "APU M2", value: "APU M2" },
//     { label: "APU M3", value: "APU M3" },
//     { label: "APU M4", value: "APU M4" },
//     { label: "APU M5", value: "APU M5" },
// ]

// const mainManufacturer = [
//     { label: "MainLG Maker 1", value: "MainLG Maker 1" },
//     { label: "MainLG Maker 2", value: "MainLG Maker 2" },
//     { label: "MainLG Maker 3", value: "MainLG Maker 3" },
//     { label: "MainLG Maker 4", value: "MainLG Maker 4" },
//     { label: "MainLG Maker 5", value: "MainLG Maker 5" },
//     { label: "MainLG Maker 6", value: "MainLG Maker 6" },
//     { label: "MainLG Maker 7", value: "MainLG Maker 7" },
//     { label: "MainLG Maker 8", value: "MainLG Maker 8" },
//     { label: "MainLG Maker 9", value: "MainLG Maker 9" },
//     { label: "MainLG Maker 10", value: "MainLG Maker 10" },
// ]

// const mainModels = [
//     { label: "MainLG M1", value: "MainLG M1" },
//     { label: "MainLG M2", value: "MainLG M2" },
//     { label: "MainLG M3", value: "MainLG M3" },
//     { label: "MainLG M4", value: "MainLG M4" },
//     { label: "MainLG M5", value: "MainLG M5" },
// ]

// const noseManufacturer = [
//     { label: "NoseLG Maker 1", value: "NoseLG Maker 1" },
//     { label: "NoseLG Maker 2", value: "NoseLG Maker 2" },
//     { label: "NoseLG Maker 3", value: "NoseLG Maker 3" },
//     { label: "NoseLG Maker 4", value: "NoseLG Maker 4" },
//     { label: "NoseLG Maker 5", value: "NoseLG Maker 5" },
//     { label: "NoseLG Maker 6", value: "NoseLG Maker 6" },
//     { label: "NoseLG Maker 7", value: "NoseLG Maker 7" },
//     { label: "NoseLG Maker 8", value: "NoseLG Maker 8" },
//     { label: "NoseLG Maker 9", value: "NoseLG Maker 9" },
//     { label: "NoseLG Maker 10", value: "NoseLG Maker 10" },
// ]

// const noseModels = [
//     { label: "NoseLG M1", value: "NoseLG M1" },
//     { label: "NoseLG M2", value: "NoseLG M2" },
//     { label: "NoseLG M3", value: "NoseLG M3" },
//     { label: "NoseLG M4", value: "NoseLG M4" },
//     { label: "NoseLG M5", value: "NoseLG M5" },
// ]

// const engineManufacturer = [
//     { label: "Maker 1", value: "Maker 1" },
//     { label: "Maker 2", value: "Maker 2" },
//     { label: "Maker 3", value: "Maker 3" },
//     { label: "Maker 4", value: "Maker 4" },
//     { label: "Maker 5", value: "Maker 5" },
//     { label: "Maker 6", value: "Maker 6" },
//     { label: "Maker 7", value: "Maker 7" },
//     { label: "Maker 8", value: "Maker 8" },
//     { label: "Maker 9", value: "Maker 9" },
//     { label: "Maker 10", value: "Maker 10" },
// ]

// const engineModels = [
//     { label: "Model 1", value: "Model 1" },
//     { label: "Model 2", value: "Model 2" },
//     { label: "Model 3", value: "Model 3" },
//     { label: "Model 4", value: "Model 4" },
//     { label: "Model 5", value: "Model 5" },
// ]


const aircraftTypes = [
    { label: "B737-700", value: "B737-700" },
    { label: "B737-800", value: "B737-800" },
    { label: "B737-900", value: "B737-900" },
    { label: "B737-900ER", value: "B737-900ER" },
    { label: "A320-200", value: "A320-200" },
    { label: "ATR72-500", value: "ATR72-500" },
    { label: "ATR72-600", value: "ATR72-600" },
]

const aircraftStatus = [
    { label: "New", value: "New" },
    { label: "Used", value: "Used" },
]

const noOfEngines = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
]

const engineManufacturer = [
    { label: "CFM International", value: "CFM International" },
    { label: "Pratt&Whitney Canada", value: "Pratt&Whitney Canada" }
]

const engineModels = [
    { label: "CFM56-7B24", value: "CFM56-7B24" },
    { label: "CFM56-7B26", value: "CFM56-7B26" },
    { label: "CFM56-7B27", value: "CFM56-7B27" },
    { label: "PWC127C", value: "PWC127C" },
]

const engineStatus = [
    { label: "New", value: "New" },
    { label: "Leased", value: "Leased" },
]

const apuManufacturer = [
    { label: "Honeywell International", value: "Honeywell International" }
]

const apuModels = [
    { label: "GTCP131-9B", value: "GTCP131-9B" },
]

const mainManufacturer = [
    { label: "BF Goodrich", value: "BF Goodrich" },
    { label: "Safran Landing Systems", value: "Safran Landing Systems" },
]

const mainModelsLeft = [
    { label: "161A1100-71", value: "161A1100-71" },
]

const mainModelsRight = [
    { label: "161A1100-72", value: "161A1100-72" },
]

const noseManufacturer = [
    { label: "BF Goodrich", value: "BF Goodrich" },
    { label: "Safran Landing Systems", value: "Safran Landing Systems" },
]

const noseModels = [
    { label: "162A1100-14", value: "162A1100-14" },
]






const PanelGoneForm = (props) => {
    const { history } = props;
    const [gone, setGone] = useState([]);
    const [form, setForm] = useState({ no_of_engines: { label: '0', value: 0 } });
    const [remHours, setRemHours] = useState({})
    console.log("form", form)

    const [thresh, setThresh] = useState({})
    const [threshCount, setThreshCount] = useState({})


    function handleThresholdChange(event) {
        const { name, value } = event.target;
        thresh[name] = value.length
        const remain_val = 40 - 35

        if (remain_val <= thresh[name]) {
            setThresh({ ...thresh, [name]: true })
        } else {
            setThresh({ ...thresh, [name]: false })
        }
        setThreshCount({ ...threshCount, [name]: event.target.value.length })
    }

    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    function handleRemHoursChange(e) {
        let str = e.target.value;
        if (str.length === 7) {
            var first = str.substring(0, str.indexOf("-"))
            var last = str.substring(str.indexOf("-") + 1)
            var minutes = parseInt(first) + parseInt(last)
            setRemHours({ ...remHours, [e.target.name]: minutes })
        }
    }

    // const [engineRow, setEngineRow] = useState([{
    //     eng_mfr: '', eng_part_no: '', conf_eng_part_no: '', eng_sl_no: '', conf_eng_sl_no: '',
    //     eng_last_shop_visit: '', eng_rem_hrs: '', eng_rem_cycle: ''
    // }])
    const [engineRow, setEngineRow] = useState({})
    console.log("engineRow", engineRow)

    function handleAddRowNested(value) {
        // const modifiedRows = [...engineRow]
        let modifiedRows = [];
        for (let i = 0; i < value; i++) {
            modifiedRows.push({
                eng_mfr: '', eng_model: '', eng_status: '', eng_part_no: '', conf_eng_part_no: '', eng_sl_no: '', conf_eng_sl_no: '',
                eng_mfr_date: '', eng_last_shop_visit: '', eng_rem_hrs: '', eng_rem_cycle: ''
            })
        }
        setEngineRow(modifiedRows)
    }

    const handleEngineChange = (e, i) => {
        const tempValues = [...engineRow];
        tempValues[i] = { ...tempValues[i], [e.target.name]: e.target.value };
        setEngineRow(tempValues);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const resData = { form, engineRow }
        // await getGone()
        // const res = await postGone(resData);
        postGone(resData)
            .then(async (resp) => {
                await getGone()
                    .then(respo => {
                        setGone(respo)
                        history.push('/panelg1')
                    })
            })
            .catch(err => {
                console.log("Error", err);
            })
    }

    const handleCancel = () => {
        history.push('/panelg1')
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Fleet Configuration | Aircraft Setup</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Configuration" breadcrumbItem="Aircraft Setup" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Aircraft Model
                                                            </label>
                                                            <div className="col-md-9">
                                                                <Select
                                                                    value={form.aircraft_name}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, aircraft_name: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                MSN
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="msn" value={form.msn}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MSN"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.msn ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.msn} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                National Registration ID
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="reg_id" value={form.reg_id}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter Registration ID"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.reg_id ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.reg_id} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Manufactured Date
                                                            </label>
                                                            <div className="col-md-9">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={form.mfr_date}
                                                                    onChange={(date) => {
                                                                        console.log("date", date)
                                                                        setForm({ ...form, mfr_date: date })
                                                                    }
                                                                    }
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Aircraft Hours
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={7} name="aircraft_hours" value={form.aircraft_hours}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Aircraft Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                No of Engines
                                                            </label>
                                                            <div className="col-md-9">
                                                                <Select
                                                                    value={form.no_of_engines}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, no_of_engines: value })
                                                                        handleAddRowNested(value.value)
                                                                    }}
                                                                    options={noOfEngines}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Received Status
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.aircraft_status}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, aircraft_status: value })
                                                                    }}
                                                                    options={aircraftStatus}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter MSN
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={25} name="confirm_msn" value={form.confirm_msn}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong MSN"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "msn" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter National Registration ID
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="confirm_reg_id" value={form.confirm_reg_id}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong Registration ID"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "reg_id" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Date Received
                                                            </label>
                                                            <div className="col-md-8">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={form.aircraft_date}
                                                                    onChange={(date) => setForm({ ...form, aircraft_date: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Cycles
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="number" name="aircraft_cycles" value={form.aircraft_cycles}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {form?.no_of_engines?.value >= 1 ?
                                                    engineRow.map((el, i) => {
                                                        return (
                                                            <Row key={i} className="mb-3">
                                                                <h4 className="card-title mb-3 text-center">Engine {i + 1}</h4>
                                                                {/* <Col lg={6}>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-3 col-form-label">
                                                                            Engine Manufacturer
                                                                        </label>
                                                                        <div className="col-md-9">
                                                                            <Select
                                                                                value={engineRow.eng_mfr}
                                                                                onChange={(value) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_mfr: value };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                                options={engineManufacturer}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-3 col-form-label">
                                                                            Engine Model
                                                                        </label>
                                                                        <div className="col-md-9">
                                                                            <Select
                                                                                value={engineRow.eng_mfr}
                                                                                onChange={(value) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_mfr: value };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                                options={engineManufacturer}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                </Col> */}
                                                                <Col lg={6}>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Engine Manufacturer
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <Select
                                                                                value={engineRow.eng_mfr}
                                                                                onChange={(value) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_mfr: value };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                                options={engineManufacturer}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Part No
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" maxLength={40} name="eng_part_no" value={el.eng_part_no}
                                                                                onChange={(el) => {
                                                                                    handleEngineChange(el, i)
                                                                                }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                autoComplete="off"
                                                                            // errorMessage="Enter Part No"
                                                                            // validate={{ required: { value: true } }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Serial No
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" maxLength={40} name="eng_sl_no" value={el.eng_sl_no}
                                                                                onChange={(el) => {
                                                                                    handleEngineChange(el, i)
                                                                                }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                autoComplete="off"
                                                                            // errorMessage="Enter Serial No"
                                                                            // validate={{ required: { value: true } }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Engine Status
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <Select
                                                                                value={engineRow.eng_status}
                                                                                onChange={(value) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_status: value };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                                options={engineStatus}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Total Hours
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" maxLength={7} name="eng_rem_hrs" value={el.eng_rem_hrs}
                                                                                onChange={(el) => { handleEngineChange(el, i) }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                placeholder="HHHH-MM"
                                                                                errorMessage="Enter Total Hours"
                                                                            // validate={{
                                                                            //     required: { value: true },
                                                                            //     pattern: {
                                                                            //         value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            //         errorMessage: "Invalid Format",
                                                                            //     },
                                                                            // }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-3 col-form-label">
                                                                            Engine Model
                                                                        </label>
                                                                        <div className="col-md-9">
                                                                            <Select
                                                                                value={engineRow.eng_model}
                                                                                onChange={(value) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_model: value };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                                options={engineModels}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-3 col-form-label">
                                                                            Re-Enter Part No
                                                                        </label>
                                                                        <div className="col-md-9">
                                                                            <AvField type="text" maxLength={40} name="conf_eng_part_no" value={el.conf_eng_part_no}
                                                                                onChange={(el) => {
                                                                                    handleEngineChange(el, i)
                                                                                }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                autoComplete="off"
                                                                            // errorMessage="Confirm Part No"
                                                                            // validate={{ required: { value: true }, match: { value: "eng_part_no" }, }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-3 col-form-label">
                                                                            Re-Enter Serial No
                                                                        </label>
                                                                        <div className="col-md-9">
                                                                            <AvField type="text" maxLength={40} name="conf_eng_sl_no" value={el.conf_eng_sl_no}
                                                                                onChange={(el) => {
                                                                                    handleEngineChange(el, i)
                                                                                }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                autoComplete="off"
                                                                            // errorMessage="Confirm Serial No"
                                                                            // validate={{
                                                                            //     required: { value: true },
                                                                            //     match: { value: "eng_sl_no" },
                                                                            // }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-3 col-form-label">
                                                                            Manufactured Date
                                                                        </label>
                                                                        <div className="col-md-9">
                                                                            <DatePicker
                                                                                className="form-control"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                placeholderText="DD/MM/YYYY"
                                                                                selected={el.eng_mfr_date}
                                                                                onChange={(date) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_mfr_date: date };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-3 col-form-label">
                                                                            Total Cycles
                                                                        </label>
                                                                        <div className="col-md-9">
                                                                            <AvField type="number" name="eng_rem_cycle" value={el.eng_rem_cycle}
                                                                                onChange={(el) => {
                                                                                    handleEngineChange(el, i)
                                                                                }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                </Col>
                                                                {el?.eng_status?.value === 'New' ?
                                                                    null :
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-3 col-form-label">
                                                                            Last Shop Visit
                                                                        </label>
                                                                        <div className="col-md-9">
                                                                            <DatePicker
                                                                                className="form-control"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                placeholderText="DD/MM/YYYY"
                                                                                selected={el.eng_last_shop_visit}
                                                                                onChange={(date) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_last_shop_visit: date };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                }

                                                            </Row>
                                                        );
                                                    })
                                                    : null
                                                }
                                                {/* APU Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">APU Details</h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                APU Manufacturer
                                                            </label>
                                                            <div className="col-md-9">
                                                                <Select
                                                                    value={form.apu_manufacturer}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, apu_manufacturer: value })
                                                                    }}
                                                                    options={apuManufacturer}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                APU Serial No
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="apu_sl_no" value={form.apu_sl_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter APU Sl No"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.apu_sl_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.apu_sl_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                APU Part Number
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="apu_product_no" value={form.apu_product_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter APU Part Number"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.apu_product_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.apu_product_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                APU Last Shop Visit
                                                            </label>
                                                            <div className="col-md-9">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={form.apu_last_shop_visit}
                                                                    onChange={(date) => setForm({ ...form, apu_last_shop_visit: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>

                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.apu_model}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, apu_model: value })
                                                                    }}
                                                                    options={apuModels}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter APU Serial No
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="confirm_apu_sl_no" value={form.confirm_apu_sl_no}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong APU Serial No"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "apu_sl_no" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="confirm_apu_product_no" value={form.confirm_apu_product_no}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong APU Part Number"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "apu_product_no" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="apu_remaining_hours" value={form.apu_remaining_hours}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            APU Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" name="apu_remaining_cycles" value={form.apu_remaining_cycles}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* Main Landing Gear Left Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">
                                                        Main Landing Gear Details(Left)
                                                    </h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Manufacturer
                                                            </label>
                                                            <div className="col-md-9">
                                                                <Select
                                                                    value={form.main_manufacturer}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_manufacturer: value })
                                                                    }}
                                                                    options={mainManufacturer}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Serial No
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="main_sl_no" value={form.main_sl_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MainLG Serial No"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.main_sl_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.main_sl_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Part Number
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="main_product_no" value={form.main_product_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MainLG Part Number"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.main_product_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.main_product_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Last Shop Visit
                                                            </label>
                                                            <div className="col-md-9">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={form.main_last_shop_visit}
                                                                    onChange={(date) => setForm({ ...form, main_last_shop_visit: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.main_model}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_model: value })
                                                                    }}
                                                                    options={mainModelsLeft}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter Serial No
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="confirm_main_sl_no" value={form.confirm_main_sl_no}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong MainLG Serial No"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "main_sl_no" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="confirm_main_product_no" value={form.confirm_main_product_no}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong MainLG Part Number"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "main_product_no" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="main_remaining_hours" value={form.main_remaining_hours}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" name="main_remaining_cycles" value={form.main_remaining_cycles}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* Main Landing Gear Right Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">
                                                        Main Landing Gear Details(Right)
                                                    </h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Manufacturer
                                                            </label>
                                                            <div className="col-md-9">
                                                                <Select
                                                                    value={form.main_manufacturer_r}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_manufacturer_r: value })
                                                                    }}
                                                                    options={mainManufacturer}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Serial No
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="main_sl_no_r" value={form.main_sl_no_r}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MainLG Serial No"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.main_sl_no_r ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.main_sl_no_r} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Part Number
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="main_product_no_r" value={form.main_product_no_r}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MainLG Part Number"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.main_product_no_r ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.main_product_no_r} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Last Shop Visit
                                                            </label>
                                                            <div className="col-md-9">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={form.main_last_shop_visit_r}
                                                                    onChange={(date) => setForm({ ...form, main_last_shop_visit_r: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.main_model_r}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_model_r: value })
                                                                    }}
                                                                    options={mainModelsRight}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter Serial No
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="confirm_main_sl_no_r" value={form.confirm_main_sl_no_r}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong MainLG Serial No"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "main_sl_no_r" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="confirm_main_product_no_r" value={form.confirm_main_product_no_r}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong MainLG Part Number"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "main_product_no_r" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="main_remaining_hours_r" value={form.main_remaining_hours_r}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" name="main_remaining_cycles_r" value={form.main_remaining_cycles_r}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* Nose Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">
                                                        Nose Landing Gear Details
                                                    </h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Manufacturer
                                                            </label>
                                                            <div className="col-md-9">
                                                                <Select
                                                                    value={form.nose_manufacturer}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, nose_manufacturer: value })
                                                                    }}
                                                                    options={noseManufacturer}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Serial No
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="nose_sl_no" value={form.nose_sl_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter NoseLG Serial No"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.nose_sl_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.nose_sl_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Part Number
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" maxLength={40} name="nose_product_no" value={form.nose_product_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter NoseLG Part Number"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.nose_product_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.nose_product_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Last Shop Visit
                                                            </label>
                                                            <div className="col-md-9">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={form.nose_last_shop_visit}
                                                                    onChange={(date) => setForm({ ...form, nose_last_shop_visit: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.nose_model}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, nose_model: value })
                                                                    }}
                                                                    options={noseModels}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter Serial No
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="confirm_nose_sl_no" value={form.confirm_nose_sl_no}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong NoseLG Serial No"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "nose_sl_no" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Re-Enter Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="confirm_nose_product_no" value={form.confirm_nose_product_no}
                                                                    onChange={e => { handleChange(e) }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Wrong NoseLG Part Number"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        match: { value: "nose_product_no" },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="nose_remaining_hours" value={form.nose_remaining_hours}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" name="nose_remaining_cycles" value={form.nose_remaining_cycles}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                <Row>
                                                    <Col className="d-flex flex-wrap gap-2 justify-content-center">
                                                        <Button className=" btn btn-success save-user w-md"
                                                            type="submit"
                                                        >
                                                            Submit
                                                        </Button>
                                                        <Button type="reset" color="secondary" onClick={handleCancel}
                                                            className=" btn btn-secondary save-user w-md">
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

PanelGoneForm.propTypes = {
    history: PropTypes.object
}

export default PanelGoneForm