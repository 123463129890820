import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
    Table
} from "reactstrap";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { getGthree, deleteGthree, getGtwo, postScheduler, getScheduler, deleteScheduler, getGone } from "actions/api";
import images from "assets/images";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import XLSX from 'xlsx';
import { make_cols } from './makeColumns';
import { SheetJSFT } from './types';
import * as moment from "moment";

import {
    getUsers as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty, size, map } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const Scheduler = props => {
    const { history } = props;
    const { match: { params } } = props;
    const id = parseInt(params.id);
    const gone_id = parseInt(params.id);
    // console.log("id", id)
    const dispatch = useDispatch();
    const [gthree, setGthree] = useState([])
    const [schedulerData, setSchedulerData] = useState([]);
    console.log("gthree", gthree)
    const { users } = useSelector(state => ({
        users: state.contacts.users,
    }));


    const [userList, setUserList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: sizePerPage,
        totalSize: schedulerData.length, // replace later with size(users),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const selectRow = {
        mode: "checkbox",
    };

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    const contactListColumns = [
        {
            text: "id",
            // dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "id",
            text: "#",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    {!user.img ? (
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle">
                                {/* {user.name.charAt(0)} */}
                                {user.id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle avatar-xs"
                                src={images[user.img]}
                                alt=""
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            dataField: "aircraft_type",
            text: "Aircraft Type",
            sort: true,
        },
        {
            dataField: "aircraft_date",
            text: "Flight Date",
            sort: true,
            formatter: (cell, row) => {
                const dueDate = row.aircraft_date ? handleValidDate(row.aircraft_date) : null
                return <>{dueDate}</>;
            },
        },
        {
            dataField: "aircraft_hours",
            text: "Flight Hours",
            sort: true,
        },
        {
            dataField: "aircraft_cycles",
            text: "Flight Cycles",
            sort: true,
        },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <div className="d-flex gap-3">
                    {/* <Link className="text-success" to="#">
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={() => handleUserClick(user)}
                        ></i>
                    </Link> */}
                    <Link className="text-danger" to="#">
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={() => handleDeleteUser(user)}
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    // useEffect(() => {
    //     if (users && !users.length) {
    //         dispatch(onGetUsers());
    //         setIsEdit(false);
    //     }
    // }, [dispatch, users]);

    // useEffect(() => {
    //     setUserList(users);
    //     setIsEdit(false);
    // }, [users]);

    // useEffect(() => {
    //     if (!isEmpty(users) && !!isEdit) {
    //         setUserList(users);
    //         setIsEdit(false);
    //     }
    // }, [users]);

    useEffect(() => {
        getGtwo()
            .then(resp2 => {
                const gTwoArr = resp2;
                getGthree()
                    .then(resp => {
                        let arr = [];
                        resp.map(n => {
                            const aircraftModel = n.aircraft_model;
                            const serAssigned = typeof n.service_assigned === 'string' ?
                                JSON.parse(n.service_assigned) : n.service_assigned;
                            const estPrice = gTwoArr?.filter(x =>
                                ((typeof x.aircraft_model === 'string' ?
                                    (JSON.parse(x.aircraft_model).map(n => n.value)) :
                                    (x.aircraft_model).map(n => n.value)).includes(aircraftModel))
                                && serAssigned.map(p => p.value).includes(x.comp_name)).reduce((a, b) => a + parseFloat(b.est_price), 0);
                            n.comp_price = estPrice;
                            arr.push({
                                ...n,
                            })
                        })
                        setGthree(arr)
                    })
            })
    }, []);

    const toggle = () => {
        setModal(!modal);
    };

    // const handleUserClick = arg => {
    //     const user = arg;

    //     setUserList({
    //         id: user.id,
    //         name: user.name,
    //         designation: user.designation,
    //         email: user.email,
    //         tags: user.tags,
    //         projects: user.projects,
    //     });
    //     setIsEdit(true);

    //     toggle();
    // };

    const handleUserClick = arg => {
        var id = arg.id;
        props.history.push(`/viewpanelg3form/${id}`)
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    // const handleDeleteUser = user => {
    //     if (user.id !== undefined) {
    //         dispatch(onDeleteUser(user));
    //         onPaginationPageChange(1);
    //     }
    // };

    const handleDeleteUser = async (user) => {
        const id = user.id;
        await deleteScheduler(id)
            .then(async (resp) => {
                await getScheduler()
                    .then(respo => {
                        setSchedulerData(respo)
                        props.history.push(`/scheduler/${gone_id}`)
                    })
            })
            .catch(err => console.log("Err", err))
    };

    /**
     * Handling submit user on user form
     */
    const handleValidUserSubmit = (e, values) => {
        if (isEdit) {
            const updateUser = {
                id: userList.id,
                name: values.name,
                designation: values.designation,
                tags: values.tags,
                email: values.email,
                projects: values.projects,
            };

            // update user
            dispatch(onUpdateUser(updateUser));
            setIsEdit(false);
        } else {
            const newUser = {
                id: Math.floor(Math.random() * (30 - 20)) + 20,
                name: values["name"],
                designation: values["designation"],
                email: values["email"],
                tags: values["tags"],
                projects: values["projects"],
            };
            // save new user
            dispatch(onAddNewUser(newUser));
        }
        toggle();
    };

    const handleNewPanel = () => {
        history.push('/panelg3form')
    }

    const handleUserClicks = () => {
        setUserList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [cols, setCols] = useState([]);

    useEffect(() => {
        getGone()
            .then(resp => {
                const foundGone = resp.find(x => x.id === parseInt(params.id))
                const aircraftType = foundGone && foundGone.aircraft_name;
                getScheduler()
                    .then(resp2 => {
                        const filterScheduler = resp2.filter(x => x.aircraft_type === aircraftType);
                        setSchedulerData(filterScheduler)
                    })
            })
    }, [])

    const handleChange = (e) => {
        const files = e.target.files;
        setFile(document.getElementById('file').files[0]);
    };

    const handleFile = () => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            // /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? 'binary' : 'array',
                bookVBA: true,
            });
            // /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            // /* Convert array of arrays */
            const dataa = XLSX.utils.sheet_to_json(ws, { raw: false });
            // /* Update state */
            setData(dataa);
            // setCols(make_cols(ws['!ref']));
            console.log(JSON.stringify(dataa, null, 2));
            //Post Scheduler
            postScheduler(dataa)
                .then(resp => {
                    getScheduler()
                        .then(respo => {
                            setSchedulerData(respo)
                            props.history.push(`/scheduler/${gone_id}`)
                        })
                })
        };

        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        }

        // const reader = new FileReader();
        // reader.onload = (evt) => {
        //     // evt = on_file_select event
        //     /* Parse data */
        //     const bstr = evt.target.result;
        //     const wb = XLSX.read(bstr, { type: "binary" });
        //     /* Get first worksheet */
        //     const wsname = wb.SheetNames[0];
        //     const ws = wb.Sheets[wsname];
        //     /* Convert array of arrays */
        //     const dataa = XLSX.utils.sheet_to_csv(ws, { header: 1 });
        //     /* Update state */
        //     console.log("Data>>>" + dataa);// shows that excel data is read
        //     console.log(convertToJson(dataa)); // shows data in json format
        //     // setData(convertToJson(dataa))
        // };
        // reader.readAsBinaryString(file);
    };

    function convertToJson(csv) {
        var lines = csv.split("\n");

        var result = [];

        var headers = lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var currentline = lines[i].split(",");

            for (var j = 0; j < headers.length; j++) {
                obj[headers[j]] = currentline[j];
            }

            result.push(obj);
        }

        //return result; //JavaScript object
        // setData(JSON.stringify(result))
        return JSON.stringify(result); //JSON
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Forecast | Scheduler</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Forecast" breadcrumbItem="Scheduler" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField={keyField}
                                        columns={contactListColumns}
                                        data={schedulerData}
                                    >
                                        {({ paginationProps, paginationTableProps }) => {
                                            return (
                                                <ToolkitProvider
                                                    keyField={keyField}
                                                    data={schedulerData}
                                                    columns={contactListColumns}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col sm="4">
                                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="8">
                                                                    <div className="table-responsive">
                                                                        <Table className="table mb-0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>
                                                                                        <label htmlFor="file" className="mt-2">Upload Excel file</label>
                                                                                    </th>
                                                                                    <td>
                                                                                        <input
                                                                                            type="file"
                                                                                            className="form-control"
                                                                                            id="file"
                                                                                            accept={SheetJSFT}
                                                                                            onChange={handleChange}
                                                                                        />
                                                                                    </td>
                                                                                    <th>
                                                                                        {file !== null ?
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className="font-16 btn-block btn btn-primary"
                                                                                                onClick={handleFile}
                                                                                            >
                                                                                                Submit
                                                                                            </Button>
                                                                                            : null
                                                                                        }
                                                                                    </th>
                                                                                    <td>{ }</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={keyField}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            selectRow={selectRow}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap table-hover"
                                                                            }
                                                                            bordered={false}
                                                                            striped={false}
                                                                            responsive
                                                                            ref={node}
                                                                        />

                                                                        <Modal isOpen={modal} toggle={toggle}>
                                                                            <ModalHeader toggle={toggle} tag="h4">
                                                                                {!!isEdit ? "Edit User" : "Add User"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <AvForm
                                                                                    onValidSubmit={handleValidUserSubmit}
                                                                                >
                                                                                    <Row form>
                                                                                        <Col xs={12}>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="aircraft_model"
                                                                                                    label="Aircraft model"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid service class"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.service_class || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_class"
                                                                                                    label="Service class"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Designation"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={
                                                                                                        userList.designation || ""
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_name"
                                                                                                    label="service name"
                                                                                                    type="select"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_name"
                                                                                                    label="service name (confirm)"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    type="select"
                                                                                                    name="select"
                                                                                                    className="form-select"
                                                                                                    label="service1"
                                                                                                    helpMessage="MULTIPLE!"
                                                                                                    multiple={true}
                                                                                                    required
                                                                                                    value={userList.tags || ""}
                                                                                                >
                                                                                                    <option></option>
                                                                                                    <option>service1</option>
                                                                                                    <option>service2</option>
                                                                                                    <option>service3</option>

                                                                                                </AvField>
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_desc"
                                                                                                    label="decription"

                                                                                                    type="textarea"
                                                                                                    id="textarea"

                                                                                                    maxLength="225"
                                                                                                    rows="3"
                                                                                                    placeholder="This textarea has a limit of 225 chars."
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-end">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                >
                                                                                                    Save
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AvForm>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            );
                                        }}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
Scheduler.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(Scheduler);
