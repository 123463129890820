import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import "@vtaits/react-color-picker/dist/index.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import "flatpickr/dist/themes/material_blue.css"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { postUsers, getUsers } from "../../actions/api";

const animatedComponents = makeAnimated()

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
        </div>
    )
}

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Yes
        </div>
    )
}

const optionGroup = [
    {
        label: "Model",
        options: [
            { label: "Type1", value: "Type1" },
            { label: "Type2", value: "Type2" },
            { label: "Type3", value: "Type3" },
            { label: "Type4", value: "Type4" },
            { label: "Type5", value: "Type5" },
            { label: "Type6", value: "Type6" },
            { label: "Type7", value: "Type7" },
            { label: "Type8", value: "Type8" },
            { label: "Type9", value: "Type9" },
            { label: "Type10", value: "Type10" }
        ]
    }

]



const access = [
    {
        label: "access",
        options: [
            { label: "Read", value: "Read" },
            { label: "Write", value: "Write" },
            { label: "Select", value: "Select" },
            { label: "Delete", value: "Delete" },
            { label: "Approve", value: "Approve" },
            { label: "Print", value: "Print" }
        ]
    }

]

const status = [
    { label: "Active Employed", value: "Active Employed" },
    { label: "Suspended", value: "Suspended" },
    { label: "Not employed", value: "Not employed" },
    { label: "Temp", value: "Temp" },
]

const position = [
    {
        label: "position",
        options: [
            { label: "Technical Records", value: "Technical Records" },
            { label: "Maintenance Planner", value: "Maintenance Planner" },
            { label: "Warehouse", value: "Warehouse" },
            { label: "Procurement", value: "Procurement" },
            { label: "Maintenance", value: "Maintenance" },
            { label: "Technical Services", value: "Technical Services" },
            { label: "System Admin", value: "System Admin" }
        ]
    }
]

const optionGroup2 = [
    {
        label: "Service",
        options: [
            { label: "Service1", value: "Service1" },
            { label: "Service2", value: "Service2" },
            { label: "Service3", value: "Service3" }
        ]
    }

]




const CreateUser = (props) => {
    const { history } = props;
    const [form, setForm] = useState({});
    console.log("form", form)
    const [allUser, setAllUsers] = useState([])
    const [textcount, settextcount] = useState(0)
    const [textareabadge, settextareabadge] = useState(0)

    const [selectedGroup, setselectedGroup] = useState(null)
    const [selectedMulti, setselectedMulti] = useState(null)

    const [thresh, setThresh] = useState({})
    const [threshCount, setThreshCount] = useState({})
    const [engineRow, setEngineRow] = useState({})

    function handleThresholdChange(event) {
        const { name, value } = event.target;
        thresh[name] = value.length
        const remain_val = 40 - 35

        if (remain_val <= thresh[name]) {
            setThresh({ ...thresh, [name]: true })
        } else {
            setThresh({ ...thresh, [name]: false })
        }
        setThreshCount({ ...threshCount, [name]: event.target.value.length })
    }


    function textareachange(event) {
        const count = event.target.value.length
        if (count > 0) {
            settextareabadge(true)
        } else {
            settextareabadge(false)
        }
        settextcount(event.target.value.length)
    }

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }

    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }
    function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti)
        console.log("selectedMulti", selectedMulti)
    }

    function handleAddRowNested(value) {
        // const modifiedRows = [...engineRow]
        let modifiedRows = [];
        for (let i = 0; i < value; i++) {

            modifiedRows.push({
                start_date: '', end_date: ''

            })
        }
        setEngineRow(modifiedRows)
    }

    const handleSubmit = async (e) => {
        // var pwd = Math.random().toString(36).substr(2, 8);
        // form.password = pwd;
        form.password = '123456';
        postUsers(form)
            .then(resp => {
                getUsers()
                    .then(respo => {
                        setAllUsers(respo)
                        history.push('/users')
                    })
            })
            .catch(err => {
                console.log("Error", err);
            })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>User Creation | Aviation</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Users" breadcrumbItem="Create User" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title"> Create New Form</h4>
                                    <AvForm onValidSubmit={handleSubmit}>
                                        <Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Username
                                                </label>
                                                <div className="col-md-9">
                                                    <AvField type="text" maxLength={40} name="username" value={form.username}
                                                        onChange={e => {
                                                            handleThresholdChange(e)
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                        errorMessage="Enter Username"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                    {thresh.username ? (
                                                        <span className="badgecount badge bg-success">
                                                            {threshCount.username} / 40{" "}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Name of Employee
                                                </label>
                                                <div className="col-md-9">
                                                    <AvField type="text" maxLength={40} name="emp_name" value={form.emp_name}
                                                        onChange={e => {
                                                            handleThresholdChange(e)
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                        errorMessage="Enter employee name"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                    {thresh.emp_name ? (
                                                        <span className="badgecount badge bg-success">
                                                            {threshCount.emp_name} / 40{" "}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Staff ID no
                                                </label>
                                                <div className="col-md-9">
                                                    <AvField type="text" maxLength={40} name="staff_id" value={form.staff_id}
                                                        onChange={e => {
                                                            handleThresholdChange(e)
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                        errorMessage="Enter staff id"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                    {thresh.staff_id ? (
                                                        <span className="badgecount badge bg-success">
                                                            {threshCount.staff_id} / 40{" "}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Employee email ID
                                                </label>
                                                <div className="col-md-9">
                                                    <AvField type="email" maxLength={40} name="email" value={form.email}
                                                        onChange={e => {
                                                            handleThresholdChange(e)
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                        errorMessage="Enter email id"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                    {thresh.email ? (
                                                        <span className="badgecount badge bg-success">
                                                            {threshCount.email} / 40{" "}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    User Status
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        value={form.status}
                                                        onChange={(value) => {
                                                            setForm({ ...form, status: value })
                                                            handleAddRowNested(value.value)
                                                        }}
                                                        options={status}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Row>
                                            {form?.status?.value === "Temp" ?
                                                <>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Start Date
                                                        </label>
                                                        <div className="col-md-9">
                                                            <DatePicker
                                                                className="form-control"
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="DD/MM/YYYY"
                                                                selected={form.start_date}
                                                                onChange={(date) => setForm({ ...form, start_date: date })}
                                                            />
                                                        </div>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            End Date
                                                        </label>
                                                        <div className="col-md-9">
                                                            <DatePicker
                                                                className="form-control"
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="DD/MM/YYYY"
                                                                selected={form.end_date}
                                                                onChange={(date) => setForm({ ...form, end_date: date })}
                                                            />
                                                        </div>
                                                    </Row>
                                                </>
                                                : null
                                            }
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    User Position
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        value={form.position}
                                                        onChange={(value) => {
                                                            setForm({ ...form, position: value })
                                                        }}
                                                        options={position}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Row>
                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Access Level
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        value={form.access}
                                                        isMulti={true}
                                                        onChange={(value) => {
                                                            handleMulti(value)
                                                            setForm({ ...form, access: value })
                                                        }}
                                                        options={access}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Row>

                                            <Row>
                                                <Col className="d-flex flex-wrap gap-2 justify-content-center">
                                                    <Button className=" btn btn-success save-user w-md"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                    <Button type="reset" color="secondary" className=" btn btn-secondary save-user w-md">
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </Container>
            </div>
        </React.Fragment>
    )
}

CreateUser.propTypes = {
    history: PropTypes.object
}

export default CreateUser
