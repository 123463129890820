import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import "@vtaits/react-color-picker/dist/index.css"
import "react-datepicker/dist/react-datepicker.css"

import Select from "react-select"
import makeAnimated from "react-select/animated"
import CreatableSelect from 'react-select/creatable';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import { postGthree, getGtwo, getGthree } from '../../../actions/api';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"

const animatedComponents = makeAnimated()

const Offsymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            No
        </div>
    )
}

const OnSymbol = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2
            }}
        >
            {" "}
            Yes
        </div>
    )
}


const optionGroup = [
    { label: "B737-700", value: "B737-700" },
    { label: "B737-800", value: "B737-800" },
    { label: "B737-900", value: "B737-900" },
    { label: "B737-900ER", value: "B737-900ER" },
    { label: "A320-200", value: "A320-200" },
    { label: "ATR72-500", value: "ATR72-500" },
    { label: "ATR72-600", value: "ATR72-600" },
]

const optionGroup1 = [
    {
        label: "Class",
        options: [
            { label: "GVI (General Visual Inspection)", value: "GVI (General Visual Inspection)" },
            { label: "DET (Detailed Visual Inspection)", value: "DET (Detailed Visual Inspection)" },
            { label: "FNC (Functional Check)", value: "FNC (Functional Check)" },
            { label: "DIS (Discard)", value: "DIS (Discard)" },
            { label: "LUB (Lubricate)", value: "LUB (Lubricate)" },
            { label: "OPC (Operational Check)", value: "OPC (Operational Check)" },
            { label: "RST (Restore)", value: "RST (Restore)" },
            { label: "SBC (Servicing)", value: "SBC (Servicing)" },
            { label: "VIC (Visual Check)", value: "VIC (Visual Check)" },
            { label: "Internal", value: "Internal" },
            { label: "Third Party", value: "Third Party" },
            { label: "CAAM", value: "CAAM" }
        ]
    }

]

const mpdTask = [
    { label: "20-110-01", value: "20-110-01" },
    { label: "20-110-02", value: "20-110-02" },
    { label: "20-110-03", value: "20-110-03" },
]

const ammTask = [
    { label: "05-55-10-200", value: "05-55-10-200" },
    { label: "05-55-10-300", value: "05-55-10-300" },
    { label: "05-55-10-400", value: "05-55-10-400" },
]

const currency = [
    { label: "MYR", value: "MYR" },
    { label: "USD", value: "USD" },
    { label: "EUR", value: "EUR" },
]

const intervalThreshold = [
    { label: "Hours", value: "Hours" },
    { label: "Cycles", value: "Cycles" },
    // { label: "Days", value: "Days" },
]



const optionGroup2 = [
    {
        label: "Service",
        options: [
            { label: "Service1", value: "Service1" },
            { label: "Service2", value: "Service2" },
            { label: "Service3", value: "Service3" }
        ]
    }

]




const PanelGthreeForm = (props) => {
    const { history } = props;
    const [gtwo, setGtwo] = useState([]);
    const [gthree, setGthree] = useState([]);
    const [form, setForm] = useState({ thresh_format: { label: "Hours", value: "Hours" }, repeat_format: { label: "Hours", value: "Hours" } });
    console.log("form", form)

    let gtwoComponent = [];
    const gtwoAircraft = gtwo?.filter(x =>
    ((typeof x.aircraft_model === 'string' ?
        (JSON.parse(x.aircraft_model).map(n => n.value)) :
        (x.aircraft_model).map(n => n.value)).includes(form?.aircraft_model?.value)))
    console.log("gtwoAircraft", gtwoAircraft)
    const gtwoComp = gtwoAircraft?.map(n => n.comp_name)
    gtwoComp.forEach((n, i) => {
        gtwoComponent.push({
            label: n,
            value: n
        })
    })



    const [textcount, settextcount] = useState(0)
    const [textareabadge, settextareabadge] = useState(0)

    const [selectedGroup, setselectedGroup] = useState(null)
    const [selectedMulti, setselectedMulti] = useState(null)

    const [thresh, setThresh] = useState({})
    const [threshCount, setThreshCount] = useState({})


    function handleThresholdChange(event) {
        const { name, value } = event.target;
        thresh[name] = value.length
        const remain_val = 40 - 35

        if (remain_val <= thresh[name]) {
            setThresh({ ...thresh, [name]: true })
        } else {
            setThresh({ ...thresh, [name]: false })
        }
        setThreshCount({ ...threshCount, [name]: event.target.value.length })
    }


    function textareachange(event) {
        const count = event.target.value.length
        if (count > 0) {
            settextareabadge(true)
        } else {
            settextareabadge(false)
        }
        settextcount(event.target.value.length)
    }

    function handleSelectGroup(selectedGroup) {
        setselectedGroup(selectedGroup)
    }

    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }
    function handleMulti(selectedMulti) {
        console.log("selectedMulti", selectedMulti)
        setselectedMulti(selectedMulti)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        postGthree(form)
            .then(async (resp) => {
                await getGthree()
                    .then(respo => {
                        setGthree(respo)
                        history.push('/panelg3')
                    })
            })
            .catch(err => {
                console.log("Error", err);
            })
    }

    const handleCancel = () => {
        history.push('/panelg3')
    }

    useEffect(() => {
        getGtwo()
            .then(resp => {
                setGtwo(resp)
            })
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Fleet Configuration| Service List Setup</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Configuration" breadcrumbItem="Service List Setup" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title"> Create Panel G3</h4>
                                    {/* <p className="card-title-desc">
                    A mobile and touch friendly input spinner component for
                    Bootstrap
                  </p> */}

                                    <AvForm onValidSubmit={handleSubmit}>
                                        <Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Aircraft Model
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        value={form.aircraft_model}
                                                        onChange={(value) => {
                                                            setForm({ ...form, aircraft_model: value })
                                                        }}
                                                        options={optionGroup}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Task
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        value={form.service_class}
                                                        onChange={(value) => {
                                                            setForm({ ...form, service_class: value })
                                                        }}
                                                        options={optionGroup1}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    MPD Task ID
                                                </label>
                                                <div className="col-md-9">
                                                    {/* <Select
                                                        value={form.mpd}
                                                        onChange={(value) => {
                                                            setForm({ ...form, mpd: value })
                                                        }}
                                                        options={mpdTask}
                                                        classNamePrefix="select2-selection"
                                                    /> */}
                                                    <AvField type="text" maxLength={40} name="mpd" value={form.mpd}
                                                        onChange={e => {
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                        errorMessage="Enter MPD Task ID"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    AMM Task ID
                                                </label>
                                                <div className="col-md-9">
                                                    {/* <Select
                                                        value={form.amm}
                                                        onChange={(value) => {
                                                            setForm({ ...form, amm: value })
                                                        }}
                                                        options={ammTask}
                                                        classNamePrefix="select2-selection"
                                                    /> */}
                                                    <AvField type="text" maxLength={40} name="amm" value={form.amm}
                                                        onChange={e => {
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                        errorMessage="Enter AMM Task ID"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Task Card Ref ID
                                                </label>
                                                <div className="col-md-9">

                                                    <AvField type="text" maxLength={40} name="task_ref" value={form.task_ref}
                                                        onChange={e => {
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                        errorMessage="Enter Task Ref ID"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Row>

                                            {/* <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Task Card name
                                                </label>
                                                <div className="col-md-9">
                                                    <AvField type="text" maxLength={40} name="service_name" value={form.service_name}
                                                        onChange={e => {
                                                            handleThresholdChange(e)
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                        errorMessage="Enter Service name"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                    {thresh.service_name ? (
                                                        <span className="badgecount badge bg-success">
                                                            {threshCount.service_name} / 40{" "}
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Re-Enter Task Card name
                                                </label>
                                                <div className="col-md-9">
                                                    <AvField type="text" maxLength={40} name="confirm_service_name" value={form.confirm_service_name}
                                                        onChange={e => { handleChange(e) }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                        errorMessage="Wrong Service Name"
                                                        validate={{
                                                            required: { value: true },
                                                            match: { value: "service_name" },
                                                        }}
                                                    />
                                                </div>
                                            </Row> */}

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Description
                                                </label>
                                                <div className="col-md-9">
                                                    <AvField type="textarea" id="textarea" rows="3" placeholder="This textarea has a limit of 999 chars." maxLength="999" name="service_desc" value={form.service_desc}
                                                        onChange={e => {
                                                            textareachange(e)

                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        errorMessage="Enter description"
                                                        validate={{ required: { value: true } }}
                                                    />
                                                    {textareabadge ? (
                                                        <span className="badgecount badge bg-success">
                                                            {" "}
                                                            {textcount} / 999{" "}
                                                        </span>
                                                    ) : null}
                                                </div>

                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Service Assigned Component
                                                </label>
                                                <div className="col-md-9">
                                                    <Select
                                                        value={selectedMulti}
                                                        isMulti={true}
                                                        onChange={(value) => {
                                                            handleMulti(value)
                                                            setForm({ ...form, service_assigned: value })
                                                        }}
                                                        options={gtwoComponent}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Zones
                                                </label>
                                                <div className="col-md-9">
                                                    <CreatableSelect
                                                        isMulti
                                                        onChange={(value) => {
                                                            setForm({ ...form, zone: value })
                                                        }}
                                                        //options={colourOptions}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Estimated Manhours
                                                </label>
                                                <div className="col-md-9">
                                                    <AvField type="number" maxLength={10} name="est_man_hours" value={form.est_man_hours}
                                                        onChange={e => {
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Estimated Price
                                                </label>
                                                <div className="col-md-3">
                                                    <Select
                                                        value={form.est_cur}
                                                        onChange={(value) => {
                                                            setForm({ ...form, est_cur: value })
                                                        }}
                                                        options={currency}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField type="number" maxLength={10} name="est_price" value={form.est_price}
                                                        onChange={e => {
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Quotation Price
                                                </label>
                                                <div className="col-md-3">
                                                    <Select
                                                        value={form.quote_cur}
                                                        onChange={(value) => {
                                                            setForm({ ...form, quote_cur: value })
                                                        }}
                                                        options={currency}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <AvField type="number" maxLength={10} name="quote_price" value={form.quote_price}
                                                        onChange={e => {
                                                            handleChange(e)
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Interval Threshold
                                                </label>
                                                <div className="col-md-3">
                                                    <Select
                                                        value={form.thresh_format}
                                                        onChange={(value) => {
                                                            setForm({ ...form, thresh_format: value })
                                                        }}
                                                        options={intervalThreshold}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                {form?.thresh_format?.value === 'Hours' ?
                                                    <div className="col-md-6">
                                                        <AvField type="number" maxLength={4} name="interval_thresh" value={form.interval_thresh}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            placeholder="HHHH"
                                                            errorMessage="Enter Threshold in Hours"
                                                            validate={{
                                                                required: form?.thresh_format?.value === 'Hours' ?
                                                                    { value: true } : { value: false },
                                                                pattern: {
                                                                    value: "^([0-9]?[0-9][0-9][0-9][0-9])$",
                                                                    errorMessage: "Invalid Format",
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    : form?.thresh_format?.value === 'Cycles' ?
                                                        <div className="col-md-6">
                                                            <AvField type="number" maxLength={4} name="interval_thresh" value={form.interval_thresh}
                                                                onChange={e => {
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                placeholder="Enter Threshold in Cycles"
                                                                errorMessage="Enter Threshold in Cycles"
                                                                validate={{
                                                                    required: form?.thresh_format?.value === 'Cycles' ?
                                                                        { value: true } : { value: false },
                                                                    // pattern: {
                                                                    //     value: "^([0-9]?[0-9][0-9][0-9][0-9])$",
                                                                    //     errorMessage: "Invalid Format",
                                                                    // },
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className="col-md-6">
                                                            <AvField type="number" maxLength={4} name="interval_thresh" value={form.interval_thresh}
                                                                onChange={e => {
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                placeholder="Enter Threshold in Days"
                                                                errorMessage="Enter Threshold in Days"
                                                                validate={{
                                                                    required: form?.thresh_format?.value === 'Days' ?
                                                                        { value: true } : { value: false },
                                                                    // pattern: {
                                                                    //     value: "^([0-9]?[0-9][0-9][0-9][0-9])$",
                                                                    //     errorMessage: "Invalid Format",
                                                                    // },
                                                                }}
                                                            />
                                                        </div>
                                                }
                                            </Row>

                                            <Row className="mb-3">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-3 col-form-label"
                                                >
                                                    Interval Repeat
                                                </label>
                                                <div className="col-md-3">
                                                    <Select
                                                        value={form.repeat_format}
                                                        onChange={(value) => {
                                                            setForm({ ...form, repeat_format: value })
                                                        }}
                                                        options={intervalThreshold}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                {form?.repeat_format?.value === 'Hours' ?
                                                    <div className="col-md-6">
                                                        <AvField type="text" maxLength={4} name="interval_repeat" value={form.interval_repeat}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                            onPaste={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            onCopy={(e) => {
                                                                e.preventDefault()
                                                                return false;
                                                            }}
                                                            placeholder="HHHH"
                                                            errorMessage="Enter Repeat in Hours"
                                                            validate={{
                                                                required: form?.repeat_format?.value === 'Hours' ?
                                                                    { value: true } : { value: false },
                                                                pattern: {
                                                                    value: "^([0-9]?[0-9][0-9][0-9][0-9])$",
                                                                    errorMessage: "Invalid Format",
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    : form?.repeat_format?.value === 'Cycles' ?
                                                        <div className="col-md-6">
                                                            <AvField type="number" maxLength={4} name="interval_repeat" value={form.interval_repeat}
                                                                onChange={e => {
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                placeholder="Enter Interval Repeat in Cycles"
                                                                errorMessage="Enter Repeat in Cycles"
                                                                validate={{
                                                                    // required: { value: true },
                                                                    required: form?.repeat_format?.value === 'Cycles' ?
                                                                        { value: true } : { value: false },
                                                                    // pattern: {
                                                                    //     value: "^([0-9]?[0-9][0-9][0-9][0-9])$",
                                                                    //     errorMessage: "Invalid Format",
                                                                    // },
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className="col-md-6">
                                                            <AvField type="number" maxLength={4} name="interval_repeat" value={form.interval_repeat}
                                                                onChange={e => {
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                placeholder="Enter Interval Repeat in Days"
                                                                errorMessage="Enter Repeat in Days"
                                                                validate={{
                                                                    // required: { value: true },
                                                                    required: form?.repeat_format?.value === 'Days' ?
                                                                        { value: true } : { value: false },
                                                                    // pattern: {
                                                                    //     value: "^([0-9]?[0-9][0-9][0-9][0-9])$",
                                                                    //     errorMessage: "Invalid Format",
                                                                    // },
                                                                }}
                                                            />
                                                        </div>
                                                }
                                            </Row>

                                            <Row>
                                                <Col className="d-flex flex-wrap gap-2 justify-content-center">
                                                    <Button className=" btn btn-success save-user w-md"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                    <Button type="reset" color="secondary" onClick={handleCancel}
                                                        className=" btn btn-secondary save-user w-md">
                                                        Cancel
                                                    </Button>
                                                </Col>
                                            </Row>






















                                            {/* <Col lg="6">
                                                <div className="mb-3">
                                                    <Label>Aircraft Model</Label>
                                                    <Select
                                                        value={selectedGroup}
                                                        onChange={() => {
                                                            handleSelectGroup()
                                                        }}
                                                        options={optionGroup}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Service Class</Label>
                                                    <Select
                                                        value={selectedGroup}
                                                        onChange={() => {
                                                            handleSelectGroup()
                                                        }}
                                                        options={optionGroup1}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Service Name</Label>
                                                    <Select
                                                        value={selectedGroup}
                                                        onChange={() => {
                                                            handleSelectGroup()
                                                        }}
                                                        options={optionGroup}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mt-3">
                                                    <Label>Description</Label>
                                                    
                                                    <Input
                                                        type="textarea"
                                                        id="textarea"
                                                        onChange={e => {
                                                            textareachange(e)
                                                        }}
                                                        maxLength="225"
                                                        rows="3"
                                                        placeholder="This textarea has a limit of 225 chars."
                                                    />
                                                    {textareabadge ? (
                                                        <span className="badgecount badge bg-success">
                                                            {" "}
                                                            {textcount} / 225{" "}
                                                        </span>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <label className="control-label">
                                                        Service Assigned
                          </label>
                                                    <Select
                                                        value={selectedMulti}
                                                        isMulti={true}
                                                        onChange={() => {
                                                            handleMulti()
                                                        }}
                                                        options={optionGroup}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Label>Aircraft Status</Label>
                                                    <Select
                                                        value={selectedGroup}
                                                        onChange={() => {
                                                            handleSelectGroup()
                                                        }}
                                                        options={optionGroup}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>

                                            </Col> */}



                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    {/* <Row>
                        <Col>
                            <div className="text-start">
                                <button
                                    type="submit"
                                    className="btn btn-success save-user "
                                >
                                    Save
                                                </button>
                            </div>
                        </Col>
                    </Row> */}

                </Container>
            </div>
        </React.Fragment>
    )
}

PanelGthreeForm.propTypes = {
    history: PropTypes.object
}

export default PanelGthreeForm
