import React from "react"
import { Redirect } from "react-router-dom"
// User profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Dashboard2 from "../pages/Dashboard-card/index"
import Desktop from "../pages/Desktop/index"

//Aviation Screens
import Users from "../pages/Users/index"
import CreateUser from "../pages/Users/createuser"
import ViewUser from "../pages/Users/viewuser"
import PanelG1 from "../pages/Configuration/PanelG1/panelg1"
import PanelGoneForm from "../pages/Configuration/PanelG1/panelg1form"
import ViewPanelGone from "../pages/Configuration/PanelG1/viewpanelg1form"
import PanelG2 from "../pages/Configuration/PanelG2/panelg2"
import PanelGtwoForm from "../pages/Configuration/PanelG2/panelg2form"
import ViewPanelGtwo from "../pages/Configuration/PanelG2/viewpanelg2form"
import PanelG3 from "../pages/Configuration/PanelG3/panelg3"
import PanelGthreeForm from "../pages/Configuration/PanelG3/panelg3form"
import ViewPanelGthree from "../pages/Configuration/PanelG3/viewpanelg3form"

import MDashboard from "../pages/Dashboard/maindashboard"
import MViewGone from "../pages/Dashboard/viewgone"
import Option1 from "../pages/Dashboard/option1"
import Option2 from "../pages/Dashboard/option2"
import MTwo from "../pages/Dashboard/mtwo"

import Forecast from "../pages/Dashboard/Screens/forecast"
import PrintForecast from "../pages/Dashboard/Screens/printforecast"
import Scheduler from "../pages/Dashboard/Screens/scheduler"
import PowerBi from "../pages/Dashboard/Screens/powerbi"

import Journey from "../pages/Dashboard/Screens/Journey/index"
import JourneyLog from "../pages/Dashboard/Screens/Journey/journey"
import ViewJourneyLog from "../pages/Dashboard/Screens/Journey/viewjourney"
import NewJourneyLog from "../pages/Dashboard/Screens/Journey/newjourney"

import OCCMPanel from "pages/Dashboard/Screens/occm/occm_panel"
import ViewOccmForm from "pages/Dashboard/Screens/occm/viewoccmform"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/dashboard-card", component: Dashboard2 },
  { path: "/desktop", component: Desktop },
  { path: "/users", component: Users },
  { path: "/usercreate", component: CreateUser },
  { path: "/viewuser/:id", component: ViewUser },

  { path: "/panelg1", component: PanelG1 },
  { path: "/panelg1form", component: PanelGoneForm },
  { path: "/viewpanelg1form/:id", component: ViewPanelGone },

  { path: "/panelg2", component: PanelG2 },
  { path: "/panelg2form", component: PanelGtwoForm },
  { path: "/viewpanelg2form/:id", component: ViewPanelGtwo },

  { path: "/panelg3", component: PanelG3 },
  { path: "/panelg3form", component: PanelGthreeForm },
  { path: "/viewpanelg3form/:id", component: ViewPanelGthree },

  { path: "/mdashboard", component: MDashboard },
  { path: "/mviewgone/:id", component: MViewGone },
  { path: "/option1", component: Option1 },
  { path: "/option2", component: Option2 },
  { path: "/mtwo/:id", component: MTwo },

  { path: "/forecast/:id", component: Forecast },
  { path: "/print-forecast/:id?", component: PrintForecast },
  { path: "/scheduler/:id", component: Scheduler },
  { path: "/powerbi", component: PowerBi },

  { path: "/journey/:id", component: Journey },
  { path: "/journeylog/:id", component: JourneyLog },
  { path: "/viewjourneylog/:id?", component: ViewJourneyLog },
  { path: "/newjourneylog", component: NewJourneyLog },

  { path: "/occm_panel", component: OCCMPanel },
  { path: "/occm_form/:id", component: ViewOccmForm },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> }

]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register }
]

export { authProtectedRoutes, publicRoutes }
