import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types"
import images from "assets/images";
import { getGone, editGoneStatus, deleteGone } from "actions/api";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
    getUsers as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty, size, map } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const PanelGone = props => {
    const { history } = props;
    const [gone, setGone] = useState([]);
    console.log("gone", gone)
    const dispatch = useDispatch();

    const { users } = useSelector(state => ({
        users: state.contacts.users,
    }));

    const [userList, setUserList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);


    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: sizePerPage,
        totalSize: gone.length, // replace later with size(users),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const selectRow = {
        mode: "checkbox",
    };

    const contactListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            // formatter: user => <>{user.id}</>,
        },
        {
            dataField: "id",
            text: "#",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    {!user.img ? (
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle">
                                {/* {user.name.charAt(0)} */}
                                {user.id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle avatar-xs"
                                src={images[user.img]}
                                alt=""
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            text: "Aircraft Model",
            dataField: "aircraft_name",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    <h5 className="font-size-14 mb-1">
                        <Link to="#" className="text-dark">
                            {user.aircraft_name}
                        </Link>
                    </h5>
                    {/* <p className="text-muted mb-0">{user.designation}</p> */}
                </>
            ),
        },
        {
            dataField: "msn",
            text: "MSN",
            sort: true,
        },
        {
            dataField: "reg_id",
            text: "National Reg ID",
            sort: true,
        },
        {
            dataField: "no_of_engines",
            text: "No of Engines",
            sort: true,
        },
        // {
        //     // dataField: "email",
        //     text: "Activate/Deactivate",
        //     sort: true,
        // },
        // {
        //     text: "Tags",
        //     dataField: "tags",
        //     sort: true,
        //     // eslint-disable-next-line react/display-name
        //     formatter: (cellContent, user) => (
        //         <>
        //             {map(
        //                 user.tags,
        //                 (tag, index) =>
        //                     index < 2 && (
        //                         <Link
        //                             to="#"
        //                             className="badge badge-soft-primary font-size-11 m-1"
        //                             key={"_skill_" + user.id + index}
        //                         >
        //                             {tag}
        //                         </Link>
        //                     )
        //             )}
        //             {size(user.tags) > 2 && (
        //                 <Link
        //                     to="#"
        //                     className="badge badge-soft-primary font-size-11 m-1"
        //                     key={"_skill_" + user.id}
        //                 >
        //                     {size(user.tags) - 1} + more
        //                 </Link>
        //             )}
        //         </>
        //     ),
        // },
        // {
        //     dataField: "projects",
        //     text: "Projects",
        //     sort: true,
        // },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <div className="d-flex gap-3">
                    <Link className="text-success" to="#">
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={() => handleUserClick(user)}
                        ></i>
                    </Link>
                    <Link className="text-danger" to="#">
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={() => handleDeleteUser(user)}
                        ></i>
                    </Link>
                    {user.status === 'Approved' ?
                        <Link className="text-success" to="#">
                            <i
                                className="mdi mdi-thumb-up font-size-18"
                            //onClick={() => handleApproveUser(user)}
                            ></i>
                        </Link> :
                        <Link className="text-success" to="#">
                            <i
                                className="mdi mdi-thumb-up-outline font-size-18"
                                onClick={() => handleApproveUser(user)}
                            ></i>
                        </Link>
                    }
                    {user.status === 'Rejected' ?
                        <Link className="text-warning" to="#">
                            <i
                                className="mdi mdi-thumb-down font-size-18"
                            //onClick={() => handleRejectUser(user)}
                            ></i>
                        </Link> :
                        <Link className="text-warning" to="#">
                            <i
                                className="mdi mdi-thumb-down-outline font-size-18"
                                onClick={() => handleRejectUser(user)}
                            ></i>
                        </Link>
                    }

                </div>
            ),
        },
    ];

    // useEffect(() => {
    //     if (users && !users.length) {
    //         dispatch(onGetUsers());
    //         setIsEdit(false);
    //     }
    // }, [dispatch, users]);

    // useEffect(() => {
    //     setUserList(users);
    //     setIsEdit(false);
    // }, [users]);

    // useEffect(() => {
    //     if (!isEmpty(users) && !!isEdit) {
    //         setUserList(users);
    //         setIsEdit(false);
    //     }
    // }, [users]);

    useEffect(() => {
        getGone()
            .then(resp => {
                setGone(resp)
            })
    }, []);

    const toggle = () => {
        setModal(!modal);
    };

    // const handleUserClick = arg => {
    //     const user = arg;

    //     setUserList({
    //         id: user.id,
    //         name: user.name,
    //         designation: user.designation,
    //         email: user.email,
    //         tags: user.tags,
    //         projects: user.projects,
    //     });
    //     setIsEdit(true);

    //     toggle();
    // };

    //props.history.push(`${process.env.PUBLIC_URL}/viewcompensation`, {id, filterCompensation})
    const handleUserClick = arg => {
        console.log("arg", arg)
        var id = arg.id;
        props.history.push(`/viewpanelg1form/${id}`)
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    // const handleDeleteUser = user => {
    //     if (user.id !== undefined) {
    //         dispatch(onDeleteUser(user));
    //         onPaginationPageChange(1);
    //     }
    // };

    const handleDeleteUser = async (user) => {
        const id = user.id;
        await deleteGone(id)
            .then(async (resp) => {
                await getGone()
                    .then(respo => {
                        setGone(respo)
                        props.history.push(`/panelg1`)
                    })
            })
            .catch(err => console.log("Err", err))
    };

    const handleApproveUser = user => {
        const id = user.id;
        const form = {
            status: 'Approved'
        }
        editGoneStatus(form, id)
            .then(resp => {
                getGone()
                    .then(respo => {
                        setGone(respo)
                        history.push('/panelg1')
                    })
            })
            .catch(err => {
                console.log("Error", err);
            })
    }

    const handleRejectUser = user => {
        const id = user.id;
        const form = {
            status: 'Rejected'
        }
        editGoneStatus(form, id)
            .then(resp => {
                getGone()
                    .then(respo => {
                        setGone(respo)
                        history.push('/panelg1')
                    })
            })
            .catch(err => {
                console.log("Error", err);
            })
    }

    /**
     * Handling submit user on user form
     */
    const handleValidUserSubmit = (e, values) => {
        if (isEdit) {
            const updateUser = {
                id: userList.id,
                name: values.name,
                designation: values.designation,
                tags: values.tags,
                email: values.email,
                projects: values.projects,
            };

            // update user
            dispatch(onUpdateUser(updateUser));
            setIsEdit(false);
        } else {
            const newUser = {
                id: Math.floor(Math.random() * (30 - 20)) + 20,
                name: values["name"],
                designation: values["designation"],
                email: values["email"],
                tags: values["tags"],
                projects: values["projects"],
            };
            // save new user
            dispatch(onAddNewUser(newUser));
        }
        toggle();
    };

    // const handleUserClicks = () => {
    //     setUserList("");
    //     setIsEdit(false);
    //     toggle();
    // };

    const handleNewPanel = () => {
        history.push('/panelg1form')
    }

    const keyField = "id";

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Fleet Configuration | Aircraft Setup</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Configuration" breadcrumbItem="Aircraft Setup" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField={keyField}
                                        columns={contactListColumns}
                                        data={gone}
                                    >
                                        {({ paginationProps, paginationTableProps }) => {
                                            return (
                                                <ToolkitProvider
                                                    keyField={keyField}
                                                    data={gone}
                                                    columns={contactListColumns}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col sm="4">
                                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="8">
                                                                    <div className="text-sm-end">
                                                                        <Button
                                                                            color="primary"
                                                                            className="font-16 btn-block btn btn-primary"
                                                                            onClick={handleNewPanel}
                                                                        >
                                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                                            Create New
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={keyField}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            selectRow={selectRow}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap table-hover"
                                                                            }
                                                                            bordered={false}
                                                                            striped={false}
                                                                            responsive
                                                                            ref={node}
                                                                        />

                                                                        <Modal isOpen={modal} toggle={toggle}>
                                                                            <ModalHeader toggle={toggle} tag="h4">
                                                                                {!!isEdit ? "Edit User" : "Add User"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <AvForm
                                                                                    onValidSubmit={handleValidUserSubmit}
                                                                                >
                                                                                    <Row form>
                                                                                        <Col xs={12}>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="name"
                                                                                                    label="Name"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid name"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.name || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="designation"
                                                                                                    label="Designation"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Designation"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={
                                                                                                        userList.designation || ""
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="email"
                                                                                                    label="Email"
                                                                                                    type="email"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    type="select"
                                                                                                    name="tags"
                                                                                                    className="form-select"
                                                                                                    label="Option"
                                                                                                    helpMessage="MULTIPLE!"
                                                                                                    multiple={true}
                                                                                                    required
                                                                                                    value={userList.tags || ""}
                                                                                                >
                                                                                                    <option>Photoshop</option>
                                                                                                    <option>illustrator</option>
                                                                                                    <option>Html</option>
                                                                                                    <option>Php</option>
                                                                                                    <option>Java</option>
                                                                                                    <option>Python</option>
                                                                                                    <option>
                                                                                                        UI/UX Designer
                                                                                                    </option>
                                                                                                    <option>Ruby</option>
                                                                                                    <option>Css</option>
                                                                                                </AvField>
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="projects"
                                                                                                    label="Projects"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Projects"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={
                                                                                                        userList.projects || ""
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-end">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                >
                                                                                                    Save
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AvForm>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            );
                                        }}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

PanelGone.propTypes = {
    history: PropTypes.object
}

export default withRouter(PanelGone);