import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import PropTypes from "prop-types"
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { postGone, getGone } from '../../actions/api';

const animatedComponents = makeAnimated()

const aircraftTypes = [
    { label: "Type 1", value: "Type 1" },
    { label: "Type 2", value: "Type 2" },
    { label: "Type 3", value: "Type 3" },
    { label: "Type 4", value: "Type 4" },
    { label: "Type 5", value: "Type 5" },
    { label: "Type 6", value: "Type 6" },
    { label: "Type 7", value: "Type 7" },
    { label: "Type 8", value: "Type 8" },
    { label: "Type 9", value: "Type 9" },
    { label: "Type 10", value: "Type 10" },
]

const aircraftStatus = [
    { label: "New", value: "New" },
    { label: "Leased", value: "Leased" },
    { label: "Option 3", value: "Option 3" },
    { label: "Option 4", value: "Option 4" },
    { label: "Option 5", value: "Option 5" },
]

const noOfEngines = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
]

const apuManufacturer = [
    { label: "APU Maker 1", value: "APU Maker 1" },
    { label: "APU Maker 2", value: "APU Maker 2" },
    { label: "APU Maker 3", value: "APU Maker 3" },
    { label: "APU Maker 4", value: "APU Maker 4" },
    { label: "APU Maker 5", value: "APU Maker 5" },
    { label: "APU Maker 6", value: "APU Maker 6" },
    { label: "APU Maker 7", value: "APU Maker 7" },
    { label: "APU Maker 8", value: "APU Maker 8" },
    { label: "APU Maker 9", value: "APU Maker 9" },
    { label: "APU Maker 10", value: "APU Maker 10" },
]

const apuModels = [
    { label: "APU M1", value: "APU M1" },
    { label: "APU M2", value: "APU M2" },
    { label: "APU M3", value: "APU M3" },
    { label: "APU M4", value: "APU M4" },
    { label: "APU M5", value: "APU M5" },
]

const mainManufacturer = [
    { label: "MainLG Maker 1", value: "MainLG Maker 1" },
    { label: "MainLG Maker 2", value: "MainLG Maker 2" },
    { label: "MainLG Maker 3", value: "MainLG Maker 3" },
    { label: "MainLG Maker 4", value: "MainLG Maker 4" },
    { label: "MainLG Maker 5", value: "MainLG Maker 5" },
    { label: "MainLG Maker 6", value: "MainLG Maker 6" },
    { label: "MainLG Maker 7", value: "MainLG Maker 7" },
    { label: "MainLG Maker 8", value: "MainLG Maker 8" },
    { label: "MainLG Maker 9", value: "MainLG Maker 9" },
    { label: "MainLG Maker 10", value: "MainLG Maker 10" },
]

const mainModels = [
    { label: "MainLG M1", value: "MainLG M1" },
    { label: "MainLG M2", value: "MainLG M2" },
    { label: "MainLG M3", value: "MainLG M3" },
    { label: "MainLG M4", value: "MainLG M4" },
    { label: "MainLG M5", value: "MainLG M5" },
]

const noseManufacturer = [
    { label: "NoseLG Maker 1", value: "NoseLG Maker 1" },
    { label: "NoseLG Maker 2", value: "NoseLG Maker 2" },
    { label: "NoseLG Maker 3", value: "NoseLG Maker 3" },
    { label: "NoseLG Maker 4", value: "NoseLG Maker 4" },
    { label: "NoseLG Maker 5", value: "NoseLG Maker 5" },
    { label: "NoseLG Maker 6", value: "NoseLG Maker 6" },
    { label: "NoseLG Maker 7", value: "NoseLG Maker 7" },
    { label: "NoseLG Maker 8", value: "NoseLG Maker 8" },
    { label: "NoseLG Maker 9", value: "NoseLG Maker 9" },
    { label: "NoseLG Maker 10", value: "NoseLG Maker 10" },
]

const noseModels = [
    { label: "NoseLG M1", value: "NoseLG M1" },
    { label: "NoseLG M2", value: "NoseLG M2" },
    { label: "NoseLG M3", value: "NoseLG M3" },
    { label: "NoseLG M4", value: "NoseLG M4" },
    { label: "NoseLG M5", value: "NoseLG M5" },
]

const engineManufacturer = [
    { label: "Maker 1", value: "Maker 1" },
    { label: "Maker 2", value: "Maker 2" },
    { label: "Maker 3", value: "Maker 3" },
    { label: "Maker 4", value: "Maker 4" },
    { label: "Maker 5", value: "Maker 5" },
    { label: "Maker 6", value: "Maker 6" },
    { label: "Maker 7", value: "Maker 7" },
    { label: "Maker 8", value: "Maker 8" },
    { label: "Maker 9", value: "Maker 9" },
    { label: "Maker 10", value: "Maker 10" },
]


const ViewPanelGone = (props) => {
    const { history } = props;
    const { match: { params } } = props

    const [form, setForm] = useState([]);
    const mfrDate = form.mfr_date ? new Date(form.mfr_date) : null;
    const aircraftDate = form.aircraft_date ? new Date(form.aircraft_date) : null;
    const apuDate = form.apu_last_shop_visit ? new Date(form.apu_last_shop_visit) : null;
    const mainDate = form.main_last_shop_visit ? new Date(form.main_last_shop_visit) : null;
    const mainRDate = form.main_last_shop_visit_r ? new Date(form.main_last_shop_visit_r) : null;
    const noseDate = form.nose_last_shop_visit ? new Date(form.nose_last_shop_visit) : null;
    const [remHours, setRemHours] = useState({})

    const [thresh, setThresh] = useState({})
    const [threshCount, setThreshCount] = useState({})
    const [engineRow, setEngineRow] = useState({})

    useEffect(() => {
        getGone()
            .then(resp => {
                // const filterGone = resp.find(x => x.id === parseInt(params.id))
                // setEngineRow(JSON.parse(filterGone.engine_details))
                // const aircraftName = [{ label: filterGone.aircraft_name, value: filterGone.aircraft_name }]
                // filterGone.aircraft_name = aircraftName;
                // setForm(filterGone)

                const filterGone = resp.find(x => x.id === parseInt(params.id))
                if (typeof filterGone.engine_details === 'string') {
                    setEngineRow(JSON.parse(filterGone.engine_details))
                } else {
                    setEngineRow(filterGone.engine_details)
                }
                const aircraftName = [{ label: filterGone.aircraft_name, value: filterGone.aircraft_name }]
                filterGone.aircraft_name = aircraftName;
                const aircraftStatus = [{ label: filterGone.aircraft_status, value: filterGone.aircraft_status }]
                filterGone.aircraft_status = aircraftStatus;
                const apuManu = [{ label: filterGone.apu_manufacturer, value: filterGone.apu_manufacturer }]
                filterGone.apu_manufacturer = apuManu;
                const apuMod = [{ label: filterGone.apu_model, value: filterGone.apu_model }]
                filterGone.apu_model = apuMod;
                const mainManu = [{ label: filterGone.main_manufacturer, value: filterGone.main_manufacturer }]
                filterGone.main_manufacturer = mainManu;
                const mainManuR = [{ label: filterGone.main_manufacturer_r, value: filterGone.main_manufacturer_r }]
                filterGone.main_manufacturer_r = mainManuR;

                const mainMod = [{ label: filterGone.main_model, value: filterGone.main_model }]
                filterGone.main_model = mainMod;
                const mainModR = [{ label: filterGone.main_model_r, value: filterGone.main_model_r }]
                filterGone.main_model_r = mainModR;

                const noseManu = [{ label: filterGone.nose_manufacturer, value: filterGone.nose_manufacturer }]
                filterGone.nose_manufacturer = noseManu;
                const noseMod = [{ label: filterGone.nose_model, value: filterGone.nose_model }]
                filterGone.nose_model = noseMod;
                setForm(filterGone)
            })
    }, []);

    function handleThresholdChange(event) {
        const { name, value } = event.target;
        thresh[name] = value.length
        const remain_val = 40 - 35

        if (remain_val <= thresh[name]) {
            setThresh({ ...thresh, [name]: true })
        } else {
            setThresh({ ...thresh, [name]: false })
        }
        setThreshCount({ ...threshCount, [name]: event.target.value.length })
    }

    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    function handleRemHoursChange(e) {
        let str = e.target.value;
        if (str.length === 7) {
            var first = str.substring(0, str.indexOf("-"))
            var last = str.substring(str.indexOf("-") + 1)
            var minutes = parseInt(first) + parseInt(last)
            setRemHours({ ...remHours, [e.target.name]: minutes })
        }
    }

    function handleAddRowNested(value) {
        // const modifiedRows = [...engineRow]
        let modifiedRows = [];
        for (let i = 0; i < value; i++) {
            modifiedRows.push({
                eng_mfr: '', eng_part_no: '', conf_eng_part_no: '', eng_sl_no: '', conf_eng_sl_no: '',
                eng_last_shop_visit: '', eng_rem_hrs: '', eng_rem_cycle: ''
            })
        }
        setEngineRow(modifiedRows)
    }

    const handleEngineChange = (e, i) => {
        const tempValues = [...engineRow];
        tempValues[i] = { ...tempValues[i], [e.target.name]: e.target.value };
        setEngineRow(tempValues);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const resData = { form, engineRow }
        // await getGone()
        // const res = await postGone(resData);
        postGone(resData)
            .then(resp => {
                console.log("respo", resp)
                history.push('/panelg1')
            })
            .catch(err => {
                console.log("Error", err);
            })
    }

    // onChange={e => {
    //     let str = e.target.value;
    //     var first = str.substring(0, str.indexOf("-"))
    //     var last = str.substring(str.indexOf("-") + 1)
    //     var minutes = parseInt(first) + parseInt(last)
    //     setForm({ ...form, [e.target.name]: minutes })
    // }}

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs breadcrumbItem="Dashboard" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <AvForm>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                {/* <AvField type="text" disabled maxLength={40} name="aircraft_name" value={form.aircraft_name}
                                                                /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.aircraft_name}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, aircraft_name: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                MSN
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="msn" value={form.msn} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Manufactured Date
                                                            </label>
                                                            <div className="col-md-8">
                                                                <DatePicker
                                                                    disabled
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={mfrDate}
                                                                    onChange={(date) => setForm({ ...form, mfr_date: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="aircraft_hours" value={form.aircraft_hours} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                No of Engines
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="no_of_engines" value={form.no_of_engines} />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Received Status
                                                            </label>
                                                            <div className="col-md-8">
                                                                {/* <AvField type="text" disabled name="aircraft_status" value={form.aircraft_status} /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.aircraft_status}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, aircraft_status: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                National Registration ID
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="reg_id" value={form.reg_id} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Date
                                                            </label>
                                                            <div className="col-md-8">
                                                                <DatePicker
                                                                    disabled
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={aircraftDate}
                                                                    onChange={(date) => setForm({ ...form, aircraft_date: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Cycles
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="number" disabled name="aircraft_cycles" value={form.aircraft_cycles}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {form?.no_of_engines >= 1 ?
                                                    engineRow.map((el, i) => {
                                                        const lastShopVisit = el.eng_last_shop_visit ?
                                                            new Date(el.eng_last_shop_visit) : null;
                                                        const engMfrDate = el.eng_mfr_date ?
                                                            new Date(el.eng_mfr_date) : null;
                                                        return (
                                                            <Row key={i} className="mb-3">
                                                                <h4 className="card-title mb-3 text-center">Engine {i + 1}</h4>
                                                                <Col lg={6}>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Engine Manufacturer
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <Select
                                                                                value={el.eng_mfr}
                                                                                isDisabled
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Part No
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" disabled name="eng_part_no" value={el.eng_part_no} />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Engine Status
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <Select
                                                                                value={el.eng_status}
                                                                                isDisabled
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Total Hours
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" disabled name="eng_rem_hrs" value={el.eng_rem_hrs} />
                                                                        </div>
                                                                    </Row>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Engine Model
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <Select
                                                                                value={el.eng_model}
                                                                                isDisabled
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Serial No
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" disabled name="eng_sl_no" value={el.eng_sl_no} />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Manufactured Date
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <DatePicker
                                                                                disabled
                                                                                className="form-control"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                placeholderText="DD/MM/YYYY"
                                                                                selected={engMfrDate}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Total Cycles
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" disabled name="eng_rem_cycle" value={el.eng_rem_cycle} />
                                                                        </div>
                                                                    </Row>
                                                                </Col>
                                                                <Row className="mb-3">
                                                                    <label htmlFor="name" className="col-md-4 col-form-label">
                                                                        Last Shop Visit
                                                                    </label>
                                                                    <div className="col-md-8">
                                                                        <DatePicker
                                                                            disabled
                                                                            className="form-control"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            placeholderText="DD/MM/YYYY"
                                                                            selected={lastShopVisit}
                                                                        />
                                                                    </div>
                                                                </Row>
                                                            </Row>
                                                        );
                                                    })
                                                    : null
                                                }
                                                {/* APU Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">APU Details</h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                APU Manufacturer
                                                            </label>
                                                            <div className="col-md-9">
                                                                {/* <AvField type="text" disabled name="apu_manufacturer" value={form.apu_manufacturer} /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.apu_manufacturer}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, apu_manufacturer: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                APU Serial No
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" disabled name="apu_sl_no" value={form.apu_sl_no} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                APU Last Shop Visit
                                                            </label>
                                                            <div className="col-md-9">
                                                                <DatePicker
                                                                    disabled
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={apuDate}
                                                                    onChange={(date) => setForm({ ...form, apu_last_shop_visit: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>

                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                {/* <AvField type="text" disabled name="apu_model" value={form.apu_model} /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.apu_model}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, apu_model: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="apu_product_no" value={form.apu_product_no} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="apu_remaining_hours" value={form.apu_remaining_hours}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            APU Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" disabled name="apu_remaining_cycles" value={form.apu_remaining_cycles}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* Main Landing Gear Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">
                                                        Main Landing Gear Details(Left)
                                                    </h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Manufacturer
                                                            </label>
                                                            <div className="col-md-9">
                                                                {/* <AvField type="text" disabled name="main_manufacturer" value={form.main_manufacturer} /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.main_manufacturer}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_manufacturer: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Serial No
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" disabled name="main_sl_no" value={form.main_sl_no} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Last Shop Visit
                                                            </label>
                                                            <div className="col-md-9">
                                                                <DatePicker
                                                                    disabled
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={mainDate}
                                                                    onChange={(date) => setForm({ ...form, main_last_shop_visit: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                {/* <AvField type="text" disabled name="main_model" value={form.main_model} /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.main_model}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_model: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="main_product_no" value={form.main_product_no} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="main_remaining_hours" value={form.main_remaining_hours}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" name="main_remaining_cycles" value={form.main_remaining_cycles}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* Main Landing Gear Right Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">
                                                        Main Landing Gear Details(Right)
                                                    </h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Manufacturer
                                                            </label>
                                                            <div className="col-md-9">
                                                                {/* <AvField type="text" disabled name="main_manufacturer_r" value={form.main_manufacturer_r} /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.main_manufacturer_r}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_manufacturer_r: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Serial No
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" disabled name="main_sl_no_r" value={form.main_sl_no_r} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Last Shop Visit
                                                            </label>
                                                            <div className="col-md-9">
                                                                <DatePicker
                                                                    disabled
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={mainRDate}
                                                                    onChange={(date) => setForm({ ...form, main_last_shop_visit_r: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                {/* <AvField type="text" disabled name="main_model_r" value={form.main_model_r} /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.main_model_r}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_model_r: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="main_product_no_r" value={form.main_product_no_r} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="main_remaining_hours_r" value={form.main_remaining_hours_r}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" name="main_remaining_cycles_r" value={form.main_remaining_cycles_r}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* Nose Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">
                                                        Nose Landing Gear Details
                                                    </h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Manufacturer
                                                            </label>
                                                            <div className="col-md-9">
                                                                {/* <AvField type="text" disabled name="nose_manufacturer" value={form.nose_manufacturer} /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.nose_manufacturer}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, nose_manufacturer: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Serial No
                                                            </label>
                                                            <div className="col-md-9">
                                                                <AvField type="text" disabled name="nose_sl_no" value={form.nose_sl_no} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-3 col-form-label"
                                                            >
                                                                Last Shop Visit
                                                            </label>
                                                            <div className="col-md-9">
                                                                <DatePicker
                                                                    disabled
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={noseDate}
                                                                    onChange={(date) => setForm({ ...form, nose_last_shop_visit: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                {/* <AvField type="text" disabled name="nose_model" value={form.nose_model} /> */}
                                                                <Select
                                                                    isDisabled
                                                                    value={form.nose_model}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, nose_model: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="nose_product_no" value={form.nose_product_no} />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" disabled name="nose_remaining_hours" value={form.nose_remaining_hours}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" disabled name="nose_remaining_cycles" value={form.nose_remaining_cycles}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* <Row>
                                                    <Col className="d-flex flex-wrap gap-2 justify-content-center">
                                                        <Button className=" btn btn-success save-user w-md"
                                                            type="submit" onClick={handleSubmit}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button type="reset" color="secondary" className=" btn btn-secondary save-user w-md">
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                </Row> */}

                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

ViewPanelGone.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(ViewPanelGone)