import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types"
import images from "assets/images";
import { getGone, getJourney, deleteJourney } from "actions/api";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
    getUsers as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty, size, map } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const Journey = props => {
    const { history } = props;
    const { match: { params } } = props
    const id = parseInt(params.id);
    const [gone, setGone] = useState([]);
    const [journey, setJourney] = useState([]);
    console.log("gone", gone)
    const dispatch = useDispatch();

    const { users } = useSelector(state => ({
        users: state.contacts.users,
    }));

    const [userList, setUserList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);


    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: sizePerPage,
        totalSize: journey.length, // replace later with size(users),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const selectRow = {
        mode: "checkbox",
    };

    const contactListColumns = [
        {
            text: "id",
            dataField: "",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            // formatter: user => <>{user.id}</>,
        },
        {
            dataField: "",
            text: "#",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    {!user.img ? (
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle">
                                {/* {user.name.charAt(0)} */}
                                {user.id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle avatar-xs"
                                src={images[user.img]}
                                alt=""
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            text: "Aircraft Type",
            dataField: "aircraft_type",
            sort: true,
        },
        {
            dataField: "registration",
            text: "Registration",
            sort: true,
        },
        {
            dataField: "flight_num_1",
            text: "Flight Number",
            sort: true,
        },
        {
            dataField: "sector_from_1",
            text: "From",
            sort: true,
        },
        {
            dataField: "sector_to_1",
            text: "To",
            sort: true,
        },
        {
            dataField: "total_flight_hrs",
            text: "Flight Hours",
            sort: true,
            // formatter: (cellContent, data) => {
            //     return <div>{data.current_fh_hr + '-' + data.current_fh_min}</div>
            // }
        },
        {
            dataField: "total_flight_cyc",
            text: "Flight Cycles",
            sort: true,
        },
        // {
        //     dataField: "action_taken",
        //     text: "Action Taken",
        //     sort: true,
        // },
        // {
        //     dataField: "defect_description",
        //     text: "Defect Desc",
        //     sort: true,
        // },
        // {
        //     // dataField: "email",
        //     text: "Activate/Deactivate",
        //     sort: true,
        // },
        // {
        //     text: "Tags",
        //     dataField: "tags",
        //     sort: true,
        //     // eslint-disable-next-line react/display-name
        //     formatter: (cellContent, user) => (
        //         <>
        //             {map(
        //                 user.tags,
        //                 (tag, index) =>
        //                     index < 2 && (
        //                         <Link
        //                             to="#"
        //                             className="badge badge-soft-primary font-size-11 m-1"
        //                             key={"_skill_" + user.id + index}
        //                         >
        //                             {tag}
        //                         </Link>
        //                     )
        //             )}
        //             {size(user.tags) > 2 && (
        //                 <Link
        //                     to="#"
        //                     className="badge badge-soft-primary font-size-11 m-1"
        //                     key={"_skill_" + user.id}
        //                 >
        //                     {size(user.tags) - 1} + more
        //                 </Link>
        //             )}
        //         </>
        //     ),
        // },
        // {
        //     dataField: "projects",
        //     text: "Projects",
        //     sort: true,
        // },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <div className="d-flex gap-3">
                    <Link className="text-success" to="#">
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={() => handleUserClick(user)}
                        ></i>
                    </Link>
                    <Link className="text-danger" to="#">
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={() => handleDeleteUser(user)}
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    // useEffect(() => {
    //     if (users && !users.length) {
    //         dispatch(onGetUsers());
    //         setIsEdit(false);
    //     }
    // }, [dispatch, users]);

    // useEffect(() => {
    //     setUserList(users);
    //     setIsEdit(false);
    // }, [users]);

    // useEffect(() => {
    //     if (!isEmpty(users) && !!isEdit) {
    //         setUserList(users);
    //         setIsEdit(false);
    //     }
    // }, [users]);

    useEffect(() => {
        getGone()
            .then(resp => {
                const filterGone = resp.find(x => x.id === id)
                const regId = filterGone && filterGone.reg_id;
                setGone(filterGone)
                getJourney()
                    .then(resp2 => {
                        const filterJourney = resp2.filter(x => x.registration === regId);
                        setJourney(filterJourney ? filterJourney : resp2)
                    })
            })
    }, []);

    const toggle = () => {
        setModal(!modal);
    };

    // const handleUserClick = arg => {
    //     const user = arg;

    //     setUserList({
    //         id: user.id,
    //         name: user.name,
    //         designation: user.designation,
    //         email: user.email,
    //         tags: user.tags,
    //         projects: user.projects,
    //     });
    //     setIsEdit(true);

    //     toggle();
    // };

    //props.history.push(`${process.env.PUBLIC_URL}/viewcompensation`, {id, filterCompensation})
    const handleUserClick = arg => {
        var view_id = arg.id;
        var gone_id = id;
        var ids = [view_id, gone_id]
        // props.history.push(`/viewjourneylog/${id}`)
        // "/print-forecast/" + selectedIds
        props.history.push('/viewjourneylog/' + ids)
    }

    const handleDeleteUser = async (arg) => {
        const view_id = arg.id;
        var gone_id = id;
        await deleteJourney(view_id)
            .then(async (resp0) => {
                getGone()
                    .then(resp => {
                        const filterGone = resp.find(x => x.id === gone_id)
                        const regId = filterGone && filterGone.reg_id;
                        setGone(filterGone)
                        getJourney()
                            .then(resp2 => {
                                const filterJourney = resp2.filter(x => x.registration === regId);
                                setJourney(filterJourney ? filterJourney : resp2)
                                props.history.push(`/journey/${gone_id}`)
                            })
                    })
            })
            .catch(err => console.log("Err", err))
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    // const handleDeleteUser = user => {
    //     if (user.id !== undefined) {
    //         dispatch(onDeleteUser(user));
    //         onPaginationPageChange(1);
    //     }
    // };


    /**
     * Handling submit user on user form
     */
    const handleValidUserSubmit = (e, values) => {
        if (isEdit) {
            const updateUser = {
                id: userList.id,
                name: values.name,
                designation: values.designation,
                tags: values.tags,
                email: values.email,
                projects: values.projects,
            };

            // update user
            dispatch(onUpdateUser(updateUser));
            setIsEdit(false);
        } else {
            const newUser = {
                id: Math.floor(Math.random() * (30 - 20)) + 20,
                name: values["name"],
                designation: values["designation"],
                email: values["email"],
                tags: values["tags"],
                projects: values["projects"],
            };
            // save new user
            dispatch(onAddNewUser(newUser));
        }
        toggle();
    };

    // const handleUserClicks = () => {
    //     setUserList("");
    //     setIsEdit(false);
    //     toggle();
    // };

    const handleNewPanel = () => {
        history.push(`/journeylog/${id}`)
    }

    const keyField = "id";

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Fleet Configuration | Journey Log</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Tier 4" breadcrumbItem="Journey Log" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField={keyField}
                                        columns={contactListColumns}
                                        data={journey}
                                    >
                                        {({ paginationProps, paginationTableProps }) => {
                                            return (
                                                <ToolkitProvider
                                                    keyField={keyField}
                                                    data={journey}
                                                    columns={contactListColumns}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col sm="4">
                                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="8">
                                                                    <div className="text-sm-end">
                                                                        <Button
                                                                            color="primary"
                                                                            className="font-16 btn-block btn btn-primary"
                                                                            onClick={handleNewPanel}
                                                                        >
                                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                                            Create New
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={keyField}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            selectRow={selectRow}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap table-hover"
                                                                            }
                                                                            bordered={false}
                                                                            striped={false}
                                                                            responsive
                                                                            ref={node}
                                                                        />

                                                                        <Modal isOpen={modal} toggle={toggle}>
                                                                            <ModalHeader toggle={toggle} tag="h4">
                                                                                {!!isEdit ? "Edit User" : "Add User"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <AvForm
                                                                                    onValidSubmit={handleValidUserSubmit}
                                                                                >
                                                                                    <Row form>
                                                                                        <Col xs={12}>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="name"
                                                                                                    label="Name"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid name"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.name || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="designation"
                                                                                                    label="Designation"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Designation"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={
                                                                                                        userList.designation || ""
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="email"
                                                                                                    label="Email"
                                                                                                    type="email"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    type="select"
                                                                                                    name="tags"
                                                                                                    className="form-select"
                                                                                                    label="Option"
                                                                                                    helpMessage="MULTIPLE!"
                                                                                                    multiple={true}
                                                                                                    required
                                                                                                    value={userList.tags || ""}
                                                                                                >
                                                                                                    <option>Photoshop</option>
                                                                                                    <option>illustrator</option>
                                                                                                    <option>Html</option>
                                                                                                    <option>Php</option>
                                                                                                    <option>Java</option>
                                                                                                    <option>Python</option>
                                                                                                    <option>
                                                                                                        UI/UX Designer
                                                                                                    </option>
                                                                                                    <option>Ruby</option>
                                                                                                    <option>Css</option>
                                                                                                </AvField>
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="projects"
                                                                                                    label="Projects"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Projects"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={
                                                                                                        userList.projects || ""
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-end">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                >
                                                                                                    Save
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AvForm>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            );
                                        }}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

Journey.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(Journey);