import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"


const FormAdvanced = () => {
    const [form, setForm] = useState({ pic_sign: "Yes", commander_sign: "Yes", daily_sign: "Yes", sign_stamp: "Yes" });
    console.log("form", form)

    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    function handleChecked(e) {
        setForm({ ...form, [e.target.name]: e.target.checked })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Aerotrend | JOURNEY LOG PANEL</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="JOURNEY LOG PANEL" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title"> Journey Log Panel</h4>
                                    {/* <p className="card-title-desc">
                    A mobile and touch friendly input spinner component for
                    Bootstrap
                  </p> */}

                                    <AvForm onValidSubmit="">
                                        <Row>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Company Name
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="company_name"
                                                            value={form.company_name}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Date
                                                    </label>
                                                    <div className="col-md-6">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Aircraft Registration
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="aircraft_regn_1" value={form.aircraft_regn_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Aircraft Type
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="aircraft_type" value={form.aircraft_type}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}

                                                        />

                                                    </div>

                                                </Row>
                                            </Col>

                                            <Col>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Log Serial No.
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="log_sl_no" value={form.log_sl_no}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        PIC Name
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="pic_name" value={form.pic_name}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        PIC License No
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="pic_lic_no" value={form.pic_lic_no}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}

                                                        />


                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        PIC Signature
                                                    </label>
                                                    <div className="col-md-8 mt-2">
                                                        <AvRadioGroup inline name="pic_sign" value={form.pic_sign}
                                                            onChange={(e) => { handleChange(e) }} required>
                                                            <AvRadio label="Yes" value="Yes" />
                                                            <AvRadio label="No" value="No" />
                                                        </AvRadioGroup>
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Commander Pre-Flight Inspection Signature
                                                    </label>
                                                    <div className="col-md-8 mt-4">
                                                        <AvRadioGroup inline name="commander_sign" value={form.commander_sign}
                                                            onChange={(e) => { handleChange(e) }} required>
                                                            <AvRadio label="Yes" value="Yes" />
                                                            <AvRadio label="No" value="No" />
                                                        </AvRadioGroup>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <h4 className="card-title mt-5 "> Sl.No: 1</h4>
                                            <Col>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Flight Number
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="flight_num" value={form.flight_num}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector From
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="sector_from" value={form.sector_from}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector To
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="sector_to" value={form.sector_to}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h4 className="card-title mt-2"> On-Chock</h4>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>

                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector Duration
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col >
                                                <h4 className="card-title mt-2"> Off-Chock</h4>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>

                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector Duration
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <h4 className="card-title mt-5 "> Sl.No: 2</h4>
                                            <Col>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Flight Number
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="flight_num" value={form.flight_num}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector From
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="sector_from" value={form.sector_from}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector To
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="sector_to" value={form.sector_to}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h4 className="card-title mt-2"> On-Chock</h4>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>

                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector Duration
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col >
                                                <h4 className="card-title mt-2"> Off-Chock</h4>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>

                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector Duration
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Row className="mb-3 mt-5">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Arrival
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="fuel_arrive" value={form.fuel_arrive}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Departure
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="fuel_dept" value={form.fuel_dept}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Remaining Fuel Onboard
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="fuel_onboard" value={form.fuel_onboard}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>

                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Uplift
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="fuel_uplift" value={form.fuel_uplift}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Calculate Total Fuel
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="cal_total_fuel" value={form.cal_total_fuel}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Discrepancy
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="fuel_discrepancy" value={form.fuel_discrepancy}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>

                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Aircraft Total Hrs
                                                    </label>
                                                    <div className="col-md-8">
                                                        <InputGroup>
                                                            <Flatpickr
                                                                className="form-control d-block"
                                                                placeholder="HH/MM"
                                                                options={{
                                                                    enableTime: true,
                                                                    noCalendar: true,
                                                                    dateFormat: "H:i",
                                                                    time_24hr: true
                                                                }}
                                                            />
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">
                                                                    <i className="mdi mdi-clock-outline" />
                                                                </span>
                                                            </div>
                                                        </InputGroup>

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Aircraft Total Cyc
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="aircraft_total_cyc" value={form.aircraft_total_cyc}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Flight Deck Gauge
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="deck_gauge" value={form.deck_gauge}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Next Due Maintenance
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Due@Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Due@Hours
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="hours" value={form.hours}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                            </Col>

                                            <Col >



                                                <Row className="mb-3 mt-5">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Total Flight Hrs
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="total_fh_" value={form.total_fh}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Total Flight Cyc
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="total_fc" value={form.total_fc}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Daily Inspection
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Type of Maintenance
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="maintenance" value={form.maintenance}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        APU Hrs
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="apu_hrs" value={form.apu_hrs}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        APU Cyc
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="apu_cyc" value={form.apu_cyc}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Oil Uplift Engine No.1
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="engine_num_1" value={form.engine_num_1}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Oil Uplift Engine No.2
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="engine_num_2" value={form.engine_num_2}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }} />

                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Oil Uplift APU
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="apu_uplift" value={form.apu_uplift}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }} />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Daily Inspection Signature & Stamp
                                                    </label>
                                                    <div className="col-md-8 mt-2">
                                                        <AvRadioGroup inline name="daily_sign" value={form.daily_sign}
                                                            onChange={(e) => { handleChange(e) }} required>
                                                            <AvRadio label="Yes" value="Yes" />
                                                            <AvRadio label="No" value="No" />
                                                        </AvRadioGroup>
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Defect Description
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="textarea" id="textarea" rows="3" maxLength="10" name="defect_desc" value={form.apu_cyc}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row className="mb-3 mt-2">
                                                    <div className="form-check form-check-end">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                            checked={form.pirep}
                                                            name="pirep"
                                                            onChange={e => {
                                                                handleChecked(e)

                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            PIREP
                                                        </label>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <div className="form-check form-check-end  mt-2 md-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck2"
                                                            defaultChecked
                                                            checked={form.marep}
                                                            name="marep"
                                                            onChange={e => {
                                                                handleChecked(e)

                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label "
                                                            htmlFor="defaultCheck2"
                                                        >
                                                            MAREP
                                                        </label>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <div className="form-check form-check-end">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                            checked={form.info}
                                                            name="info"
                                                            onChange={e => {
                                                                handleChecked(e)

                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            INFO
                                                        </label>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <div className="form-check form-check-end  mt-2 md-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck2"
                                                            defaultChecked
                                                            checked={form.cabin}
                                                            name="cabin"
                                                            onChange={e => {
                                                                handleChecked(e)

                                                            }}


                                                        />
                                                        <label
                                                            className="form-check-label "
                                                            htmlFor="defaultCheck2"
                                                        >
                                                            CABIN
                                                        </label>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <h4 className="card-title mt-5">Action Taken</h4>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        MEL Expiry Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />

                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        MEL Reference
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="mel_reference" value={form.mel_reference}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        MEL Repair cat
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="mel_repair" value={form.mel_repair}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        LIC No
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" maxLength="10" name="lic_no" value={form.lic_no}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h3 className="card-title mt-5">Part Description - 1</h3>
                                                <Row className="mb-3 mt-5">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Part Number On
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="part_no_on" value={form.part_no_on}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}

                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Part Number Off
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="part_no_off" value={form.part_no_off}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}

                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Serial Number On
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="serial_no_on" value={form.serial_no_on}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}

                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Serial Number Off
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="serial_no_off" value={form.serial_no_off}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Certificate Number
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="certificate_no" value={form.certificate_no}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <h3 className="card-title mt-5">Part Description - 2</h3>
                                                <Row className="mb-3 mt-5">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Part Number On
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="part_no_on" value={form.part_no_on}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}

                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Part Number Off
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="part_no_off" value={form.part_no_off}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}

                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Serial Number On
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="serial_no_on" value={form.serial_no_on}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}

                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Serial Number Off
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="serial_no_off" value={form.serial_no_off}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Certificate Number
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" maxLength="10" name="certificate_no" value={form.certificate_no}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3 mt-6">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-4 col-form-label"
                                            >
                                                Signature and Stamp
                                            </label>
                                            <div className="col-md-8 mt-2">
                                                <AvRadioGroup inline name="sign_stamp" value={form.sign_stamp}
                                                    onChange={(e) => { handleChange(e) }} required>
                                                    <AvRadio label="Yes" value="Yes" />
                                                    <AvRadio label="No" value="No" />
                                                </AvRadioGroup>
                                            </div>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default FormAdvanced