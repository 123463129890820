import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
    Table
} from "reactstrap";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { getGone, getGtwo, postBulkGtwo, getOccm, postOccm, editOccm, deleteOccm } from "actions/api";
import images from "assets/images";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import XLSX from 'xlsx';
import { make_cols } from '../makeColumns';
import { SheetJSFT } from '../types';
import * as moment from "moment";

import {
    getUsers as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty, size, map } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const OCCMPanel = props => {
    const { history } = props;
    const { match: { params } } = props;
    const id = parseInt(params.id);
    const gone_id = parseInt(params.id);
    // console.log("id", id)
    const dispatch = useDispatch();
    const [gone, setGone] = useState([])
    const [gtwo, setGtwo] = useState([])
    const [gtwoData, setGtwoData] = useState(null);
    const [occm, setOccm] = useState([])
    const { users } = useSelector(state => ({
        users: state.contacts.users,
    }));


    const [userList, setUserList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: sizePerPage,
        totalSize: occm.length, // replace later with size(users),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const selectRow = {
        mode: "checkbox",
    };

    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    const contactListColumns = [
        {
            text: "id",
            // dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "id",
            text: "#",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    {!user.img ? (
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle">
                                {/* {user.name.charAt(0)} */}
                                {user.id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle avatar-xs"
                                src={images[user.img]}
                                alt=""
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            dataField: "comp_part_no",
            text: "PartNo",
            sort: true,
        },
        {
            dataField: "serial_no",
            text: "SerialNo",
            sort: true,
        },
        {
            dataField: "description",
            text: "Description",
            sort: true,
        },
        {
            dataField: "pos",
            text: "POS",
            sort: true,
        },
        {
            dataField: "inst_date",
            text: "INST Date",
            sort: true,
            formatter: (cell, row) => {
                const instDate = row.inst_date ? handleValidDate(row.inst_date) : null
                return <>{instDate}</>;
            },
        },
        {
            dataField: "tsn",
            text: "TSN",
            sort: true,
        },
        {
            dataField: "csn",
            text: "CSN",
            sort: true,
        },
        {
            dataField: "tsi",
            text: "TSI",
            sort: true,
        },
        {
            dataField: "csi",
            text: "CSI",
            sort: true,
        },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <div className="d-flex gap-3">
                    <Link className="text-success" to="#">
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={() => handleUserClick(user)}
                        ></i>
                    </Link>
                    <Link className="text-danger" to="#">
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={() => handleDeleteUser(user)}
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    // useEffect(() => {
    //     if (users && !users.length) {
    //         dispatch(onGetUsers());
    //         setIsEdit(false);
    //     }
    // }, [dispatch, users]);

    // useEffect(() => {
    //     setUserList(users);
    //     setIsEdit(false);
    // }, [users]);

    // useEffect(() => {
    //     if (!isEmpty(users) && !!isEdit) {
    //         setUserList(users);
    //         setIsEdit(false);
    //     }
    // }, [users]);

    useEffect(() => {
        getGtwo()
            .then(resp => {
                setGtwo(resp)
                getOccm()
                    .then(resp2 => {
                        setOccm(resp2)
                        setSubmitting(false)
                    })
            })
    }, [submitting]);

    const toggle = () => {
        setModal(!modal);
        setSubmitting(true)
    };

    // const handleUserClick = arg => {
    //     const user = arg;

    //     setUserList({
    //         id: user.id,
    //         name: user.name,
    //         designation: user.designation,
    //         email: user.email,
    //         tags: user.tags,
    //         projects: user.projects,
    //     });
    //     setIsEdit(true);

    //     toggle();
    // };

    const handleUserClick = arg => {
        var id = arg.id;
        props.history.push(`/occm_form/${id}`)
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    // const handleDeleteUser = user => {
    //     if (user.id !== undefined) {
    //         dispatch(onDeleteUser(user));
    //         onPaginationPageChange(1);
    //     }
    // };

    const handleDeleteUser = async (user) => {
        const id = user.id;
        await deleteOccm(id)
            .then(async (resp) => {
                await getOccm()
                    .then(respo => {
                        setOccm(respo)
                        props.history.push('/occm_panel')
                    })
            })
            .catch(err => console.log("Err", err))
    };

    /**
     * Handling submit user on user form
     */
    const handleValidUserSubmit = (e, values) => {
        if (isEdit) {
            const updateUser = {
                id: userList.id,
                name: values.name,
                designation: values.designation,
                tags: values.tags,
                email: values.email,
                projects: values.projects,
            };

            // update user
            dispatch(onUpdateUser(updateUser));
            setIsEdit(false);
        } else {
            const newUser = {
                id: Math.floor(Math.random() * (30 - 20)) + 20,
                name: values["name"],
                designation: values["designation"],
                email: values["email"],
                tags: values["tags"],
                projects: values["projects"],
            };
            // save new user
            dispatch(onAddNewUser(newUser));
        }
        toggle();
    };

    const handleNewPanel = () => {
        history.push('/panelg3form')
    }

    const handleUserClicks = () => {
        setUserList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [cols, setCols] = useState([]);



    const handleChange = (e) => {
        const files = e.target.files;
        setFile(document.getElementById('file').files[0]);
    };

    const handleFile = async () => {
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            // /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {
                type: rABS ? 'binary' : 'array',
                bookVBA: true,
            });
            // /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            // /* Convert array of arrays */
            const dataa = XLSX.utils.sheet_to_json(ws, { raw: false });
            // /* Update state */
            setData(dataa);
            // setCols(make_cols(ws['!ref']));
            console.log(JSON.stringify(dataa, null, 2));
            let arrayObj = dataa;
            let componentArr = [];
            arrayObj.forEach(item => {
                componentArr.push({
                    comp_part_no: item.PART_NO,
                    comp_name: item.DESCRIPTION,
                    comp_class: 'Active',
                    comp_class_change_date: new Date(item.INST_DATE)
                })
            });
            let ocmArr = [];
            arrayObj.forEach(item => {
                ocmArr.push({
                    ata: item.ATA,
                    comp_part_no: item.PART_NO,
                    serial_no: item.SERIAL_NO,
                    description: item.DESCRIPTION,
                    pos: item.POS,
                    inst_date: new Date(item.INST_DATE),
                    tsn: item.TSN,
                    csn: item.CSN,
                    tsi: item.TSI,
                    csi: item.CSI,
                })
            });
            const filterCompArr = componentArr && componentArr.filter(x => ![... new Set(gtwo.map(n => n.comp_part_no))].includes(x.comp_part_no))

            const filteredArr = filterCompArr.reduce((acc, current) => {
                const x = acc.find(item => item.comp_part_no === current.comp_part_no);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);
            console.log("filteredArr", filteredArr)

            if (ocmArr.length > 0) {
                postOccm(ocmArr)
                    .then(resp => {
                        // props.history.push('/occm_panel')
                        setIsEdit(false);
                        setGtwoData(filteredArr)
                        toggle();
                    })
            }

        };

        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        }

    };

    const handleSubmitGtwo = () => {
        // Post Gtwo
        if (gtwoData.length > 0) {
            postBulkGtwo(gtwoData)
                .then(resp => {
                    setSubmitting(true)
                    setFile(null)
                    toggle();
                    props.history.push('/occm_panel')
                })
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>OCCM Panel</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Component" breadcrumbItem="OCCM Panel" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField={keyField}
                                        columns={contactListColumns}
                                        data={occm}
                                    >
                                        {({ paginationProps, paginationTableProps }) => {
                                            return (
                                                <ToolkitProvider
                                                    keyField={keyField}
                                                    data={occm}
                                                    columns={contactListColumns}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-0">
                                                                <Col sm="4">
                                                                    <div className="search-box ms-2 mb-2 mt-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="8">
                                                                    <div className="table-responsive">
                                                                        <Table className="table mb-0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>
                                                                                        <label htmlFor="file" className="mt-2">Upload Excel file</label>
                                                                                    </th>
                                                                                    <td>
                                                                                        <input
                                                                                            type="file"
                                                                                            className="form-control"
                                                                                            id="file"
                                                                                            accept={SheetJSFT}
                                                                                            onChange={handleChange}
                                                                                        />
                                                                                    </td>
                                                                                    <th>
                                                                                        {file !== null ?
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className="font-16 btn-block btn btn-primary"
                                                                                                onClick={handleFile}
                                                                                            >
                                                                                                Submit
                                                                                            </Button>
                                                                                            : null
                                                                                        }
                                                                                    </th>
                                                                                    <td>{ }</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={keyField}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            selectRow={selectRow}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap table-hover"
                                                                            }
                                                                            bordered={false}
                                                                            striped={false}
                                                                            responsive
                                                                            ref={node}
                                                                        />

                                                                        <Modal isOpen={modal} toggle={toggle}>
                                                                            <ModalHeader toggle={toggle} tag="h4">
                                                                                {!!isEdit ? "Adding Component" : "Adding Component"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <Row form>
                                                                                    <Col xs={12}>
                                                                                        <p>On confirmation, the number of components: <strong>{gtwoData?.length}</strong> gets added to the component master, Are you sure to Approve this?</p>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <div className="text-center">
                                                                                            <button
                                                                                                type="submit"
                                                                                                className="btn btn-success save-user"
                                                                                                onClick={handleSubmitGtwo}
                                                                                            >
                                                                                                Approve
                                                                                            </button>
                                                                                            <button
                                                                                                type="submit"
                                                                                                className="btn btn-danger save-user ms-2"
                                                                                                onClick={() => toggle()}
                                                                                            >
                                                                                                Decline
                                                                                            </button>
                                                                                        </div>
                                                                                        {/* <div className="text-center">
                                                                                            <button
                                                                                                type="submit"
                                                                                                className="btn btn-danger save-user"
                                                                                            >
                                                                                                Decline
                                                                                            </button>
                                                                                        </div> */}
                                                                                    </Col>
                                                                                </Row>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            );
                                        }}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
OCCMPanel.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(OCCMPanel);
