import React, { Component } from "react"
import MetaTags from 'react-meta-tags';

import {
    Card,
    CardBody,
    CardColumns,
    CardDeck,
    CardFooter,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    Container,
    Row,
} from "reactstrap"

// import images
import Configuration from "../../assets/images/section/Configuration.jpg"
import Flight from "../../assets/images/section/Flight.jpg"
import Airworthiness from "../../assets/images/section/Airworthiness.jpg"
import Maintenance from "../../assets/images/section/Maintenance.jpg"
import Quality from "../../assets/images/section/Quality.jpg"
import Human from "../../assets/images/section/Human.jpg"
import Stores from "../../assets/images/section/Stores.jpg"
import Warehouse from "../../assets/images/section/Procurement.jpg"
import Management from "../../assets/images/section/Management.jpg"
import Procurement from "../../assets/images/section/Procurement2.png"
import Accounts from "../../assets/images/section/Accounts.jpg"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

class Option2 extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Home | AEROTREND</title>
                    </MetaTags>
                    <Container fluid={true}>
                        <Breadcrumbs title="Main Menu" breadcrumbItem="Aviation" />

                        <Row>
                            <Col md={6} xl={2}>
                                <Card>

                                    <CardBody className="text-center" style={{ width: "85%", height: "60%" }} >
                                        <CardTitle className="h1 mt-0">Task Card Panel S1 </CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md={6} xl={2}>
                                <Card>

                                    <CardBody className="text-center" style={{ width: "85%", height: "60%" }}>
                                        <CardTitle className="h1 mt-0">Journey Log Panel S2 </CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>

                            {/* <Col md={6} xl={2}>
                <Card>
                  <CardImg top className="img-fluid" src={Airworthiness} alt="Skote" />
                  <CardBody className="text-center">
                    <CardTitle className="h4 ">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/desktop" >
                      {"CAMO"}
                    </Link>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col> */}

                            <Col md={6} xl={2}>
                                <Card>

                                    <CardBody className="text-center" style={{ width: "85%", height: "60%" }} >
                                        <CardTitle className="h1 mt-0">Maintenance Log Panel S3 </CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md={6} xl={2}>
                                <Card>

                                    <CardBody className="text-center" style={{ width: "100%", height: "60%" }} >
                                        <CardTitle className="h1 mt-0">Technical Records Summary Panel S4 </CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6} xl={2}>
                                <Card>

                                    <CardBody className="text-center" style={{ width: "85%", height: "60%" }}>
                                        <CardTitle className="h1 mt-0">Documentation Panel S5</CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6} xl={2}>
                                <Card>

                                    <CardBody className="text-center" style={{ width: "85%", height: "60%" }}>
                                        <CardTitle className="h1 mt-0">Documentation Panel S5</CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col md={6} xl={2}>
                <Card>
                  <CardImg top className="img-fluid" src={Procurement} alt="Skote" />
                  <CardBody className="text-center">
                    <CardTitle className="h4 mt-0">Procurement</CardTitle>
                  </CardBody>
                </Card>
              </Col> */}
                            <Col md={6} xl={2}>
                                <Card>

                                    <CardBody className="text-center" style={{ width: "85%", height: "60%" }}>
                                        <CardTitle className="h1 mt-0">Planning Panel S7</CardTitle>
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* <Col md={6} xl={2}>
                <Card>
                  <CardImg top className="img-fluid" src={Accounts} alt="Skote" />
                  <CardBody className="text-center">
                    <CardTitle className="h4 mt-0"></CardTitle>
                  </CardBody>
                </Card>
              </Col> */}

                        </Row>




                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default Option2
