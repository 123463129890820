import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, InputGroup, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { SketchPicker } from "react-color"
import ColorPicker from "@vtaits/react-color-picker"
import "@vtaits/react-color-picker/dist/index.css"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Switch from "react-switch"
import Select from "react-select"
import makeAnimated from "react-select/animated"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import PropTypes from "prop-types"
//Import Breadcrumb
// import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import * as moment from "moment";
import { editGone, getGone } from '../../../actions/api';

const animatedComponents = makeAnimated()

const aircraftTypes = [
    { label: "B737-700", value: "B737-700" },
    { label: "B737-800", value: "B737-800" },
    { label: "B737-900", value: "B737-900" },
    { label: "B737-900ER", value: "B737-900ER" },
    { label: "A320-200", value: "A320-200" },
    { label: "ATR72-500", value: "ATR72-500" },
    { label: "ATR72-600", value: "ATR72-600" },
]

const aircraftStatus = [
    { label: "New", value: "New" },
    { label: "Used", value: "Used" },
]

const noOfEngines = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
]

const engineManufacturer = [
    { label: "CFM International", value: "CFM International" },
    { label: "Pratt&Whitney Canada", value: "Pratt&Whitney Canada" }
]

const engineModels = [
    { label: "CFM56-7B24", value: "CFM56-7B24" },
    { label: "CFM56-7B26", value: "CFM56-7B26" },
    { label: "CFM56-7B27", value: "CFM56-7B27" },
    { label: "PWC127C", value: "PWC127C" },
]

const engineStatus = [
    { label: "New", value: "New" },
    { label: "Leased", value: "Leased" },
]

const apuManufacturer = [
    { label: "Honeywell International", value: "Honeywell International" }
]

const apuModels = [
    { label: "GTCP131-9B", value: "GTCP131-9B" },
]

const mainManufacturer = [
    { label: "BF Goodrich", value: "BF Goodrich" },
    { label: "Safran Landing Systems", value: "Safran Landing Systems" },
]

const mainModelsLeft = [
    { label: "161A1100-71", value: "161A1100-71" },
]

const mainModelsRight = [
    { label: "161A1100-72", value: "161A1100-72" },
]

const noseManufacturer = [
    { label: "BF Goodrich", value: "BF Goodrich" },
    { label: "Safran Landing Systems", value: "Safran Landing Systems" },
]

const noseModels = [
    { label: "162A1100-14", value: "162A1100-14" },
]


const ViewPanelGoneForm = (props) => {
    // const { history } = props;
    const { match: { params } } = props
    // const location = props.location;
    // const formId = location.state ? location.state.id : null;
    // const formData = location.state ? location.state.arg : null;

    const [form, setForm] = useState([]);
    const [remHours, setRemHours] = useState({})
    console.log("form", form)
    const mfrDate = form.mfr_date ? new Date(form.mfr_date) : null;
    const aircraftDate = form.aircraft_date ? new Date(form.aircraft_date) : null;
    const apuDate = form.apu_last_shop_visit ? new Date(form.apu_last_shop_visit) : null;
    const mainDate = form.main_last_shop_visit ? new Date(form.main_last_shop_visit) : null;
    const mainRDate = form.main_last_shop_visit_r ? new Date(form.main_last_shop_visit_r) : null;
    const noseDate = form.nose_last_shop_visit ? new Date(form.nose_last_shop_visit) : null;

    const [thresh, setThresh] = useState({})
    const [threshCount, setThreshCount] = useState({})
    const [engineRow, setEngineRow] = useState([])
    console.log("engineRow", engineRow)

    useEffect(() => {
        getGone()
            .then(resp => {
                const filterGone = resp.find(x => x.id === parseInt(params.id))
                if (typeof filterGone.engine_details === 'string') {
                    setEngineRow(JSON.parse(filterGone.engine_details))
                } else {
                    setEngineRow(filterGone.engine_details)
                }
                const aircraftName = [{ label: filterGone.aircraft_name, value: filterGone.aircraft_name }]
                filterGone.aircraft_name = aircraftName;
                const aircraftStatus = [{ label: filterGone.aircraft_status, value: filterGone.aircraft_status }]
                filterGone.aircraft_status = aircraftStatus;
                const apuManu = [{ label: filterGone.apu_manufacturer, value: filterGone.apu_manufacturer }]
                filterGone.apu_manufacturer = apuManu;
                const apuMod = [{ label: filterGone.apu_model, value: filterGone.apu_model }]
                filterGone.apu_model = apuMod;
                const mainManu = [{ label: filterGone.main_manufacturer, value: filterGone.main_manufacturer }]
                filterGone.main_manufacturer = mainManu;
                const mainManuR = [{ label: filterGone.main_manufacturer_r, value: filterGone.main_manufacturer_r }]
                filterGone.main_manufacturer_r = mainManuR;

                const mainMod = [{ label: filterGone.main_model, value: filterGone.main_model }]
                filterGone.main_model = mainMod;
                const mainModR = [{ label: filterGone.main_model_r, value: filterGone.main_model_r }]
                filterGone.main_model_r = mainModR;

                const noseManu = [{ label: filterGone.nose_manufacturer, value: filterGone.nose_manufacturer }]
                filterGone.nose_manufacturer = noseManu;
                const noseMod = [{ label: filterGone.nose_model, value: filterGone.nose_model }]
                filterGone.nose_model = noseMod;

                setForm(filterGone)
            })
    }, []);

    function handleThresholdChange(event) {
        const { name, value } = event.target;
        thresh[name] = value.length
        const remain_val = 40 - 35

        if (remain_val <= thresh[name]) {
            setThresh({ ...thresh, [name]: true })
        } else {
            setThresh({ ...thresh, [name]: false })
        }
        setThreshCount({ ...threshCount, [name]: event.target.value.length })
    }

    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    function handleRemHoursChange(e) {
        let str = e.target.value;
        if (str.length === 7) {
            var first = str.substring(0, str.indexOf("-"))
            var last = str.substring(str.indexOf("-") + 1)
            var minutes = parseInt(first) + parseInt(last)
            setRemHours({ ...remHours, [e.target.name]: minutes })
        }
    }

    function handleAddRowNested(value) {
        // const modifiedRows = [...engineRow]
        let modifiedRows = [];
        for (let i = 0; i < value; i++) {
            modifiedRows.push({
                eng_mfr: '', eng_part_no: '', conf_eng_part_no: '', eng_sl_no: '', conf_eng_sl_no: '',
                eng_last_shop_visit: '', eng_rem_hrs: '', eng_rem_cycle: ''
            })
        }
        setEngineRow(modifiedRows)
    }

    const handleEngineChange = (e, i) => {
        const tempValues = [...engineRow];
        tempValues[i] = { ...tempValues[i], [e.target.name]: e.target.value };
        setEngineRow(tempValues);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const id = parseInt(params.id)
        const resData = { form, engineRow }
        //const resData = { form, engineRow }
        // await getGone()
        // const res = await postGone(resData);
        editGone(resData, id)
            .then(resp => {
                console.log("respo", resp)
                props.history.push('/panelg1')
            })
            .catch(err => {
                console.log("Error", err);
            })
    }

    const handleCancel = () => {
        props.history.push('/panelg1')
    }

    const handleValidDate = date => {
        console.log("date", date)
        const date1 = moment(new Date(date)).format("DD MMM Y");
        return date1;
    };

    // const manDate = new Date(form?.mfr_date);
    // console.log("manDate", manDate)

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Panel G1 | Form Template</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="Configuration" breadcrumbItem="Aircraft Setup" />

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <AvForm onValidSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.aircraft_name}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, aircraft_name: value })
                                                                    }}
                                                                    options={aircraftTypes}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                                {/* <AvField type="text" disabled maxLength={40} name="aircraft_name" value={form.aircraft_name}
                                                                onChange={e => {
                                                                    handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                autoComplete="off"
                                                                errorMessage="Enter Registration ID"
                                                                validate={{ required: { value: true } }}
                                                                /> */}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                MSN
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="msn" value={form.msn}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MSN"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.msn ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.msn} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Manufactured Date
                                                            </label>
                                                            <div className="col-md-8">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={mfrDate}
                                                                    onChange={(date) => setForm({ ...form, mfr_date: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="aircraft_hours" value={form.aircraft_hours}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Aircraft Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                No of Engines
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" name="no_of_engines" value={form.no_of_engines} />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Received Status
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.aircraft_status}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, aircraft_status: value })
                                                                    }}
                                                                    options={aircraftStatus}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                National Registration ID
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="reg_id" value={form.reg_id}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter Registration ID"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.reg_id ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.reg_id} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Date Received
                                                            </label>
                                                            <div className="col-md-8">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={aircraftDate}
                                                                    onChange={(date) => setForm({ ...form, aircraft_date: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Aircraft Cycles
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="number" name="aircraft_cycles" value={form.aircraft_cycles}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                {form?.no_of_engines >= 1 ?
                                                    engineRow && engineRow.map((el, i) => {
                                                        const lastShopVisit = el.eng_last_shop_visit ?
                                                            new Date(el.eng_last_shop_visit) : null;
                                                        const engMfrDate = el.eng_mfr_date ?
                                                            new Date(el.eng_mfr_date) : null;
                                                        return (
                                                            <Row key={i} className="mb-3">
                                                                <h4 className="card-title mb-3 text-center">Engine {i + 1}</h4>
                                                                <Col lg={6}>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Engine Manufacturer
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <Select
                                                                                value={el.eng_mfr}
                                                                                onChange={(value) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_mfr: value };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                                options={engineManufacturer}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Part No
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" maxLength={40} name="eng_part_no" value={el.eng_part_no}
                                                                                onChange={(el) => {
                                                                                    handleEngineChange(el, i)
                                                                                }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                autoComplete="off"
                                                                            // errorMessage="Enter Part No"
                                                                            // validate={{ required: { value: true } }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Engine Status
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <Select
                                                                                value={el.eng_status}
                                                                                onChange={(value) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_status: value };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                                options={engineStatus}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Total Hours
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" maxLength={7} name="eng_rem_hrs" value={el.eng_rem_hrs}
                                                                                onChange={(el) => { handleEngineChange(el, i) }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                placeholder="HHHH-MM"
                                                                                errorMessage="Enter Total Hours"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Engine Model
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <Select
                                                                                value={el.eng_model}
                                                                                onChange={(value) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_model: value };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                                options={engineModels}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Serial No
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="text" maxLength={40} name="eng_sl_no" value={el.eng_sl_no}
                                                                                onChange={(el) => {
                                                                                    handleEngineChange(el, i)
                                                                                }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                autoComplete="off"
                                                                            // errorMessage="Enter Serial No"
                                                                            // validate={{ required: { value: true } }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Manufactured Date
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <DatePicker
                                                                                className="form-control"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                placeholderText="DD/MM/YYYY"
                                                                                selected={engMfrDate}
                                                                                onChange={(date) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_mfr_date: date };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Total Cycles
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <AvField type="number" name="eng_rem_cycle" value={el.eng_rem_cycle}
                                                                                onChange={(el) => {
                                                                                    handleEngineChange(el, i)
                                                                                }}
                                                                                onPaste={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                                onCopy={(e) => {
                                                                                    e.preventDefault()
                                                                                    return false;
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                </Col>
                                                                {el?.eng_status?.value === 'New' ?
                                                                    null :
                                                                    <Row className="mb-3">
                                                                        <label htmlFor="name" className="col-md-4 col-form-label">
                                                                            Last Shop Visit
                                                                        </label>
                                                                        <div className="col-md-8">
                                                                            <DatePicker
                                                                                className="form-control"
                                                                                dateFormat="dd/MM/yyyy"
                                                                                placeholderText="DD/MM/YYYY"
                                                                                selected={lastShopVisit}
                                                                                onChange={(date) => {
                                                                                    const tempValues = [...engineRow];
                                                                                    tempValues[i] = { ...tempValues[i], eng_last_shop_visit: date };
                                                                                    setEngineRow(tempValues);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Row>
                                                                }
                                                            </Row>
                                                        );
                                                    })
                                                    : null
                                                }
                                                {/* APU Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">APU Details</h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Manufacturer
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.apu_manufacturer}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, apu_manufacturer: value })
                                                                    }}
                                                                    options={apuManufacturer}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="apu_product_no" value={form.apu_product_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter APU Part Number"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.apu_product_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.apu_product_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Last Shop Visit
                                                            </label>
                                                            <div className="col-md-8">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={apuDate}
                                                                    onChange={(date) => setForm({ ...form, apu_last_shop_visit: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>

                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.apu_model}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, apu_model: value })
                                                                    }}
                                                                    options={apuModels}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Serial No
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="apu_sl_no" value={form.apu_sl_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter APU Sl No"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.apu_sl_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.apu_sl_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                APU Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="apu_remaining_hours" value={form.apu_remaining_hours}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            APU Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" name="apu_remaining_cycles" value={form.apu_remaining_cycles}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* Main Landing Gear Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">
                                                        Main Landing Gear Details(Left)
                                                    </h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Manufacturer
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.main_manufacturer}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_manufacturer: value })
                                                                    }}
                                                                    options={mainManufacturer}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Serial No
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="main_sl_no" value={form.main_sl_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MainLG Serial No"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.main_sl_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.main_sl_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Last Shop Visit
                                                            </label>
                                                            <div className="col-md-8">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={mainDate}
                                                                    onChange={(date) => setForm({ ...form, main_last_shop_visit: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                {/* <AvField type="text" disabled name="main_model" value={form.main_model} /> */}
                                                                <Select
                                                                    value={form.main_model}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_model: value })
                                                                    }}
                                                                    options={mainModelsLeft}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="main_product_no" value={form.main_product_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MainLG Part Number"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.main_product_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.main_product_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="main_remaining_hours" value={form.main_remaining_hours}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-4 col-form-label"
                                                        >
                                                            Total Cycles
                                                        </label>
                                                        <div className="col-md-8">
                                                            <AvField type="number" name="main_remaining_cycles" value={form.main_remaining_cycles}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* Main Landing Gear Right Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">
                                                        Main Landing Gear Details(Right)
                                                    </h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Manufacturer
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.main_manufacturer_r}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_manufacturer_r: value })
                                                                    }}
                                                                    options={mainManufacturer}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>

                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Serial No
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="main_sl_no_r" value={form.main_sl_no_r}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MainLG Serial No"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.main_sl_no_r ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.main_sl_no_r} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Last Shop Visit
                                                            </label>
                                                            <div className="col-md-8">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={mainRDate}
                                                                    onChange={(date) => setForm({ ...form, main_last_shop_visit_r: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.main_model_r}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, main_model_r: value })
                                                                    }}
                                                                    options={mainModelsLeft}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="main_product_no_r" value={form.main_product_no_r}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter MainLG Part Number"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.main_product_no_r ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.main_product_no_r} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="main_remaining_hours_r" value={form.main_remaining_hours_r}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-4 col-form-label"
                                                        >
                                                            Total Cycles
                                                        </label>
                                                        <div className="col-md-8">
                                                            <AvField type="number" name="main_remaining_cycles_r" value={form.main_remaining_cycles_r}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                {/* Nose Details */}
                                                <Row className="mb-3">
                                                    <h4 className="card-title mb-3 text-center">
                                                        Nose Landing Gear Details
                                                    </h4>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Manufacturer
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.nose_manufacturer}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, nose_manufacturer: value })
                                                                    }}
                                                                    options={noseManufacturer}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Serial No
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="nose_sl_no" value={form.nose_sl_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter NoseLG Serial No"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.nose_sl_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.nose_sl_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Last Shop Visit
                                                            </label>
                                                            <div className="col-md-8">
                                                                <DatePicker
                                                                    className="form-control"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    placeholderText="DD/MM/YYYY"
                                                                    selected={noseDate}
                                                                    onChange={(date) => setForm({ ...form, nose_last_shop_visit: date })}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col lg="6">
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Model
                                                            </label>
                                                            <div className="col-md-8">
                                                                <Select
                                                                    value={form.nose_model}
                                                                    onChange={(value) => {
                                                                        setForm({ ...form, nose_model: value })
                                                                    }}
                                                                    options={noseModels}
                                                                    classNamePrefix="select2-selection"
                                                                />
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Part Number
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={40} name="nose_product_no" value={form.nose_product_no}
                                                                    onChange={e => {
                                                                        handleThresholdChange(e)
                                                                        handleChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    autoComplete="off"
                                                                    errorMessage="Enter NoseLG Part Number"
                                                                    validate={{ required: { value: true } }}
                                                                />
                                                                {thresh.nose_product_no ? (
                                                                    <span className="badgecount badge bg-success">
                                                                        {threshCount.nose_product_no} / 40{" "}
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </Row>
                                                        <Row className="mb-3">
                                                            <label
                                                                htmlFor="example-text-input"
                                                                className="col-md-4 col-form-label"
                                                            >
                                                                Total Hours
                                                            </label>
                                                            <div className="col-md-8">
                                                                <AvField type="text" maxLength={7} name="nose_remaining_hours" value={form.nose_remaining_hours}
                                                                    onChange={e => {
                                                                        handleChange(e)
                                                                        handleRemHoursChange(e)
                                                                    }}
                                                                    onPaste={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    onCopy={(e) => {
                                                                        e.preventDefault()
                                                                        return false;
                                                                    }}
                                                                    placeholder="HHHH-MM"
                                                                    errorMessage="Enter Total Hours"
                                                                    validate={{
                                                                        required: { value: true },
                                                                        pattern: {
                                                                            value: "^([0-9]?[0-9][0-9][0-9][0-9])-[0-5][0-9]$",
                                                                            errorMessage: "Invalid Format",
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Row className="mb-3">
                                                        <label
                                                            htmlFor="example-text-input"
                                                            className="col-md-3 col-form-label"
                                                        >
                                                            Total Cycles
                                                        </label>
                                                        <div className="col-md-9">
                                                            <AvField type="number" name="nose_remaining_cycles" value={form.nose_remaining_cycles}
                                                                onChange={e => {
                                                                    //handleThresholdChange(e)
                                                                    handleChange(e)
                                                                }}
                                                                onPaste={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                                onCopy={(e) => {
                                                                    e.preventDefault()
                                                                    return false;
                                                                }}
                                                            // errorMessage="Enter Registration ID"
                                                            // validate={{ required: { value: true } }}
                                                            />
                                                        </div>
                                                    </Row>
                                                </Row>

                                                <Row>
                                                    <Col className="d-flex flex-wrap gap-2 justify-content-center">
                                                        <Button className=" btn btn-success save-user w-md"
                                                            type="submit"
                                                        >
                                                            Update
                                                        </Button>
                                                        <Button type="reset" color="secondary" onClick={handleCancel}
                                                            className=" btn btn-secondary save-user w-md">
                                                            Cancel
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

ViewPanelGoneForm.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(ViewPanelGoneForm)