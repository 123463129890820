import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import Image
import logo from "../../../assets/images/logo-dark.png";
import { getInvoiceDetail as onGetInvoiceDetail } from "../../../store/invoices/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import { getGone, getGthree, getForecast, getJourney, getScheduler } from "actions/api";
import * as moment from "moment";

const InvoiceDetail = props => {
    const dispatch = useDispatch();
    const { invoiceDetail } = useSelector(state => ({
        invoiceDetail: state.invoices.invoiceDetail,
    }));

    const {
        match: { params },
    } = props;
    const selectedIds = params.id.split(",");
    const [gthree, setGthree] = useState([]);
    console.log("gthree", gthree)
    const [gone, setGone] = useState([]);
    const [schedule_date, setSchedule] = useState(null);
    const [aircraftDetails, setAircraftDetails] = useState({});

    useEffect(() => {
        if (params && params.id) {
            dispatch(onGetInvoiceDetail(params.id));
        } else {
            dispatch(onGetInvoiceDetail(1)); //remove this after full integration
        }
    }, [params, onGetInvoiceDetail]);

    useEffect(() => {
        getGthree()
            .then(resp2 => {
                const filterGthree = resp2.filter(x => selectedIds.map(x => parseInt(x)).includes(x.id))
                let gthreeArr = [];
                filterGthree.forEach((n, i) => {
                    const serviceAssigned = (typeof n.service_assigned === 'string') ?
                        JSON.parse(n.service_assigned) : n.service_assigned;
                    gthreeArr.push({
                        ...n,
                        service_assigned: serviceAssigned
                    })
                })
                let gOne = filterGthree[0];
                const aircraftModel = gOne && gOne.aircraft_model;
                let journeyLogs;
                getJourney()
                    .then(resp4 => {
                        journeyLogs = resp4;
                    })
                getScheduler()
                    .then(resp5 => {
                        const filterScheduler = resp5.filter(x => x.aircraft_type === aircraftModel)
                        getForecast()
                            .then(resp3 => {
                                // const filterForecast = resp3.filter(x => x.status === "true" &&
                                //     filterGthree.map(s => s.id).includes(x.gthree_id));
                                // setSchedule(filterForecast && filterForecast[0].next_date);
                                const filterForecast = resp3.filter(x => x.status === "true" &&
                                    filterGthree.map(s => s.id).includes(x.gthree_id));
                                let hsum = 0;
                                let csum = 0;
                                let schedulearr = [];
                                const serviceHours = filterForecast && filterForecast[0].repeat_format === 'Hours' ? ((filterForecast && filterForecast[0].interval_repeat) * 60) : (filterForecast && filterForecast[0].interval_repeat);
                                const repeatFormat = filterForecast && filterForecast[0].repeat_format;
                                const lastDateFilter = filterScheduler.filter(x => new Date(x.aircraft_date).setHours(0, 0, 0, 0) > new Date(filterForecast && filterForecast[0].last_date).setHours(0, 0, 0, 0))
                                console.log("lastDateFilter", lastDateFilter)
                                lastDateFilter.forEach((p, i) => {
                                    const foundJourney = journeyLogs.find(x =>
                                        new Date(x.date).setHours(0, 0, 0, 0) === new Date(p.aircraft_date).setHours(0, 0, 0, 0) &&
                                        x.aircraft_type === p.aircraft_type);
                                    // console.log("foundJourney", foundJourney)
                                    const actualHours = foundJourney && foundJourney.total_flight_hrs;
                                    const actualJrnHrs = (actualHours)?.split('-');
                                    const act = actualJrnHrs?.map(x => parseInt(x))
                                    const act_hrs = act && act[0] ? act[0] * 60 : parseInt(actualHours) * 60
                                    const act_min = act && act[1] ? act[1] : 0
                                    const total_act = parseInt(act_hrs ? act_hrs : 0) + parseInt(act_min ? act_min : 0)

                                    const scheduleJrnHrs = (p.aircraft_hours)?.split('-');
                                    const sch = scheduleJrnHrs?.map(x => parseInt(x))
                                    const sch_hrs = sch && sch[0] ? sch[0] * 60 : parseInt(p.aircraft_hours) * 60
                                    const sch_min = sch && sch[1] ? sch[1] : 0
                                    const total_sch = parseInt(sch_hrs ? sch_hrs : 0) + parseInt(sch_min ? sch_min : 0);
                                    const actualCycles = foundJourney && foundJourney.total_flight_cyc;
                                    const hoursConsider = actualHours ? total_act : total_sch
                                    const cyclesConsider = actualCycles ? parseInt(actualCycles) : parseInt(p.aircraft_cycles);

                                    hsum = hsum + parseInt(hoursConsider);
                                    csum = csum + parseInt(cyclesConsider);

                                    if (repeatFormat === 'Hours') {
                                        schedulearr.push({
                                            ...p,
                                            actual_hrs: total_act,
                                            hours_consider: hoursConsider,
                                            cumulative: hsum,
                                            status: hsum > serviceHours ? true : false
                                        })
                                    } else {
                                        // console.log("Else part working")
                                        schedulearr.push({
                                            ...p,
                                            actual_cyc: actualCycles,
                                            cycles_consider: cyclesConsider,
                                            cumulative: csum,
                                            status: csum > serviceHours ? true : false
                                        })
                                    }
                                })
                                const falseArr = schedulearr?.filter(x => x.status === false)
                                const trueArr = schedulearr?.filter(x => x.status === true)

                                const aircraftDate = falseArr?.length > 0 ?
                                    new Date(falseArr[falseArr?.length - 1].aircraft_date) : null

                                const nextDue = trueArr?.length > 0 ? aircraftDate : null;


                                const nextDueDate = !filterForecast ? null :
                                    (nextDue ? nextDue : new Date(filterForecast && filterForecast[0].next_date))
                                setSchedule(nextDueDate);
                            })
                    })
                // setGthree(filterGthree)
                setGthree(gthreeArr)
                getGone()
                    .then(resp => {
                        const foundGone = resp.find(x => x.aircraft_name === aircraftModel)
                        setGone(foundGone)
                        let total_ac;
                        let total_journey;
                        let journeyLogs;
                        getJourney()
                            .then(resp4 => {
                                journeyLogs = resp4;
                                const aircraftCycles = (foundGone?.aircraft_cycles)
                                const aircraftHrs = (foundGone?.aircraft_hours).split('-');
                                const abc = aircraftHrs.map(x => parseInt(x))
                                const ac_hrs = abc[0] * 60;
                                const ac_min = abc[1];
                                total_ac = ac_hrs + ac_min
                                const aircraftName = foundGone?.aircraft_name;
                                const aircraftReg = foundGone?.reg_id;
                                const foundJourney = journeyLogs?.filter(x => x.aircraft_type === aircraftName)
                                let journeyarr = [];
                                foundJourney.forEach((item, i) => {
                                    const flightHours = (item.total_flight_hrs)?.split('-');
                                    const abc = flightHours?.map(x => parseInt(x))
                                    const fc_hrs = abc && abc[0] ? abc[0] * 60 : item.total_flight_hrs * 60
                                    const fc_min = abc && abc[1] ? abc[1] : 0
                                    journeyarr.push({
                                        ...item,
                                        journey_hr: fc_hrs,
                                        journey_min: fc_min,
                                    })
                                })
                                const journeyHour = journeyarr.reduce((a, b) => a + parseInt(b.journey_hr), 0);
                                const journeyMin = journeyarr.reduce((a, b) => a + parseInt(b.journey_min), 0);
                                const journeyCycles = journeyarr.reduce((a, b) => a + parseInt(b.total_flight_cyc), 0);
                                total_journey = parseInt(journeyHour) + parseInt(journeyMin)

                                const totalFH = Math.floor((total_ac + total_journey) / 60);
                                const totalMin = Math.floor((total_ac + total_journey) % 60);
                                const totalFlightHours = totalFH + '-' + totalMin;
                                const addTotalFH = totalFlightHours ? totalFlightHours : filterGone?.aircraft_hours //total FH
                                const totalFlightCycles = parseInt(aircraftCycles) + journeyCycles;
                                setAircraftDetails({
                                    add_total_fh: addTotalFH, add_total_fc: totalFlightCycles
                                })
                            })
                    })
                //console.log("filterGthree", filterGthree)
            })
    }, [])

    // useEffect(() => {
    //     getGone()
    //         .then(resp => {
    //             const foundGone = resp.find(x => x.id === parseInt(params.id))
    //             const aircraftModel = foundGone && foundGone.aircraft_name;
    //             const reg_id = foundGone && foundGone.reg_id;
    //             const msn = foundGone && foundGone.msn;
    //             //setAircraftModel(aircraftModel)
    //             setAircraftDetails({ aircraftModel: aircraftModel, reg_id: reg_id, msn: msn })
    //             //console.log("aircraftModel", aircraftModel)
    //             getGthree()
    //                 .then(resp2 => {
    //                     const filterGthree = resp2.filter(x => selectedIds.map(x => parseInt(x)).includes(x.id))
    //                     console.log("filterGthree", filterGthree)
    //                     // getForecast()
    //                     //     .then(resp3 => {
    //                     //         let forecastResp = resp3;
    //                     //         let arr = [];
    //                     //         filterGthree.map(n => {
    //                     //             const findForecast = (forecastResp || []).find(s => s.gthree_id === n.id)
    //                     //             arr.push({
    //                     //                 ...n,
    //                     //                 last_date: findForecast ? findForecast.last_date : null,
    //                     //                 last_hours: findForecast ? findForecast.last_hours : null,
    //                     //                 last_cycles: findForecast ? findForecast.last_cycles : null,
    //                     //                 next_date: findForecast ? findForecast.next_date : null,
    //                     //                 next_hours: findForecast ? findForecast.next_hours : null,
    //                     //                 next_cycles: findForecast ? findForecast.next_cycles : null,
    //                     //                 remaining_date: findForecast ? findForecast.remaining_date : null,
    //                     //                 remaining_hours: findForecast ? findForecast.remaining_hours : null,
    //                     //                 remaining_cycles: findForecast ? findForecast.remaining_cycles : null,
    //                     //                 avg_hours: findForecast ? findForecast.avg_hours : null,
    //                     //             })
    //                     //         })
    //                     //         // setGthree(arr)
    //                     //     })
    //                 })
    //         })
    // }, [])

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    const wordWrap = (word) => {
        var a = word.match(/.{1,50}/g);
        var b = '';
        if (a != null) {
            for (var i = 0; i < a.length; i++) {
                if (a[i].toString() != null) {
                    b = b + '<p>' + a[i].toString() + '</p>';
                }
            }
            word.html(b);
        }
        return b
    }

    var today = new Date();
    var dd =
        today.getDate() < 10
            ? '0' + today.getDate()
            : today.getDate().toString();
    var mm =
        today.getMonth() + 1 < 10
            ? '0' + parseInt(today.getMonth() + 1)
            : parseInt(today.getMonth() + 1).toString();
    var yyyy = today.getFullYear();
    var hrs =
        today.getHours() < 10
            ? '0' + today.getHours()
            : today.getHours().toString();
    var mins =
        today.getMinutes() < 10
            ? '0' + today.getMinutes()
            : today.getMinutes().toString();
    var secs =
        today.getSeconds() < 10
            ? '0' + today.getSeconds()
            : today.getSeconds().toString();

    today = yyyy + mm + dd + hrs + mins + secs;

    const handleValidDate = date => {
        console.log("date", date)
        const date1 = moment(new Date(date)).format("DD MMM Y");
        // const date1 = moment(new Date(date)).format("DD-MM-YYYY");
        return date1;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>
                        Forecast | Task Card
                    </title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Task Card" breadcrumbItem="Forecast" /> */}
                    {!isEmpty(gthree) && (
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <div className="d-print-none">
                                            <div className="float-end">
                                                <Link
                                                    to="#"
                                                    onClick={printInvoice}
                                                    className="btn btn-success me-2"
                                                >
                                                    <i className="fa fa-print" />
                                                </Link>
                                            </div>
                                        </div>
                                        {/* <div className="invoice-title"> */}
                                        <div>
                                            <h4 className="text-center font-size-16">
                                                TASK CARD
                                            </h4>
                                        </div>
                                        <hr />
                                        <Row>
                                            <Col xs="5">
                                                <div>
                                                    <strong>A/C TYPE: </strong>
                                                    <span>{gone && gone.aircraft_name}</span>
                                                </div>
                                                <div>
                                                    <strong>A/C REGN: </strong>
                                                    <span>{gone && gone.reg_id}</span>
                                                </div>
                                                <div>
                                                    <strong>A/C MSN: </strong>
                                                    <span>{gone && gone.msn}</span>
                                                </div>
                                            </Col>
                                            <Col xs="3">
                                                <div>
                                                    <strong>A/C FH: </strong>
                                                    <span>{aircraftDetails.add_total_fh}</span>
                                                </div>
                                                <div>
                                                    <strong>A/C FC: </strong>
                                                    <span>{aircraftDetails.add_total_fc}</span>
                                                </div>
                                                <div>
                                                    <strong>DATE: </strong>
                                                    <span>{handleValidDate(new Date())}</span>
                                                </div>
                                            </Col>
                                            <Col xs="4" className="text-end">
                                                <div>
                                                    <strong>WORK ORDER NO: </strong>
                                                    <span>{today}</span>
                                                </div>
                                                <div>
                                                    <strong>CHECK TYPE: </strong>
                                                    <span>{"Scheduled Maintenance"}</span>
                                                </div>
                                                <div>
                                                    <strong>SCHEDULE DATE: </strong>
                                                    <span>{handleValidDate(schedule_date)}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        {/* <div className="py-2 mt-3">
                                            <h3 className="font-size-15 fw-bold">Order summary</h3>
                                        </div> */}
                                        <div className="table-responsive">
                                            <Table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "70px" }}>NO.</th>
                                                        <th>TASKCARD_REF</th>
                                                        {/* <th className="text-end">MPD</th> */}
                                                        <th>MPD_TASK</th>
                                                        <th>DESCRIPTION</th>
                                                        <th>ZONE</th>
                                                        <th>COMP REQ</th>
                                                        <th>INTERVAL</th>
                                                        <th colSpan={2}>MANHOURS</th>
                                                        <th>COMPLETED_DATE</th>
                                                        <th>SIGN</th>
                                                    </tr>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>EST</th>
                                                        <th>ACTUAL</th>
                                                        <th style={{ textAlign: 'center' }}>DD/MM/YY</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {map(
                                                        gthree,
                                                        (item, key) => (
                                                            <tr key={key}>
                                                                <td>{key + 1}</td>
                                                                <td>{item.task_ref}</td>
                                                                <td>{item.mpd}</td>
                                                                <td>
                                                                    <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '25em', overflow: 'hidden', textOverflow: 'ellipse', height: '10em' }}>
                                                                        {item.service_desc}
                                                                    </div>
                                                                </td>
                                                                <td>{item?.zone}</td>
                                                                <td>{item?.service_assigned[0].value}</td>
                                                                <td>{item.interval_thresh}</td>
                                                                <td>{item.est_man_hours}</td>

                                                                <td style={{ width: '8%' }}></td>
                                                                <td style={{ width: '18%' }}>
                                                                    {/* //         <Table>
                                                            //             <tbody>
                                                            //                 <tr>
                                                            //                     <th colSpan={2} style={{ border: '0px', width: '30%' }}></th>
                                                            //                     <th style={{ borderStyle: '1px solid', borderWidth: 'thin', width: '2%' }}>/</th>
                                                            //                     <th colSpan={2} style={{ border: '0px', width: '33%' }}></th>
                                                            //                     <th style={{ borderStyle: '1px solid', borderWidth: 'thin', width: '2%' }}>/</th>
                                                            //                     <th colSpan={3} style={{ border: '0px', width: '33%' }}></th>
                                                            //                 </tr>
                                                            //             </tbody>
                                                            //         </Table> */}
                                                                    <Table>
                                                                        <tbody>
                                                                            <tr>
                                                                                <div style={{ border: '1px solid' }}>
                                                                                    <th colSpan={2} style={{ border: '0px', width: '30%' }}></th>
                                                                                    <th style={{ borderStyle: '1px solid', borderWidth: 'thin', width: '2%' }}>/</th>
                                                                                    <th colSpan={2} style={{ border: '0px', width: '33%' }}></th>
                                                                                    <th style={{ borderStyle: '1px solid', borderWidth: 'thin', width: '2%' }}>/</th>
                                                                                    <th colSpan={3} style={{ border: '0px', width: '33%' }}></th>
                                                                                </div>
                                                                            </tr>
                                                                        </tbody>
                                                                    </Table>
                                                                </td>
                                                                <td style={{ width: '8%' }}></td>
                                                            </tr>
                                                            // <tr key={key}>
                                                            //     <td>{key + 1}</td>
                                                            //     <td>{"26-100-00-01"}</td>
                                                            //     <td></td>
                                                            //     <td>
                                                            //         <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '25em', overflow: 'hidden', textOverflow: 'ellipse', height: '12em' }}>

                                                            //         </div>
                                                            //     </td>
                                                            //     <td></td>
                                                            //     <td></td>
                                                            //     <td></td>
                                                            //     <td></td>
                                                            //     <td></td>
                                                            //     <td>
                                                            //         <Table>
                                                            //             <tbody>
                                                            //                 <tr>
                                                            //                     <div style={{ border: '1px solid' }}>
                                                            //                         <th colSpan={2} style={{ border: '0px', width: '30%' }}></th>
                                                            //                         <th style={{ borderStyle: '1px solid', borderWidth: 'thin', width: '2%' }}>/</th>
                                                            //                         <th colSpan={2} style={{ border: '0px', width: '33%' }}></th>
                                                            //                         <th style={{ borderStyle: '1px solid', borderWidth: 'thin', width: '2%' }}>/</th>
                                                            //                         <th colSpan={3} style={{ border: '0px', width: '33%' }}></th>
                                                            //                     </div>
                                                            //                 </tr>
                                                            //             </tbody>
                                                            //         </Table>
                                                            //     </td>
                                                            //     <td style={{ width: '8%' }}></td>
                                                            // </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                        {/* <div className="d-print-none">
                                            <div className="float-end">
                                                <Link
                                                    to="#"
                                                    onClick={printInvoice}
                                                    className="btn btn-success  me-2"
                                                >
                                                    <i className="fa fa-print" />
                                                </Link>
                                                <Link to="#" className="btn btn-primary w-md ">
                                                    Send
                                                </Link> 
                                            </div>
                                        </div> */}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

InvoiceDetail.propTypes = {
    match: PropTypes.any,
};

export default withRouter(InvoiceDetail);
