import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
    getUsers as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty, size, map } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getGone, getJourney } from "actions/api";

const MainDashboard = props => {
    const dispatch = useDispatch();

    const { users } = useSelector(state => ({
        users: state.contacts.users,
    }));

    const { history } = props

    const [gone, setGone] = useState([]);

    const [userList, setUserList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: sizePerPage,
        totalSize: gone.length, // replace later with size(users),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const selectRow = {
        mode: "checkbox",
    };

    const contactListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "id",
            text: "#",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    {!user.img ? (
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle">
                                {/* {user.name.charAt(0)} */}
                                {user.id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle avatar-xs"
                                src={images[user.img]}
                                alt=""
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            text: "Aircraft Model",
            dataField: "aircraft_name",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    <h5 className="font-size-14 mb-1">
                        <Link to="#" className="text-dark">
                            {user.aircraft_name}
                        </Link>
                    </h5>
                    {/* <p className="text-muted mb-0">{user.designation}</p> */}
                </>
            ),
        },
        {
            dataField: "msn",
            text: "MSN",
            sort: true,
        },
        {
            dataField: "reg_id",
            text: "National Reg ID",
            sort: true,
        },
        {
            dataField: "no_of_engines",
            text: "No of Engines",
            sort: true,
        },
        {
            dataField: "add_total_fh",
            text: "Flight Hours",
            sort: true,
        },
        {
            dataField: "add_total_fc",
            text: "Flight Cycles",
            sort: true,
        },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <div className="d-flex gap-3">
                    <Link className="text-success" to="#">
                        <i
                            className="mdi mdi-eye font-size-18"
                            id="eyetooltip"
                            onClick={() => handleUserClick(user)}
                        ></i>
                    </Link>
                    {/* <div className="dropdown d-inline-block"> */}
                    {/* <button
                   onClick={this.toggleRightDrawer} disabled={this.state.open}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                > */}
                    <Link className="dropdown d-inline-block" to="#">
                        <i className="mdi mdi-cog-outline font-size-18" onClick={() => handleProcessMtwo(user)} />
                    </Link>
                    {/* </button> */}
                    {/* </div> */}
                    {/* <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="edittooltip"
              onClick={() => handleDeleteUser(user)}
            ></i>
          </Link> */}
                </div>
            ),
        },
    ];

    // useEffect(() => {
    //     if (users && !users.length) {
    //         dispatch(onGetUsers());
    //         setIsEdit(false);
    //     }
    // }, [dispatch, users]);

    // useEffect(() => {
    //     setUserList(users);
    //     setIsEdit(false);
    // }, [users]);

    // useEffect(() => {
    //     if (!isEmpty(users) && !!isEdit) {
    //         setUserList(users);
    //         setIsEdit(false);
    //     }
    // }, [users]);

    useEffect(() => {
        getGone()
            .then(resp => {
                const filterResp = resp.filter(x => x.status === 'Approved')
                getJourney()
                    .then(resp2 => {
                        const journeyLogs = resp2;
                        let arr = [];
                        filterResp.forEach((n, i) => {
                            const aircraftCycles = (n.aircraft_cycles)
                            const aircraftHrs = (n.aircraft_hours).split('-');
                            const abc = aircraftHrs.map(x => parseInt(x))
                            const ac_hrs = abc[0] * 60;
                            const ac_min = abc[1];
                            const total_ac = ac_hrs + ac_min
                            const aircraftName = n.aircraft_name;
                            const aircraftReg = n.reg_id;
                            // const foundJourney = journeyLogs?.filter(x => x.aircraft_type === aircraftName)
                            // const foundJourney = journeyLogs?.filter(x => x.registration === aircraftReg)
                            // const journeyHour = foundJourney.reduce((a, b) => a + parseInt(b.current_fh_hr), 0) * 60;
                            // const journeyHour = foundJourney.reduce((a, b) => a + parseInt(b.total_flight_hrs), 0) * 60;
                            // const journeyMin = foundJourney.reduce((a, b) => a + parseInt(b.current_fh_min), 0);
                            // const journeyCycles = foundJourney.reduce((a, b) => a + parseInt(b.total_flight_cyc), 0);
                            // const total_journey = parseInt(journeyHour) + parseInt(journeyMin)
                            const foundJourney = journeyLogs?.filter(x => x.registration === aircraftReg)
                            let journeyarr = [];
                            foundJourney.forEach((item, i) => {
                                const flightHours = (item.total_flight_hrs)?.split('-');
                                const abc = flightHours?.map(x => parseInt(x))
                                const fc_hrs = abc && abc[0] ? abc[0] * 60 : item.total_flight_hrs
                                const fc_min = abc && abc[1] ? abc[1] : 0
                                journeyarr.push({
                                    ...item,
                                    journey_hr: fc_hrs,
                                    journey_min: fc_min,
                                })
                            })
                            const journeyHour = journeyarr.reduce((a, b) => a + parseInt(b.journey_hr), 0);
                            const journeyMin = journeyarr.reduce((a, b) => a + parseInt(b.journey_min), 0);
                            const journeyCycles = journeyarr.reduce((a, b) => a + parseInt(b.total_flight_cyc), 0);
                            const total_journey = parseInt(journeyHour) + parseInt(journeyMin)

                            const totalFH = Math.floor((total_ac + total_journey) / 60);
                            const totalMin = Math.floor((total_ac + total_journey) % 60);
                            const totalFlightHours = totalFH + '-' + totalMin;
                            const totalFlightCycles = parseInt(aircraftCycles) + journeyCycles;
                            arr.push({
                                ...n,
                                add_total_fh: totalFlightHours ? totalFlightHours : n.aircraft_hours,
                                add_total_fc: totalFlightCycles ? totalFlightCycles : n.aircraft_cycles,
                            })
                        })
                        setGone(arr)
                    })
            })
    }, []);


    const toggle = () => {
        setModal(!modal);
    };

    // const handleUserClick = arg => {
    //     const user = arg;

    //     setUserList({
    //         id: user.id,
    //         name: user.name,
    //         designation: user.designation,
    //         email: user.email,
    //         tags: user.tags,
    //         projects: user.projects,
    //     });
    //     setIsEdit(true);

    //     toggle();
    // };
    const handleUserClick = arg => {
        const id = arg.id;
        history.push(`/mviewgone/${id}`)
    }

    const handleProcessMtwo = arg => {
        const id = arg.id;
        history.push(`/mtwo/${id}`)
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const handleDeleteUser = user => {
        if (user.id !== undefined) {
            dispatch(onDeleteUser(user));
            onPaginationPageChange(1);
        }
    };

    /**
     * Handling submit user on user form
     */
    const handleValidUserSubmit = (e, values) => {
        if (isEdit) {
            const updateUser = {
                id: userList.id,
                name: values.name,
                designation: values.designation,
                tags: values.tags,
                email: values.email,
                projects: values.projects,
            };

            // update user
            dispatch(onUpdateUser(updateUser));
            setIsEdit(false);
        } else {
            const newUser = {
                id: Math.floor(Math.random() * (30 - 20)) + 20,
                name: values["name"],
                designation: values["designation"],
                email: values["email"],
                tags: values["tags"],
                projects: values["projects"],
            };
            // save new user
            dispatch(onAddNewUser(newUser));
        }
        toggle();
    };

    const handleNewPanel = () => {
        history.push('/panelg3_form')
    }

    const handleUserClicks = () => {
        setUserList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs breadcrumbItem="Dashboard" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField={keyField}
                                        columns={contactListColumns}
                                        data={gone}
                                    >
                                        {({ paginationProps, paginationTableProps }) => {
                                            return (
                                                <ToolkitProvider
                                                    keyField={keyField}
                                                    data={gone}
                                                    columns={contactListColumns}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col sm="4">
                                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="8">
                                                                    <div className="text-sm-end">
                                                                        {/* <Button
                                                                            color="primary"
                                                                            className="font-16 btn-block btn btn-primary"
                                                                            onClick={handleNewPanel}

                                                                          onClick={() =>
                                                                            history.push(
                                                                              `/ecommerce-product-detail/${product.id}`
                                                                            )
                                                                          }
                                                                          onClick={handleUserClicks}
                                                                        >
                                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                                            Create New User
                                                                        </Button> */}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={keyField}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            selectRow={selectRow}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap table-hover"
                                                                            }
                                                                            bordered={false}
                                                                            striped={false}
                                                                            responsive
                                                                            ref={node}
                                                                        />

                                                                        <Modal isOpen={modal} toggle={toggle}>
                                                                            <ModalHeader toggle={toggle} tag="h4">
                                                                                {!!isEdit ? "Edit User" : "Add User"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <AvForm
                                                                                    onValidSubmit={handleValidUserSubmit}
                                                                                >
                                                                                    <Row form>
                                                                                        <Col xs={12}>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="aircraft_model"
                                                                                                    label="Aircraft model"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid service class"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.service_class || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_class"
                                                                                                    label="Service class"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Designation"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={
                                                                                                        userList.designation || ""
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_name"
                                                                                                    label="service name"
                                                                                                    type="select"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_name"
                                                                                                    label="service name (confirm)"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    type="select"
                                                                                                    name="select"
                                                                                                    className="form-select"
                                                                                                    label="service1"
                                                                                                    helpMessage="MULTIPLE!"
                                                                                                    multiple={true}
                                                                                                    required
                                                                                                    value={userList.tags || ""}
                                                                                                >
                                                                                                    <option></option>
                                                                                                    <option>service1</option>
                                                                                                    <option>service2</option>
                                                                                                    <option>service3</option>

                                                                                                </AvField>
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_desc"
                                                                                                    label="decription"

                                                                                                    type="textarea"
                                                                                                    id="textarea"

                                                                                                    maxLength="225"
                                                                                                    rows="3"
                                                                                                    placeholder="This textarea has a limit of 225 chars."



                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-end">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                >
                                                                                                    Save
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AvForm>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            );
                                        }}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
MainDashboard.propTypes = {

    history: PropTypes.object,

}

export default withRouter(MainDashboard);

