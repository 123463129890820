import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { getGthree, deleteGthree, getGtwo } from "actions/api";
import images from "assets/images";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
    getUsers as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty, size, map } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const PanelGthree = props => {
    const { history } = props;
    const dispatch = useDispatch();
    const [gthree, setGthree] = useState([])
    console.log("gthree", gthree)
    const { users } = useSelector(state => ({
        users: state.contacts.users,
    }));


    const [userList, setUserList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: sizePerPage,
        totalSize: gthree.length, // replace later with size(users),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const selectRow = {
        mode: "checkbox",
    };

    const contactListColumns = [
        {
            text: "id",
            // dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "id",
            text: "#",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    {!user.img ? (
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle">
                                {/* {user.name.charAt(0)} */}
                                {user.id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle avatar-xs"
                                src={images[user.img]}
                                alt=""
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            dataField: "aircraft_model",
            text: "Aircraft Model",
            sort: true,
        },
        // {
        //     dataField: "S.No",
        //     text: "S.No",
        //     sort: true,
        //   },
        // {
        //   dataField: "img",
        //   text: "#",
        //   // eslint-disable-next-line react/display-name
        //   formatter: (cellContent, user) => (
        //     <>
        //       {!user.img ? (
        //         <div className="avatar-xs">
        //           <span className="avatar-title rounded-circle">
        //             {user.name.charAt(0)}
        //           </span>
        //         </div>
        //       ) : (
        //         <div>
        //           <img
        //             className="rounded-circle avatar-xs"
        //             src={images[user.img]}
        //             alt=""
        //           />
        //         </div>
        //       )}
        //     </>
        //   ),
        // },
        {
            text: "Task",
            dataField: "service_class",
            sort: true,
            // eslint-disable-next-line react/display-name
            //   formatter: (cellContent, user) => (
            //     <>
            //       <h5 className="font-size-14 mb-1">
            //         <Link to="#" className="text-dark">

            //         </Link>
            //       </h5>
            //       <p className="text-muted mb-0"></p>
            //     </>
            //   ),
        },
        {
            dataField: "mpd",
            text: "MPD Task ID",
            sort: true,
        },
        {
            dataField: "task_ref",
            text: "Task Card Ref ID",
            sort: true,
        },
        {
            dataField: "zone",
            text: "Zone",
            sort: true,
        },
        {
            dataField: "comp_price",
            text: "CompPrice",
            sort: true,
        },
        {
            dataField: "est_price",
            text: "ServicePrice",
            sort: true,
        },
        {
            dataField: "est_price",
            text: "Estimated Price",
            hidden: true,
            formatter: (cellContent, data) => {
                return <div>{parseInt(data.comp_price) + parseInt(data.est_price)}</div>
            }
        },

        // {
        //     text: "Applicable Aircrafts",
        //     dataField: "aircraft_model",
        //     sort: true,
        //     // eslint-disable-next-line react/display-name
        //     formatter: (cellContent, user) => (
        //         <>
        //             {map(
        //                 user.aircraft_model,
        //                 (tag, index) =>
        //                     index < 2 && (
        //                         <Link
        //                             to="#"
        //                             className="badge badge-soft-primary font-size-11 m-1"
        //                             key={"_skill_" + user.id + index}
        //                         >
        //                             {tag}
        //                         </Link>
        //                     )
        //             )}
        //             {size(user.aircraft_model) > 2 && (
        //                 <Link
        //                     to="#"
        //                     className="badge badge-soft-primary font-size-11 m-1"
        //                     key={"_skill_" + user.id}
        //                 >
        //                     {size(user.aircraft_model) - 1} + more
        //                 </Link>
        //             )}
        //         </>
        //     ),
        // },
        // {
        //     // dataField: "activate/deactivate",
        //     dataField: "-",
        //     text: "Activate/Deactivate",
        //     sort: true,
        // },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <div className="d-flex gap-3">
                    <Link className="text-success" to="#">
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={() => handleUserClick(user)}
                        ></i>
                    </Link>
                    <Link className="text-danger" to="#">
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={() => handleDeleteUser(user)}
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    // useEffect(() => {
    //     if (users && !users.length) {
    //         dispatch(onGetUsers());
    //         setIsEdit(false);
    //     }
    // }, [dispatch, users]);

    // useEffect(() => {
    //     setUserList(users);
    //     setIsEdit(false);
    // }, [users]);

    // useEffect(() => {
    //     if (!isEmpty(users) && !!isEdit) {
    //         setUserList(users);
    //         setIsEdit(false);
    //     }
    // }, [users]);

    useEffect(() => {
        getGtwo()
            .then(resp2 => {
                const gTwoArr = resp2;
                getGthree()
                    .then(resp => {
                        let arr = [];
                        resp.map(n => {
                            const aircraftModel = n.aircraft_model;
                            const serAssigned = typeof n.service_assigned === 'string' ?
                                JSON.parse(n.service_assigned) : n.service_assigned;
                            // const estPrice = gTwoArr?.filter(x =>
                            //     ((typeof x.aircraft_model === 'string' ?
                            //         (JSON.parse(x.aircraft_model).map(n => n.value)) :
                            //         (x.aircraft_model).map(n => n.value)).includes(aircraftModel))
                            //     && serAssigned.map(p => p.value).includes(x.comp_name)).reduce((a, b) => a + parseFloat(b.est_price), 0);

                            const estPrice = gTwoArr && gTwoArr.filter(x =>
                                x?.aircraft_model !== null ? ((typeof x?.aircraft_model === 'string' ?
                                    (JSON.parse(x?.aircraft_model).map(n => n.value)) :
                                    (x?.aircraft_model).map(n => n.value)).includes(aircraftModel)) : null
                                && serAssigned.map(p => p.value).includes(x.comp_name)).reduce((a, b) => a + parseFloat(b?.est_price), 0)
                                + parseFloat(n.est_price);
                            // n.comp_price = estPrice;
                            console.log("estPrice", estPrice)
                            arr.push({
                                ...n,
                                comp_price: estPrice
                            })
                        })
                        setGthree(arr)
                    })
            })
    }, []);

    const toggle = () => {
        setModal(!modal);
    };

    // const handleUserClick = arg => {
    //     const user = arg;

    //     setUserList({
    //         id: user.id,
    //         name: user.name,
    //         designation: user.designation,
    //         email: user.email,
    //         tags: user.tags,
    //         projects: user.projects,
    //     });
    //     setIsEdit(true);

    //     toggle();
    // };

    const handleUserClick = arg => {
        var id = arg.id;
        props.history.push(`/viewpanelg3form/${id}`)
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    // const handleDeleteUser = user => {
    //     if (user.id !== undefined) {
    //         dispatch(onDeleteUser(user));
    //         onPaginationPageChange(1);
    //     }
    // };

    const handleDeleteUser = async (user) => {
        const id = user.id;
        await deleteGthree(id)
            .then(async (resp) => {
                await getGthree()
                    .then(respo => {
                        setGthree(respo)
                        props.history.push(`/panelg3`)
                    })
            })
            .catch(err => console.log("Err", err))
    };

    /**
     * Handling submit user on user form
     */
    const handleValidUserSubmit = (e, values) => {
        if (isEdit) {
            const updateUser = {
                id: userList.id,
                name: values.name,
                designation: values.designation,
                tags: values.tags,
                email: values.email,
                projects: values.projects,
            };

            // update user
            dispatch(onUpdateUser(updateUser));
            setIsEdit(false);
        } else {
            const newUser = {
                id: Math.floor(Math.random() * (30 - 20)) + 20,
                name: values["name"],
                designation: values["designation"],
                email: values["email"],
                tags: values["tags"],
                projects: values["projects"],
            };
            // save new user
            dispatch(onAddNewUser(newUser));
        }
        toggle();
    };

    const handleNewPanel = () => {
        history.push('/panelg3form')
    }

    const handleUserClicks = () => {
        setUserList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Fleet Configuration | Service List Setup</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Configuration" breadcrumbItem="Service List Setup" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField={keyField}
                                        columns={contactListColumns}
                                        data={gthree}
                                    >
                                        {({ paginationProps, paginationTableProps }) => {
                                            return (
                                                <ToolkitProvider
                                                    keyField={keyField}
                                                    data={gthree}
                                                    columns={contactListColumns}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col sm="4">
                                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="8">
                                                                    <div className="text-sm-end">
                                                                        <Button
                                                                            color="primary"
                                                                            className="font-16 btn-block btn btn-primary"
                                                                            onClick={handleNewPanel}

                                                                        //   onClick={() =>
                                                                        //     history.push(
                                                                        //       `/ecommerce-product-detail/${product.id}`
                                                                        //     )
                                                                        //   }
                                                                        //   onClick={handleUserClicks}
                                                                        >
                                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                                            Create New
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={keyField}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            selectRow={selectRow}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap table-hover"
                                                                            }
                                                                            bordered={false}
                                                                            striped={false}
                                                                            responsive
                                                                            ref={node}
                                                                        />

                                                                        <Modal isOpen={modal} toggle={toggle}>
                                                                            <ModalHeader toggle={toggle} tag="h4">
                                                                                {!!isEdit ? "Edit User" : "Add User"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <AvForm
                                                                                    onValidSubmit={handleValidUserSubmit}
                                                                                >
                                                                                    <Row form>
                                                                                        <Col xs={12}>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="aircraft_model"
                                                                                                    label="Aircraft model"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid service class"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.service_class || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_class"
                                                                                                    label="Service class"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Designation"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={
                                                                                                        userList.designation || ""
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_name"
                                                                                                    label="service name"
                                                                                                    type="select"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_name"
                                                                                                    label="service name (confirm)"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    type="select"
                                                                                                    name="select"
                                                                                                    className="form-select"
                                                                                                    label="service1"
                                                                                                    helpMessage="MULTIPLE!"
                                                                                                    multiple={true}
                                                                                                    required
                                                                                                    value={userList.tags || ""}
                                                                                                >
                                                                                                    <option></option>
                                                                                                    <option>service1</option>
                                                                                                    <option>service2</option>
                                                                                                    <option>service3</option>

                                                                                                </AvField>
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_desc"
                                                                                                    label="decription"

                                                                                                    type="textarea"
                                                                                                    id="textarea"

                                                                                                    maxLength="225"
                                                                                                    rows="3"
                                                                                                    placeholder="This textarea has a limit of 225 chars."



                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-end">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                >
                                                                                                    Save
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AvForm>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            );
                                        }}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
PanelGthree.propTypes = {
    history: PropTypes.object,
}

export default withRouter(PanelGthree);
