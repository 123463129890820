import React, { useState, useEffect } from "react"
import MetaTags from 'react-meta-tags';

import {
    Card,
    CardBody,
    CardColumns,
    CardDeck,
    CardFooter,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    Container,
    Row,
    Table
} from "reactstrap"
import { Link } from "react-router-dom"
// import images
import Configuration from "../../assets/images/section/Configuration.jpg"
import Flight from "../../assets/images/section/Flight.jpg"
import Airworthiness from "../../assets/images/section/Airworthiness.jpg"
import Maintenance from "../../assets/images/section/Maintenance.jpg"
import Quality from "../../assets/images/section/Quality.jpg"
import Human from "../../assets/images/section/Human.jpg"
import Stores from "../../assets/images/section/Stores.jpg"
import Warehouse from "../../assets/images/section/Procurement.jpg"
import Management from "../../assets/images/section/Management.jpg"
import Procurement from "../../assets/images/section/Procurement2.png"
import Accounts from "../../assets/images/section/Accounts.jpg"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { getGone, getJourney } from '../../actions/api';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Option = (props) => {
    const { history } = props;
    const { match: { params } } = props
    const id = parseInt(params.id);
    const [form, setForm] = useState(null);
    console.log("Form", form)

    useEffect(() => {
        getGone()
            .then(resp => {
                const filterGone = resp.find(x => x.id === parseInt(params.id))
                getJourney()
                    .then(resp2 => {
                        const journeyLogs = resp2;
                        const aircraftCycles = (filterGone?.aircraft_cycles)
                        const aircraftHrs = (filterGone?.aircraft_hours).split('-');
                        const abc = aircraftHrs.map(x => parseInt(x))
                        const ac_hrs = abc[0] * 60;
                        const ac_min = abc[1];
                        const total_ac = ac_hrs + ac_min
                        const aircraftName = filterGone?.aircraft_name;
                        const aircraftReg = filterGone?.reg_id;
                        // const foundJourney = journeyLogs?.filter(x => x.aircraft_type === aircraftName)
                        // const journeyHour = foundJourney.reduce((a, b) => a + parseInt(b.current_fh_hr), 0) * 60;
                        // const journeyMin = foundJourney.reduce((a, b) => a + parseInt(b.current_fh_min), 0);
                        // const journeyCycles = foundJourney.reduce((a, b) => a + parseInt(b.current_fc), 0);
                        // const total_journey = parseInt(journeyHour) + parseInt(journeyMin)
                        const foundJourney = journeyLogs?.filter(x => x.registration === aircraftReg)
                        let journeyarr = [];
                        foundJourney.forEach((item, i) => {
                            const flightHours = (item.total_flight_hrs)?.split('-');
                            const abc = flightHours?.map(x => parseInt(x))
                            const fc_hrs = abc && abc[0] ? abc[0] * 60 : item.total_flight_hrs
                            const fc_min = abc && abc[1] ? abc[1] : 0
                            journeyarr.push({
                                ...item,
                                journey_hr: fc_hrs,
                                journey_min: fc_min,
                            })
                        })
                        const journeyHour = journeyarr.reduce((a, b) => a + parseInt(b.journey_hr), 0);
                        const journeyMin = journeyarr.reduce((a, b) => a + parseInt(b.journey_min), 0);
                        const journeyCycles = journeyarr.reduce((a, b) => a + parseInt(b.total_flight_cyc), 0);
                        const total_journey = parseInt(journeyHour) + parseInt(journeyMin)

                        const totalFH = Math.floor((total_ac + total_journey) / 60);
                        const totalMin = Math.floor((total_ac + total_journey) % 60);
                        const totalFlightHours = totalFH + '-' + totalMin;
                        const totalFlightCycles = parseInt(aircraftCycles) + journeyCycles;
                        filterGone.add_total_fh = totalFlightHours ? totalFlightHours : filterGone?.aircraft_hours
                        filterGone.add_total_fc = totalFlightCycles ? totalFlightCycles : filterGone?.aircraft_cycles
                        setForm(filterGone)
                    })
            })
    }, []);

    const eLearningWeb = "http://elearning.nu-pie.com";

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Aircraft Activity Selection </title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs breadcrumbItem="Aircraft Activity Selection " />
                    <Row className="mb-3 text-center">
                        <Col xs="3">
                            <address>
                                <strong style={{ fontSize: '16px' }}>Aircraft Model: </strong>
                                <span>{form?.aircraft_name}</span>
                            </address>
                        </Col>
                        <Col xs="3">
                            <address>
                                <strong style={{ fontSize: '16px' }}>National Reg ID: </strong>
                                <span>{form?.reg_id}</span>
                            </address>
                        </Col>
                        <Col xs="3" className="text-center">
                            <address>
                                <strong style={{ fontSize: '16px' }}>Aircraft FH: </strong>
                                <span>{form?.add_total_fh}</span>
                            </address>
                        </Col>
                        <Col xs="3" className="text-center">
                            <address>
                                <strong style={{ fontSize: '16px' }}>Aircraft FC: </strong>
                                <span>{form?.add_total_fc}</span>
                            </address>
                        </Col>
                    </Row>
                    {/* <Row className="mb-3 text-center">
                        <table>
                            <tr>
                                <td><h5><b>Aircraft Model:</b> </h5><h6>{form?.aircraft_name} </h6></td>
                                <td><h5> <b>National Registration ID:</b> </h5><h6>{form?.reg_id} </h6></td>
                            </tr>
                        </table>
                    </Row> */}
                    <Row >
                        <Col md={6} xl={3}>
                            <Link to={`/occm_panel`}>
                                <Card >
                                    <CardBody className="text-center" style={{ height: "8em" }} >
                                        <div className="dropdown d-inline-block" style={{ paddingTop: "10px" }}>
                                            <i className="fas fa-tasks font-size-18" />
                                        </div>
                                        <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} >OCCM Panel</CardTitle>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>

                        <Col md={6} xl={3}>
                            <Link to={`/journey/${id}`}>
                                <Card >
                                    <CardBody className="text-center" style={{ height: "8em" }} >
                                        <div className="dropdown d-inline-block" style={{ paddingTop: "10px" }} >
                                            <i className="fas fa-plane font-size-18" />
                                        </div>
                                        <CardTitle className="h1 mt-0" style={{ paddingTop: "10px" }}>Journey Log Panel S2 </CardTitle>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>



                        <Col md={6} xl={3}>
                            <a href={eLearningWeb} target='_blank' rel="noreferrer">
                                <Card>
                                    <CardBody className="text-center" style={{ height: "8em" }} >
                                        <div className="dropdown d-inline-block" style={{ paddingTop: "10px" }} >
                                            <i className="fas fa-tools font-size-18" />
                                        </div>
                                        {/* <CardTitle className="h1 mt-0" style={{ paddingTop: "10px" }}>Maintenance Log Panel S3 </CardTitle> */}
                                        <CardTitle className="h1 mt-0" style={{ paddingTop: "10px" }}>Panel S3 </CardTitle>
                                    </CardBody>
                                </Card>
                            </a>
                        </Col>

                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>
                                    <div className="dropdown d-inline-block"  >
                                        {/* <i className="fas fa-server font-size-18" /> */}
                                    </div>
                                    {/* <CardTitle className="h1 mt-0" style={{ paddingTop: "10px" }}>Technical Records Summary Panel S4 </CardTitle> */}
                                    <CardTitle className="h1 mt-0" style={{ paddingTop: "10px" }}>Panel S4 </CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>
                                    <div className="dropdown d-inline-block" style={{ paddingTop: "10px" }}>
                                        {/* <i className="fas fa-file font-size-18 " /> */}
                                    </div>
                                    {/* <CardTitle className="h1 mt-0" style={{ paddingTop: "10px" }}>Documentation Panel S5</CardTitle> */}
                                    <CardTitle className="h1 mt-0" style={{ paddingTop: "10px" }}>Panel S5</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>
                                    <div className="dropdown d-inline-block" style={{ paddingTop: "10px" }}>
                                        {/* <i className="fas fa-store font-size-18" /> */}
                                    </div>
                                    {/* <CardTitle className="h1 mt-0" style={{ paddingTop: "10px" }}>Stores Panel S6</CardTitle> */}
                                    <CardTitle className="h1 mt-0" style={{ paddingTop: "10px" }}>Panel S6</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={6} xl={3}>
                            <Link to={`/forecast/${id}`}>
                                <Card>

                                    <CardBody className="text-center" style={{ height: "8em" }}>
                                        <div className="dropdown d-inline-block" style={{ paddingTop: "10px" }} >
                                            <i className="fas fa-chart-line font-size-18" />
                                        </div>
                                        <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} >Forecast Panel S7</CardTitle>

                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S8</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S9</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S10</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S11</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S12</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S13</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S14</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S15</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S16</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S17</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S18</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S19</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S20</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S21</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S22</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S23</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S24</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S25</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S26</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S27</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S28</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S29</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} xl={3}>
                            <Card>

                                <CardBody className="text-center" style={{ height: "8em" }}>

                                    <CardTitle className="h1 mt-0 text-center" style={{ paddingTop: "10px" }} > Panel S30</CardTitle>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>

            </div>
        </React.Fragment >
    )
}

Option.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(Option)