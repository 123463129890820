import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = props => {
    const curUser = JSON.parse(localStorage.getItem("authUser"))
    const curPosition = curUser?.position;

    const [configuration, setConfiguration] = useState(false)
    const [dashboard, setdashboard] = useState(false)
    const [ui, setui] = useState(false)
    const [app, setapp] = useState(false)
    const [email, setemail] = useState(false)
    const [ecommerce, setecommerce] = useState(false)
    const [crypto, setcrypto] = useState(false)
    const [project, setproject] = useState(false)
    const [task, settask] = useState(false)
    const [contact, setcontact] = useState(false)
    const [blog, setBlog] = useState(false)
    const [component, setcomponent] = useState(false)
    const [form, setform] = useState(false)
    const [table, settable] = useState(false)
    const [chart, setchart] = useState(false)
    const [icon, seticon] = useState(false)
    const [map, setmap] = useState(false)
    const [extra, setextra] = useState(false)
    const [invoice, setinvoice] = useState(false)
    const [auth, setauth] = useState(false)
    const [utility, setutility] = useState(false)

    useEffect(() => {
        var matchingMenuItem = null
        var ul = document.getElementById("navigation")
        var items = ul.getElementsByTagName("a")
        for (var i = 0; i < items.length; ++i) {
            if (props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i]
                break
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
        }
    });

    function activateParentDropdown(item) {
        item.classList.add("active")
        const parent = item.parentElement
        if (parent) {
            parent.classList.add("active") // li
            const parent2 = parent.parentElement
            parent2.classList.add("active") // li
            const parent3 = parent2.parentElement
            if (parent3) {
                parent3.classList.add("active") // li
                const parent4 = parent3.parentElement
                if (parent4) {
                    parent4.classList.add("active") // li
                    const parent5 = parent4.parentElement
                    if (parent5) {
                        parent5.classList.add("active") // li
                        const parent6 = parent5.parentElement
                        if (parent6) {
                            parent6.classList.add("active") // li
                        }
                    }
                }
            }
        }
        return false
    }

    return (
        <React.Fragment>
            <div className="topnav">
                <div className="container-fluid">
                    <nav
                        className="navbar navbar-light navbar-expand-lg topnav-menu"
                        id="navigation"
                    >
                        <Collapse
                            isOpen={props.leftMenu}
                            className="navbar-collapse"
                            id="topnav-menu-content"
                        >
                            <ul className="navbar-nav">
                                {curPosition === 'admin' ?
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle arrow-none" to="/users" >
                                            <i className="bx bx-chalkboard me" />{' '}
                                            {props.t("Access Level Control")} {props.menuOpen}
                                        </Link>
                                    </li>
                                    : null
                                }
                                {curPosition === 'System Admin' ?
                                    <>
                                        <li className="nav-item dropdown">
                                            <Link
                                                to="/#"
                                                onClick={e => {
                                                    e.preventDefault()
                                                    //this.setState({ appState: !this.state.appState })
                                                    setConfiguration(!configuration)
                                                }}
                                                className="nav-link dropdown-toggle arrow-none"
                                            >
                                                <i className="bx bx-tone me" />
                                                {props.t("Configuration")} <div className="arrow-down" />
                                            </Link>
                                            <div
                                                className={classname("dropdown-menu", {
                                                    // show: this.state.appState,
                                                    show: configuration
                                                })}
                                            >
                                                <Link to="/panelg1" className="dropdown-item">
                                                    {props.t("Aircraft Setup")}
                                                </Link>
                                                <Link to="/panelg2" className="dropdown-item">
                                                    {props.t("Component List Setup")}
                                                </Link>
                                                <Link to="/panelg3" className="dropdown-item">
                                                    {props.t("Service List Setup")}
                                                </Link>
                                            </div>
                                        </li>
                                    </> : null}

                                {curPosition !== 'admin' ?
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link dropdown-toggle arrow-none" to="/mdashboard" >
                                            <i className="bx bx-customize me" />{' '}
                                            {props.t("Dashboard")} {props.menuOpen}
                                        </Link>
                                    </li>
                                    : null
                                }
                                {/* <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle arrow-none" to="/option1" >
                                        <i className="bx bx-customize me" />{' '}
                                        {props.t("Option1")} {props.menuOpen}
                                    </Link>
                                </li>

                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle arrow-none" to="/option2" >
                                        <i className="bx bx-customize me" />{' '}
                                        {props.t("Option2")} {props.menuOpen}
                                    </Link>
                                </li> */}

                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
}

Navbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
}

const mapStatetoProps = state => {
    const { leftMenu } = state.Layout
    return { leftMenu }
}

export default withRouter(
    connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
