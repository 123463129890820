import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";

import powerBiImg from "assets/images/powerbi.png";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


const PowerBi = props => {
    const { history } = props;

    const powerBiUrl = "https://app.powerbi.com/links/5dJnpe9ryG?ctid=a08e97ba-0317-429e-98e2-1e509b6745be&pbi_source=linkShare"

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Forecast | Dashboard</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    {/* <Breadcrumbs title="Forecast" breadcrumbItem="Scheduler" /> */}
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <a href={powerBiUrl} target="_blank" rel="noreferrer">
                                        <img src={powerBiImg} height="480" />
                                    </a>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
PowerBi.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(PowerBi);
