import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import Dropzone from "react-dropzone"
import { Link, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Progress,
    Alert
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { getGone, uploadJourneyFile, getTempJourney, getJourney, postJourney } from "actions/api"
// import { Table } from "react-super-responsive-table";
// const [formRows, setFormRows] = useState([{ id: 1 }])

//   const onAddFormRow = () => {
//     const modifiedRows = [...formRows]
//     modifiedRows.push({ id: modifiedRows.length + 1 })
//     setFormRows(modifiedRows)
//   }
const JourneyLogForm = props => {
    const { history } = props;
    const { match: { params } } = props
    const id = params.id;
    const [modal, setmodal] = useState(false)
    const [subscribemodal, setSubscribemodal] = useState(false)
    const [form, setForm] = useState({ pic_sign: "No", commander_sign: "No", daily_sign: "No", sign_stamp: "No" })
    const [aircraft_model, setAircraft] = useState(null)
    console.log("form", form)
    const [journeyFile, setJourneyFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        getGone()
            .then(resp => {
                const filterGone = resp.find(x => x.id === parseInt(params.id))
                setAircraft(filterGone?.aircraft_name)
                const regId = (filterGone?.reg_id).replace(/[^a-zA-Z0-9 ]/g, "");
                let total_ac;
                let total_journey;
                getJourney()
                    .then(resp2 => {
                        const journeyLogs = resp2;
                        const aircraftCycles = (filterGone?.aircraft_cycles);
                        console.log("aircraftCycles", aircraftCycles)
                        const aircraftHrs = (filterGone?.aircraft_hours).split('-');
                        const abc = aircraftHrs.map(x => parseInt(x))
                        const ac_hrs = abc[0] * 60;
                        const ac_min = abc[1];
                        total_ac = ac_hrs + ac_min
                        const aircraftName = filterGone?.aircraft_name;
                        const aircraftReg = filterGone?.reg_id;
                        // const foundJourney = journeyLogs?.filter(x => x.aircraft_type === aircraftName)
                        const foundJourney = journeyLogs?.filter(x => x.registration === aircraftReg)
                        let journeyarr = [];
                        foundJourney.forEach((item, i) => {
                            const flightHours = (item.total_flight_hrs)?.split('-');
                            const abc = flightHours?.map(x => parseInt(x))
                            const fc_hrs = abc && abc[0] ? abc[0] * 60 : item.total_flight_hrs
                            const fc_min = abc && abc[1] ? abc[1] : 0
                            journeyarr.push({
                                ...item,
                                journey_hr: fc_hrs,
                                journey_min: fc_min,
                            })
                        })
                        const journeyHour = journeyarr.reduce((a, b) => a + parseInt(b.journey_hr), 0);
                        const journeyMin = journeyarr.reduce((a, b) => a + parseInt(b.journey_min), 0);
                        const journeyCycles = journeyarr.reduce((a, b) => a + parseInt(b.total_flight_cyc), 0);

                        // const journeyHour = foundJourney.reduce((a, b) => a + parseInt(b.current_fh_hr), 0) * 60;
                        // const journeyMin = foundJourney.reduce((a, b) => a + parseInt(b.current_fh_min), 0);
                        // const journeyCycles = foundJourney.reduce((a, b) => a + parseInt(b.current_fc), 0);
                        total_journey = parseInt(journeyHour) + parseInt(journeyMin)
                        const totalFH = Math.floor((total_ac + total_journey) / 60);
                        const totalMin = Math.floor((total_ac + total_journey) % 60);
                        const totalFC = parseInt(aircraftCycles) + journeyCycles;
                        const aircraftTotalHrs = totalFH + '-' + totalMin;
                        setForm({
                            ...form, aircraft_type: filterGone?.aircraft_name,
                            total_fh_hr: totalFH, total_fh_min: totalMin, total_fc: totalFC,
                            reg_id: regId, aircraft_total_hrs: aircraftTotalHrs, aircraft_total_cyc: totalFC,
                            registration: filterGone?.reg_id
                        })
                    })
            })
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setSubscribemodal(true)
        }, 500)
    }, [])

    const handleFileSubmit = async () => {
        //setSubscribemodal(!subscribemodal)
        setLoading(true)
        const formData = new FormData();
        formData.append("file", journeyFile)
        // const fileType = "Journey Log";
        try {
            const resp = await uploadJourneyFile(formData)
            if (resp && resp[0] == 'success') {
                getTempJourney()
                    .then(respo => {
                        console.log("respo", respo)
                        const result = respo[respo.length - 1];
                        const logSerial = (result?.log_sl_no).substring(0, 5);
                        if (logSerial === form.reg_id) {
                            //Move forward with pulling the data
                            //Capture DD MM YYYY and converting that to a date
                            const curDate = new Date(result.date_yyyy, parseInt(result.date_mm - 1), result.date_dd)
                            const departureDate1 = result.departure_date_1_dd ? new Date(result.date_yyyy, parseInt(result.departure_date_1_mm - 1), result.departure_date_1_dd) : null;
                            const departureDate2 = result.departure_date_2_dd ? new Date(result.date_yyyy, parseInt(result.departure_date_2_mm - 1), result.departure_date_2_dd) : null;
                            const departureDate3 = result.departure_date_3_dd ? new Date(result.date_yyyy, parseInt(result.departure_date_3_mm - 1), result.departure_date_3_dd) : null;
                            const departureDate4 = result.departure_date_4_dd ? new Date(result.date_yyyy, parseInt(result.departure_date_4_mm - 1), result.departure_date_4_dd) : null;
                            const arrivalDate1 = result.arrival_date_1_dd ? new Date(result.date_yyyy, parseInt(result.arrival_date_1_mm - 1), result.arrival_date_1_dd) : null;
                            const arrivalDate2 = result.arrival_date_2_dd ? new Date(result.date_yyyy, parseInt(result.arrival_date_2_mm - 1), result.arrival_date_2_dd) : null;
                            const arrivalDate3 = result.arrival_date_3_dd ? new Date(result.date_yyyy, parseInt(result.arrival_date_3_mm - 1), result.arrival_date_3_dd) : null;
                            const arrivalDate4 = result.arrival_date_4_dd ? new Date(result.date_yyyy, parseInt(result.arrival_date_4_mm - 1), result.arrival_date_4_dd) : null;
                            const dailyInspection = result.daily_inspect_dd ? new Date(result.daily_inspect_yyyy, parseInt(result.daily_inspect_mm - 1), result.daily_inspect_dd) : null;
                            const nextDueMaintenance = result.next_due_maint_dd ? new Date(result.next_due_maint_yyyy, parseInt(result.next_due_maint_mm - 1), result.next_due_maint_dd) : null;
                            const dueDate = result.due_date_dd ? new Date(result.due_date_yyyy, parseInt(result.due_date_mm - 1), result.due_date_dd) : null;
                            const melExpiryDate = result.mel_expiry_date_mm ? new Date(result.mel_expiry_date_yyyy, parseInt(result.mel_expiry_date_mm - 1), result.mel_expiry_date_dd) : null;

                            const reg_id = result.aircraft_regn_1 + '-' + result.aircraft_regn_2;
                            const picLicenseNumber = result.pic_license_num + '-' + result.pic_license_alpha;

                            const departureTime1 = result.departure_time_1_hh + ':' + result.departure_time_1_mm;
                            const departureTime2 = result.departure_time_2_hh + ':' + result.departure_time_2_mm;
                            const departureTime3 = result.departure_time_3_hh + ':' + result.departure_time_3_mm;
                            const departureTime4 = result.departure_time_4_hh + ':' + result.departure_time_4_mm;
                            const arrivalTime1 = result.arrival_time_1_hh + ':' + result.arrival_time_1_mm;
                            const arrivalTime2 = result.arrival_time_2_hh + ':' + result.arrival_time_2_mm;
                            const arrivalTime3 = result.arrival_time_3_hh + ':' + result.arrival_time_3_mm;
                            const arrivalTime4 = result.arrival_time_4_hh + ':' + result.arrival_time_4_mm;
                            const sectorDuration1 = result.sector_duration_1_hh + ':' + result.sector_duration_1_mm;
                            const sectorDuration2 = result.sector_duration_2_hh + ':' + result.sector_duration_2_mm;
                            const sectorDuration3 = result.sector_duration_3_hh + ':' + result.sector_duration_3_mm;
                            const sectorDuration4 = result.sector_duration_4_hh + ':' + result.sector_duration_4_mm;

                            const totalFlightHrs = result.aircraft_total_hrs_hh + '-' + result.aircraft_total_hrs_mm;
                            console.log("totalFlightHrs", totalFlightHrs)
                            const licNumber = result.lic_num + '-' + result.lic_alpha;

                            result.date = curDate;
                            result.registration = reg_id;
                            result.pic_license_num = picLicenseNumber;
                            result.departure_date_1 = departureDate1;
                            result.departure_date_2 = departureDate2;
                            result.departure_date_3 = departureDate3;
                            result.departure_date_4 = departureDate4;

                            result.arrival_date_1 = arrivalDate1;
                            result.arrival_date_2 = arrivalDate2;
                            result.arrival_date_3 = arrivalDate3;
                            result.arrival_date_4 = arrivalDate4;

                            result.departure_time_1 = departureTime1;
                            result.departure_time_2 = departureTime2;
                            result.departure_time_3 = departureTime3;
                            result.departure_time_4 = departureTime4;

                            result.arrival_time_1 = arrivalTime1;
                            result.arrival_time_2 = arrivalTime2;
                            result.arrival_time_3 = arrivalTime3;
                            result.arrival_time_4 = arrivalTime4;

                            result.sector_duration_1 = sectorDuration1;
                            result.sector_duration_2 = sectorDuration2;
                            result.sector_duration_3 = sectorDuration3;
                            result.sector_duration_4 = sectorDuration4;

                            result.daily_inspect_date = dailyInspection;
                            result.next_due_date = nextDueMaintenance;
                            result.due_date = dueDate;
                            result.mel_expiry_date = melExpiryDate;

                            result.total_flight_hrs = totalFlightHrs;
                            result.aircraft_total_hrs = form.total_fh_hr + '-' + form.total_fh_min;

                            result.lic_num = licNumber;

                            result.aircraft_type = form.aircraft_type;
                            result.registration = form.registration;
                            result.pic_sign = form.pic_sign;
                            result.commander_sign = form.commander_sign;
                            result.daily_sign = form.daily_sign;
                            result.sign_stamp = form.sign_stamp;



                            const totalFHhrs = parseInt(form.total_fh_hr) * 60
                            const totalFHmin = parseInt(form.total_fh_min)
                            const addTotalFH = totalFHhrs + totalFHmin;
                            const currentFHhrs = parseInt(result.current_fh_hr) * 60
                            const currentFHmin = parseInt(result.current_fh_min)
                            const addCurFH = currentFHhrs + currentFHmin;
                            const totalFH = Math.floor((addTotalFH + addCurFH) / 60);
                            const totalMin = Math.floor((addTotalFH + addCurFH) % 60);

                            result.new_total_fh_hr = totalFH;
                            result.new_total_fh_min = totalMin;
                            result.new_total_fh = totalFH + '-' + totalMin;
                            // const newTotalFC = parseInt(result.total_fc) + parseInt(result.current_fc)
                            const newTotalFC = parseInt(form?.aircraft_total_cyc) + parseInt(result?.total_flight_cyc)
                            result.new_total_fc = newTotalFC;
                            setForm(result)
                            setLoading(false)
                            setSubscribemodal(!subscribemodal)
                        } else {
                            //Throw Error
                            setLoading(false)
                            setError("AJL did not match with the current aircraft, please refresh the page and try again")
                            toast.error('AJL did not match with the current aircraft');
                        }
                    })
            } else {
                setLoading(false)
                setError("Something went wrong");
                toast.error('Something went wrong');
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const [selectedFiles, setselectedFiles] = useState([])

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        )
        setselectedFiles(files)
    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    function previewImage() {
        var file = document.getElementById("file").files;
        setJourneyFile(file[0])
        if (file.length > 0) {
            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                document.getElementById("preview").setAttribute("src", event.target.result);
            };
            fileReader.readAsDataURL(file[0])
        }
    }

    // const totalFHhrs = form?.total_fh_hr ? parseInt(form?.total_fh_hr) * 60 : 0;
    // const totalFHmin = form?.total_fh_min ? parseInt(form?.total_fh_min) : 0;
    // const addTotalFH = totalFHhrs + totalFHmin;
    // const currentFHhrs = form?.current_fh_hr ? parseInt(form?.current_fh_hr) * 60 : 0;
    // const currentFHmin = form?.current_fh_min ? parseInt(form?.current_fh_min) : 0;
    // const addCurFH = currentFHhrs + currentFHmin;
    // const totalFH = Math.floor((addTotalFH + addCurFH) / 60);
    // const totalMin = Math.floor((addTotalFH + addCurFH) % 60);

    // form.new_total_fh_hr = totalFH ? totalFH : 0;
    // form.new_total_fh_min = totalMin ? totalMin : 0;
    // const newTotalFC = parseInt(form?.total_fc) + parseInt(form?.current_fc)
    // form.new_total_fc = newTotalFC ? newTotalFC : null;

    const totalFHhrs = form?.total_fh_hr ? parseInt(form?.total_fh_hr) * 60 : 0;
    const totalFHmin = form?.total_fh_min ? parseInt(form?.total_fh_min) : 0;
    const addTotalFH = totalFHhrs + totalFHmin;
    const currentFHhrs = form?.total_flight_hrs ? parseInt(form?.total_flight_hrs) * 60 : 0;
    const currentFHmin = form?.current_fh_min ? parseInt(form?.current_fh_min) : 0;
    const addCurFH = currentFHhrs + currentFHmin;
    const totalFH = Math.floor((addTotalFH + addCurFH) / 60);
    const totalMin = Math.floor((addTotalFH + addCurFH) % 60);

    form.new_total_fh_hr = totalFH ? totalFH : 0;
    form.new_total_fh_min = totalMin ? totalMin : 0;
    form.new_total_fh = totalFH && totalMin ? (totalFH + '-' + totalMin) : totalFH
    const newTotalFC = parseInt(form?.aircraft_total_cyc) + parseInt(form?.total_flight_cyc)
    form.new_total_fc = newTotalFC ? newTotalFC : null;


    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    function handleChecked(e) {
        setForm({ ...form, [e.target.name]: e.target.checked })
    }

    const [rows1, setrows1] = useState([{ id: 1 }])



    const [formRows, setFormRows] = useState()
    console.log("formRows", formRows)

    const onAddFormRow = () => {
        const modifiedRows = [...formRows]
        modifiedRows.push({ id: modifiedRows.length + 1 })
        setFormRows(modifiedRows)
    }
    const handleFormChange = (e, i) => {
        const tempValues = [...formRows];
        tempValues[i] = { ...tempValues[i], [e.target.name]: e.target.value };
        setFormRows(tempValues);
    }

    const onDeleteFormRow = id => {
        if (id !== 1) {
            var modifiedRows = [...formRows]
            modifiedRows = modifiedRows.filter(x => x["id"] !== id)
            setFormRows(modifiedRows)
        }
    }
    const handleSubmit = async () => {
        // form.part_replacement = formRows;
        form.aircraft_type = aircraft_model;
        form.id = null;
        //form.aircraft_type, form.date, form.registration, form.new_total_fh, form.new_total_fc
        //form.log_sl_no = 
        postJourney(form)
            .then(resp => {
                if (resp) {
                    history.push(`/journey/${id}`)
                }
            })
    }

    const handleCancel = () => {
        history.push(`/journey/${id}`)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Aerotrend | JOURNEY LOG PANEL</title>
                </MetaTags>
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="JOURNEY LOG PANEL" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4 ">JOURNEY LOG PANEL</CardTitle>
                                    <AvForm onValidSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Company Name
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" name="company_name"
                                                            value={form.company_name}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Date
                                                    </label>
                                                    <div className="col-md-6">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.date}
                                                            onChange={(date) => setForm({ ...form, date: date })}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Aircraft Registration
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" name="registration" value={form.registration}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Aircraft Type
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" name="aircraft_type" value={form.aircraft_type}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>

                                            <Col>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Log Serial No.
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" name="log_sl_no" value={form.log_sl_no}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        PIC Name
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" name="pic_name" value={form.pic_name}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        PIC License No
                                                    </label>
                                                    <div className="col-md-6">
                                                        <AvField type="text" id="text" rows="3" name="pic_license_num" value={form.pic_license_num}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        PIC Signature
                                                    </label>
                                                    <div className="col-md-8 mt-2">
                                                        <AvRadioGroup inline name="pic_sign" value={form.pic_sign}
                                                            onChange={(e) => { handleChange(e) }}>
                                                            <AvRadio label="Yes" value="Yes" />
                                                            <AvRadio label="No" value="No" />
                                                        </AvRadioGroup>
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Commander Pre-Flight Inspection Signature
                                                    </label>
                                                    <div className="col-md-8 mt-4">
                                                        <AvRadioGroup inline name="commander_sign" value={form.commander_sign}
                                                            onChange={(e) => { handleChange(e) }}>
                                                            <AvRadio label="Yes" value="Yes" />
                                                            <AvRadio label="No" value="No" />
                                                        </AvRadioGroup>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <h4 className="card-title mt-5 "> Sl.No: 1</h4>
                                            <Col>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Flight Number
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="flight_num_1" value={form.flight_num_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector From
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="sector_from_1" value={form.sector_from_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector To
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="sector_to_1" value={form.sector_to_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h4 className="card-title mt-2"> On-Chock</h4>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.departure_date_1}
                                                            onChange={(date) => setForm({ ...form, departure_date_1: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="departure_time_1" value={form.departure_time_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.arrival_date_1}
                                                            onChange={(date) => setForm({ ...form, arrival_date_1: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="arrival_time_1" value={form.arrival_time_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector Duration
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="sector_duration_1" value={form.sector_duration_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col >
                                                <h4 className="card-title mt-2"> Off-Chock</h4>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.departure_date_2}
                                                            onChange={(date) => setForm({ ...form, departure_date_2: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="departure_time_2" value={form.departure_time_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.arrival_date_2}
                                                            onChange={(date) => setForm({ ...form, arrival_date_2: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="arrival_time_2" value={form.arrival_time_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector Duration
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="sector_duration_2" value={form.sector_duration_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <h4 className="card-title mt-5 "> Sl.No: 2</h4>
                                            <Col>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Flight Number
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="flight_num_2" value={form.flight_num_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector From
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="sector_from_2" value={form.sector_from_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector To
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="sector_to_2" value={form.sector_to_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h4 className="card-title mt-2"> On-Chock</h4>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.departure_date_3}
                                                            onChange={(date) => setForm({ ...form, departure_date_3: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="departure_time_3" value={form.departure_time_3}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.arrival_date_3}
                                                            onChange={(date) => setForm({ ...form, arrival_date_3: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="arrival_time_3" value={form.arrival_time_3}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector Duration
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="sector_duration_3" value={form.sector_duration_3}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col >
                                                <h4 className="card-title mt-2"> Off-Chock</h4>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.departure_date_4}
                                                            onChange={(date) => setForm({ ...form, departure_date_4: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Departure Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="departure_time_4" value={form.departure_time_4}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.arrival_date_4}
                                                            onChange={(date) => setForm({ ...form, arrival_date_4: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Arrival Time
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="arrival_time_4" value={form.arrival_time_4}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Sector Duration
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="sector_duration_4" value={form.sector_duration_4}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Row className="mb-3 mt-5">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Arrival
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="fuel_arrival" value={form.fuel_arrival}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Departure
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="fuel_departure" value={form.fuel_departure}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Remaining Fuel Onboard
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="remain_fuel_onboard" value={form.remain_fuel_onboard}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Uplift
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="fuel_uplift" value={form.fuel_uplift}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Calculate Total Fuel
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="cal_total_fuel" value={form.cal_total_fuel}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Discrepancy
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="fuel_discrepancy" value={form.fuel_discrepancy}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Aircraft Total Hrs
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField disabled type="text" id="number" rows="3"
                                                            name="aircraft_total_hrs" value={form.aircraft_total_hrs}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Aircraft Total Cyc
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField disabled type="number" id="number" rows="3"
                                                            name="aircraft_total_cyc" value={form.aircraft_total_cyc}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Fuel Flight Deck Gauge
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="fuel_flight_deck_gauge" value={form.fuel_flight_deck_gauge}
                                                            onChange={e => {

                                                                handleChange(e)
                                                            }}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Next Due Maintenance
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.next_due_date}
                                                            onChange={(date) => setForm({ ...form, next_due_date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Due@Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.due_date}
                                                            onChange={(date) => setForm({ ...form, due_date: date })}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 ">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Due@Hours
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="due_hours" value={form.due_hours}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>

                                            <Col>
                                                <Row className="mb-3 mt-5">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Total Flight Hrs
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="total_flight_hrs" value={form.total_flight_hrs}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Total Flight Cyc
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="total_flight_cyc" value={form.total_flight_cyc}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Daily Inspection
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.daily_inspect_date}
                                                            onChange={(date) => setForm({ ...form, daily_inspect_date: date })}
                                                        />

                                                    </div>

                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Type of Maintenance
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="type_maintenance" value={form.type_maintenance}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        APU Hrs
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="apu_hrs" value={form.apu_hrs}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        APU Cyc
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="apu_cyc" value={form.apu_cyc}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Oil Uplift Engine No.1
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" rows="3" name="oil_uplift_eng_1" value={form.oil_uplift_eng_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Oil Uplift Engine No.2
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="oil_uplift_eng_2" value={form.oil_uplift_eng_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }} />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Oil Uplift APU
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="oil_uplift_apu" value={form.oil_uplift_apu}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }} />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Action Taken
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="textarea" name="action_taken" value={form.action_taken}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Defect Description
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="textarea" id="textarea" rows="3" name="defect_description" value={form.defect_description}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Row className="mb-3 mt-6">
                                                <label
                                                    htmlFor="example-text-input"
                                                    className="col-md-6 col-form-label"
                                                >
                                                    Daily Inspection Signature & Stamp
                                                </label>
                                                <div className="col-md-6 mt-2">
                                                    <AvRadioGroup inline name="daily_sign" value={form.daily_sign}
                                                        onChange={(e) => { handleChange(e) }} >
                                                        <AvRadio label="Yes" value="Yes" />
                                                        <AvRadio label="No" value="No" />
                                                    </AvRadioGroup>
                                                </div>
                                            </Row>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Row className="mb-3 mt-2">
                                                    <div className="form-check form-check-end">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                            checked={form.pirep}
                                                            name="pirep"
                                                            onChange={e => {
                                                                handleChecked(e)
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            PIREP
                                                        </label>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <div className="form-check form-check-end  mt-2 md-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck2"
                                                            checked={form.marep}
                                                            name="marep"
                                                            onChange={e => {
                                                                handleChecked(e)
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label "
                                                            htmlFor="defaultCheck2"
                                                        >
                                                            MAREP
                                                        </label>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <div className="form-check form-check-end">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                            checked={form.info}
                                                            name="info"
                                                            onChange={e => {
                                                                handleChecked(e)

                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="defaultCheck1"
                                                        >
                                                            INFO
                                                        </label>
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <div className="form-check form-check-end  mt-2 md-2">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck2"
                                                            checked={form.cabin}
                                                            name="cabin"
                                                            onChange={e => {
                                                                handleChecked(e)

                                                            }}


                                                        />
                                                        <label
                                                            className="form-check-label "
                                                            htmlFor="defaultCheck2"
                                                        >
                                                            CABIN
                                                        </label>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <h4 className="card-title mt-5">Action Taken</h4>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        MEL Expiry Date
                                                    </label>
                                                    <div className="col-md-8">
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="dd/MM/yyyy"
                                                            placeholderText="DD/MM/YYYY"
                                                            selected={form.mel_expiry_date}
                                                            onChange={(date) => setForm({ ...form, mel_expiry_date: date })}
                                                        />

                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        MEL Reference
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" rows="3" name="mel_reference" value={form.mel_reference}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        MEL Repair cat
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="mel_repair_cat" value={form.mel_repair_cat}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        LIC No
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="text" rows="3" name="lic_num" value={form.lic_num}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h3 className="card-title mt-5">Part Description - 1</h3>
                                                <Row className="mb-3 mt-5">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Part Number On
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="part_num_on_1" value={form.part_num_on_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Part Number Off
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="part_num_off_1" value={form.part_num_off_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Serial Number On
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="serial_num_on_1" value={form.serial_num_on_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Serial Number Off
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="serial_num_off_1" value={form.serial_num_off_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Certificate Number
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="certificate_num_1" value={form.certificate_num_1}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <h3 className="card-title mt-5">Part Description - 2</h3>
                                                <Row className="mb-3 mt-5">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Part Number On
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="part_num_on_2" value={form.part_num_on_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>

                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Part Number Off
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="part_num_off_2" value={form.part_num_off_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Serial Number On
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="serial_num_on_2" value={form.serial_num_on_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Serial Number Off
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="text" id="number" rows="3" name="serial_num_off_2" value={form.serial_num_off_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3 mt-6">
                                                    <label
                                                        htmlFor="example-text-input"
                                                        className="col-md-4 col-form-label"
                                                    >
                                                        Certificate Number
                                                    </label>
                                                    <div className="col-md-8">
                                                        <AvField type="number" id="number" rows="3" name="certificate_num_2" value={form.certificate_num_2}
                                                            onChange={e => {
                                                                handleChange(e)
                                                            }}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3 mt-6">
                                            <label
                                                htmlFor="example-text-input"
                                                className="col-md-4 col-form-label"
                                            >
                                                Signature and Stamp
                                            </label>
                                            <div className="col-md-8 mt-2">
                                                <AvRadioGroup inline name="sign_stamp" value={form.sign_stamp}
                                                    onChange={(e) => { handleChange(e) }}>
                                                    <AvRadio label="Yes" value="Yes" />
                                                    <AvRadio label="No" value="No" />
                                                </AvRadioGroup>
                                            </div>
                                        </Row>

                                        <Row>
                                            <Col className="d-flex flex-wrap gap-2 justify-content-center">
                                                <Button className=" btn btn-success save-user w-md"
                                                    type="submit"
                                                >
                                                    Submit
                                                </Button>
                                                <Button className=" btn btn-secondary save-user w-md"
                                                    onClick={handleCancel}>
                                                    Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                    </AvForm>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </Container>
                {/* container-fluid */}
            </div>
            {/* subscribe ModalHeader */}
            <Modal
                isOpen={subscribemodal}
                role="dialog"
                autoFocus={true}
                centered
                data-toggle="modal"
                toggle={() => {
                    setSubscribemodal(!subscribemodal)
                }}
            >
                <div className="modal-content">
                    <div className="modal-header border-bottom-0">
                        <ModalHeader
                            toggle={() => {
                                setSubscribemodal(!subscribemodal)
                            }}
                        ></ModalHeader>
                    </div>
                    <div className="modal-body">
                        <div className="text-center mb-4">
                            {loading ?
                                <div className="row justify-content-center">
                                    <div className="col-xl-10">
                                        <h4 className="text-primary">Processing...</h4>
                                        <Alert color="danger">
                                            Please{" "}
                                            <Link to="#" className="alert-link">
                                                do not refresh the page
                                            </Link>
                                            {" "}and wait while we are fetching the details
                                        </Alert>
                                        <Progress
                                            className="mt-5"
                                            value={100}
                                            color="success"
                                            style={{ width: '100%' }}
                                            animated
                                        ></Progress>
                                    </div>
                                </div>
                                : error !== null ?
                                    <div className="row justify-content-center">
                                        <div className="col-xl-10">
                                            <h4 className="text-danger">{error}</h4>
                                        </div>
                                    </div>
                                    :
                                    <div className="row justify-content-center">
                                        <div className="col-xl-10">
                                            <h4 className="text-primary">Upload Journey Log</h4>
                                            <Form>
                                                <div className="input-group rounded bg-light mt-5">
                                                    {/* <Input type="file" className="form-control" name="file" id="file"
                                                    value={journeyFile} onChange={previewImage} /> */}
                                                    <input
                                                        onChange={previewImage}
                                                        accept="image/jpeg"
                                                        className="form-control"
                                                        id="file"
                                                        type="file"
                                                    />
                                                    {/* <Label className="input-group-text" htmlFor="inputGroupFile02">Upload</Label> */}

                                                </div>
                                                {journeyFile !== null ?
                                                    <div className="mb-3 mt-3" style={{ alignItems: "center" }} >
                                                        <img id="preview"
                                                            thumbnail=""
                                                            height="150"
                                                            className="avatar-sm-5 rounded bg-light"
                                                        ></img>
                                                    </div>
                                                    : null
                                                }
                                            </Form>
                                            <Button className="mt-3" color="primary" type="button" id="button-addon2"
                                                onClick={handleFileSubmit}>
                                                <i className="bx bxs-paper-plane"></i>{' '}
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>

                    {/* <div className="modal-body">
                        <Form>
                            <div className="input-group">
                                <Input type="file" className="form-control" name="file" id="file" onChange={previewImage} />
                                 <Label className="input-group-text" htmlFor="inputGroupFile02">Upload</Label> 

                            </div>
                            <div className="mb-3 mt-3" style={{ alignItems: "center" }} >
                                <img id="preview"
                                    thumbnail=""
                                    height="150"
                                    className="avatar-sm-5 rounded bg-light"
                                ></img>
                            </div>

                        </Form>
                        <div className="text-center mt-4">
                            <button
                                type="button"
                                className="btn btn-primary "
                            >
                                SUBMIT
                            </button>
                        </div>

                    </div> */}
                </div>
            </Modal>
        </React.Fragment>
    )
}

JourneyLogForm.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(JourneyLogForm)
