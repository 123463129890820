import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
    // getUsers as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty, size, map, filter } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getUsers, deleteUser } from "../../actions/api"

const Users = props => {
    const dispatch = useDispatch();
    const [users, setUsers] = useState([])
    console.log("users", users)
    // const { users } = useSelector(state => ({
    //     users: state.contacts.users,
    // }));

    const { history } = props

    const [userList, setUserList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: sizePerPage,
        totalSize: users.length, // replace later with size(users),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    const selectRow = {
        mode: "checkbox",
    };

    const contactListColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
        },
        {
            dataField: "id",
            text: "#",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    {!user.img ? (
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle">
                                {/* {user.name.charAt(0)} */}
                                {user.id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle avatar-xs"
                                src={images[user.img]}
                                alt=""
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            text: "User Name",
            dataField: "username",
            sort: true,

        },
        {
            dataField: "emp_name",
            text: "Employee Name",
            sort: true,
        },

        {
            dataField: "staff_id",
            text: "Staff ID",
            sort: true,
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
        },
        {
            dataField: "position",
            text: "User Position",
            sort: true,
        },
        {
            dataField: "access",
            text: "Access level",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => {
                let data = user.access
                if (typeof data === 'string') {
                    data = JSON.parse(user?.access)
                }
                data = data.map(n => n.value)
                console.log("data", data)
                return (
                    <>
                        <p className="badge badge-soft-primary font-size-11 m-1">
                            {/* {data?.map(n => n?.value).join(", ")} */}
                            {data?.join(", ")}
                        </p>
                    </>
                );
            }

        },
        // {
        //     dataField: "access",
        //     text: "Access level",
        //     sort: true,
        //     // eslint-disable-next-line react/display-name
        //     formatter: (cellContent, user) => (
        //         <>
        //             <p className="badge badge-soft-primary font-size-11 m-1">
        //                 {(JSON.parse(user?.access)).map(n => n.value).join(", ")}
        //             </p>
        //             {map(
        //                 user?.role,
        //                 (tag, index) =>
        //                     <Link
        //                         to="#"
        //                         className="badge badge-soft-primary font-size-11 m-1"
        //                         key={"_skill_" + user.id + index}
        //                     >
        //                         {tag}
        //                     </Link>
        //                 // )
        //             )}
        //         </>
        //     ),
        // },
        // {
        //     dataField: "access",
        //     text: "Access level",
        //     sort: true,
        // },
        // {
        //     dataField: "activate/deactivate",
        //     text: "Activate/Deactivate",
        //     sort: true,
        // },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <div className="d-flex gap-3">
                    <Link className="text-success" to="#">
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={() => handleUserClick(user)}
                        ></i>
                    </Link>
                    <Link className="text-danger" to="#">
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={() => handleDeleteUser(user)}
                        ></i>
                    </Link>
                </div>
            ),
        },
    ];

    // useEffect(() => {
    //     if (users && !users.length) {
    //         dispatch(onGetUsers());
    //         setIsEdit(false);
    //     }
    // }, [dispatch, users]);

    // useEffect(() => {
    //     setUserList(users);
    //     setIsEdit(false);
    // }, [users]);

    // useEffect(() => {
    //     if (!isEmpty(users) && !!isEdit) {
    //         setUserList(users);
    //         setIsEdit(false);
    //     }
    // }, [users]);

    useEffect(() => {
        getUsers()
            .then(resp => {
                console.log("userResponse", resp)
                const filterUsers = resp.filter(n => n.position !== 'admin');
                // const role = filterUsers.map(n => JSON.parse(n.access).map(n => n.value))
                // filterUsers.role = role;
                setUsers(filterUsers)
            })
    }, [])

    const toggle = () => {
        setModal(!modal);
    };

    // const handleUserClick = arg => {
    //     const user = arg;

    //     setUserList({
    //         id: user.id,
    //         name: user.name,
    //         designation: user.designation,
    //         email: user.email,
    //         tags: user.tags,
    //         projects: user.projects,
    //     });
    //     setIsEdit(true);

    //     toggle();
    // };

    const handleUserClick = arg => {
        const id = arg.id;
        props.history.push(`/viewuser/${id}`)
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    // const handleDeleteUser = user => {
    //     if (user.id !== undefined) {
    //         dispatch(onDeleteUser(user));
    //         onPaginationPageChange(1);
    //     }
    // };

    const handleDeleteUser = async (user) => {
        const id = user.id;
        await deleteUser(id)
            .then(async (resp) => {
                await getUsers()
                    .then(respo => {
                        console.log("response", respo)
                        const filterUsers = respo.filter(n => n.position !== 'admin');
                        setUsers(filterUsers)
                        props.history.push(`/users`)
                    })
            })
            .catch(err => console.log("Err", err))
    };

    /**
     * Handling submit user on user form
     */
    const handleValidUserSubmit = (e, values) => {
        if (isEdit) {
            const updateUser = {
                id: userList.id,
                name: values.name,
                designation: values.designation,
                tags: values.tags,
                email: values.email,
                projects: values.projects,
            };

            // update user
            dispatch(onUpdateUser(updateUser));
            setIsEdit(false);
        } else {
            const newUser = {
                id: Math.floor(Math.random() * (30 - 20)) + 20,
                name: values["name"],
                designation: values["designation"],
                email: values["email"],
                tags: values["tags"],
                projects: values["projects"],
            };
            // save new user
            dispatch(onAddNewUser(newUser));
        }
        toggle();
    };

    const handleNewPanel = () => {
        history.push('/usercreate')
    }

    const handleUserClicks = () => {
        setUserList("");
        setIsEdit(false);
        toggle();
    };

    const keyField = "id";

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Users | Aviation</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Access Level Control" breadcrumbItem="User List" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField={keyField}
                                        columns={contactListColumns}
                                        data={users}
                                    >
                                        {({ paginationProps, paginationTableProps }) => {
                                            return (
                                                <ToolkitProvider
                                                    keyField={keyField}
                                                    data={users}
                                                    columns={contactListColumns}
                                                    bootstrap4
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col sm="4">
                                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="8">
                                                                    <div className="text-sm-end">
                                                                        <Button
                                                                            color="primary"
                                                                            className="font-16 btn-block btn btn-primary"
                                                                            onClick={handleNewPanel}

                                                                        >
                                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                                            Create New User
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={keyField}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            selectRow={selectRow}
                                                                            defaultSorted={defaultSorted}
                                                                            classes={
                                                                                "table align-middle table-nowrap table-hover"
                                                                            }
                                                                            bordered={false}
                                                                            striped={false}
                                                                            responsive
                                                                            ref={node}
                                                                        />

                                                                        <Modal isOpen={modal} toggle={toggle}>
                                                                            <ModalHeader toggle={toggle} tag="h4">
                                                                                {!!isEdit ? "Edit User" : "Add User"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <AvForm
                                                                                    onValidSubmit={handleValidUserSubmit}
                                                                                >
                                                                                    <Row form>
                                                                                        <Col xs={12}>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="aircraft_model"
                                                                                                    label="Aircraft model"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid service class"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.service_class || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_class"
                                                                                                    label="Service class"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Designation"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={
                                                                                                        userList.designation || ""
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_name"
                                                                                                    label="service name"
                                                                                                    type="select"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_name"
                                                                                                    label="service name (confirm)"
                                                                                                    type="text"
                                                                                                    errorMessage="Invalid Email"
                                                                                                    validate={{
                                                                                                        required: { value: true },
                                                                                                    }}
                                                                                                    value={userList.email || ""}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    type="select"
                                                                                                    name="select"
                                                                                                    className="form-select"
                                                                                                    label="service1"
                                                                                                    helpMessage="MULTIPLE!"
                                                                                                    multiple={true}
                                                                                                    required
                                                                                                    value={userList.tags || ""}
                                                                                                >
                                                                                                    <option></option>
                                                                                                    <option>service1</option>
                                                                                                    <option>service2</option>
                                                                                                    <option>service3</option>

                                                                                                </AvField>
                                                                                            </div>
                                                                                            <div className="mb-3">
                                                                                                <AvField
                                                                                                    name="service_desc"
                                                                                                    label="decription"

                                                                                                    type="textarea"
                                                                                                    id="textarea"

                                                                                                    maxLength="225"
                                                                                                    rows="3"
                                                                                                    placeholder="This textarea has a limit of 225 chars."



                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-end">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                >
                                                                                                    Save
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AvForm>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            );
                                        }}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
Users.propTypes = {
    history: PropTypes.object,
}

export default withRouter(Users);

