import * as api from './index.js';
import axios from 'axios';
// const url = 'http://localhost:6005/api';
const url = 'https://aerotrend.erp.nu-pie.com/api';


export const loginUsers = async (form) => {
  try {
    const data = await axios.post(url + '/login_user', form)
    const resp = data.data.user;
    if (resp) {
      localStorage.setItem("authUser", JSON.stringify(resp));
      return resp;
    }
  }
  catch (err) {
    console.log("err", err)
  }
  // await axios.post(url + '/login_user', form)
  //   .then(response => {
  //     const result = response.data.user
  //     console.log("result", result)
  //     console.log("loginRespData", response.data)
  //     // if (response.status >= 200 || response.status <= 299)
  //     localStorage.setItem("authUser", JSON.stringify(result));
  //     return result;
  //   })
  //   .catch(err => {
  //     const message = {}
  //     message.error = "Invalid Credentials";
  //     return message;
  //   })
}

export const postUsers = async (form) => {
  axios.post(url + '/create_user', form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      const message = {}
      message.error = err;
      return message;
    })
};

export const editUsers = async (form, id) => {
  axios.patch(`${url + '/update_user'}/${id}`, form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const deleteUser = async (id) => {
  axios.delete(`${url + '/delete_user'}/${id}`)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const getUsers = async () => {
  try {
    const data = await fetch(url + '/users');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

export const postGone = async (form) => {
  axios.post(url + '/create_gone', form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      const message = {}
      message.error = err;
      return message;
    })
};

export const getGone = async () => {
  try {
    const data = await fetch(url + '/gone');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

export const editGone = async (form, id) => {
  axios.patch(`${url + '/update_gone'}/${id}`, form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const deleteGone = async (id) => {
  axios.delete(`${url + '/delete_gone'}/${id}`)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const editGoneStatus = async (form, id) => {
  axios.patch(`${url + '/update_gone_status'}/${id}`, form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const postGtwo = async (form) => {
  axios.post(url + '/create_gtwo', form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      const message = {}
      message.error = err;
      return message;
    })
};

export const postBulkGtwo = async (form) => {
  axios.post(url + '/create_bulk_gtwo', form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      const message = {}
      message.error = err;
      return message;
    })
};

export const getGtwo = async () => {
  try {
    const data = await fetch(url + '/gtwo');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

export const editGtwo = async (form, id) => {
  axios.patch(`${url + '/update_gtwo'}/${id}`, form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}


export const deleteGtwo = async (id) => {
  axios.delete(`${url + '/delete_gtwo'}/${id}`)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const postGthree = async (form) => {
  axios.post(url + '/create_gthree', form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      const message = {}
      message.error = err;
      return message;
    })
};

export const getGthree = async () => {
  try {
    const data = await fetch(url + '/gthree');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

export const editGthree = async (form, id) => {
  axios.patch(`${url + '/update_gthree'}/${id}`, form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const deleteGthree = async (id) => {
  axios.delete(`${url + '/delete_gthree'}/${id}`)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

//Forecast
export const postForecast = async (form) => {
  axios.post(url + '/create_forecast', form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      const message = {}
      message.error = err;
      return message;
    })
};

export const getForecast = async () => {
  try {
    const data = await fetch(url + '/forecast');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

//Scheduler

export const postScheduler = async (form) => {
  axios.post(url + '/create_scheduler', form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      const message = {}
      message.error = err;
      return message;
    })
};

export const getScheduler = async () => {
  try {
    const data = await fetch(url + '/scheduler');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

export const deleteScheduler = async (id) => {
  axios.delete(`${url + '/delete_scheduler'}/${id}`)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const uploadJourneyFile = async (file) => {
  try {
    const resp = await axios.post(url + '/upload_journey', file)
    if (resp) {
      const result = resp.data;
      return result;
    }
  }
  catch (err) {
    console.log("err", err)
  }
};

export const getTempJourney = async () => {
  try {
    const data = await fetch(url + '/temp_journey');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

export const getJourney = async () => {
  try {
    const data = await fetch(url + '/journey');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

export const postJourney = async (form) => {
  try {
    const resp = await axios.post(url + '/create_journey', form)
    if (resp) {
      const result = resp.data;
      return result;
    }
  }
  catch (err) {
    console.log("err", err)
  }
};

export const editJourney = async (form, id) => {
  axios.patch(`${url + '/update_journey'}/${id}`, form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const deleteJourney = async (id) => {
  axios.delete(`${url + '/delete_journey'}/${id}`)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}

export const updateNextDue = async (form) => {
  try {
    const resp = await axios.post(url + '/update_next_due', form)
    if (resp) {
      const result = resp.data;
      return result;
    }
  }
  catch (err) {
    console.log("err", err)
  }
};

export const getJourneyLogs = async () => {
  try {
    const data = await fetch(url + '/journey_logs');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

export const uploadTaskFile = async (file) => {
  try {
    const resp = await axios.post(url + '/upload_task', file)
    if (resp) {
      const result = resp.data;
      return result;
    }
  }
  catch (err) {
    console.log("err", err)
  }
};

//OCCM Panel
export const postOccm = async (form) => {
  axios.post(url + '/create_occm', form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      const message = {}
      message.error = err;
      return message;
    })
};

export const getOccm = async () => {
  try {
    const data = await fetch(url + '/occm');
    const res = await data.json();
    return res;
  } catch (error) {
    console.log("Error data", error.message || error);
  }
};

export const editOccm = async (form, id) => {
  axios.patch(`${url + '/update_occm'}/${id}`, form)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}


export const deleteOccm = async (id) => {
  axios.delete(`${url + '/delete_occm'}/${id}`)
    .then(resp => {
      const result = resp.data;
      return result
    })
    .catch(err => {
      console.log("err", err)
    })
}