import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Button,
    ModalHeader,
    ModalBody,
    Table, Alert, Progress, Form, Input
} from "reactstrap";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { getGone, getGthree, postForecast, getForecast, getJourney, updateNextDue, getGtwo, getJourneyLogs, uploadTaskFile, getScheduler } from "actions/api";
import images from "assets/images";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import filterFactory, { dateFilter, textFilter, Comparator } from 'react-bootstrap-table2-filter';
import * as moment from "moment";
import Editable from "react-bootstrap-editable"

const { ExportCSVButton } = CSVExport;
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
    getUsers as onGetUsers,
    addNewUser as onAddNewUser,
    updateUser as onUpdateUser,
    deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty, size, map } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";
// import { isSubmitting } from "redux-form";

const Forecast = props => {
    const { history } = props;
    const { match: { params } } = props;
    const id = parseInt(params.id);
    // console.log("id", id)
    const dispatch = useDispatch();
    const [gthree, setGthree] = useState([])
    const [forecast, setForecast] = useState([])
    const [aircraftDetails, setAircraftDetails] = useState({});
    const [form, setForm] = useState({});
    const [avg_hours, setAvgHours] = useState(null)
    const [avg_cycles, setAvgCycles] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(true);
    // console.log("isSubmitting", isSubmitting)
    const [taskFile, setTaskFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [enableDetails, setEnableDetails] = useState(false)
    const [error, setError] = useState(null)

    const confirmElement = (
        <button type="submit" className="btn btn-success editable-submit btn-sm me-1"><i className="mdi mdi-check"></i></button>
    );

    /** Cancel button */
    const cancelElement = (
        <button type="button" className="btn btn-danger editable-cancel btn-sm"><i className="mdi mdi-close"></i></button>
    );

    const { users } = useSelector(state => ({
        users: state.contacts.users,
    }));


    const [userList, setUserList] = useState([]);
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const { SearchBar } = Search;
    const sizePerPage = 10;
    const pageOptions = {
        sizePerPage: sizePerPage,
        totalSize: gthree.length, // replace later with size(users),
        custom: true,
    };
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "asc", // desc or asc
        },
    ];

    const [selectedIds, setSelectIds] = useState([])
    // console.log("selectedIds", selectedIds)
    const filterG3 = gthree.filter(x => selectedIds.map(x => parseInt(x)).includes(x.id))
    const [enablePrint, setEnablePrint] = useState(false);
    // console.log("filterG3", filterG3)

    useEffect(() => {
        if (filterG3.length > 0) {
            const abcDate = filterG3[0].next_date
            const bbb = filterG3.filter(x => new Date(x.next_date).setHours(0, 0, 0, 0) == new Date(abcDate).setHours(0, 0, 0, 0))
            if (abcDate && filterG3.length === bbb.length) {
                setEnablePrint(true)
            } else {
                setEnablePrint(false)
            }
        } else {
            setEnablePrint(false)
        }
    }, [filterG3])

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        //selected: selectedIds.selected, selected:[2]
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setSelectIds([...selectedIds, row.id])
                return true;
            } else {
                setSelectIds(selectedIds?.filter(x => x !== row.id))
            }
        },
        onSelectAll: (isSelect, row) => {
            const ids = row.map(r => r.id);
            if (isSelect) {
                setSelectIds(ids)
            } else {
                setSelectIds([])
            }
        }
    };

    const handleValidDate = date => {
        // console.log("date", date)
        const date1 = moment(new Date(date)).format("DD MMM Y");
        // const date1 = moment(new Date(date)).format("DD-MM-YYYY");
        return date1;
    };

    const contactListColumns = [
        {
            text: "id",
            // dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: user => <>{user.id}</>,
            csvExport: false
        },
        {
            dataField: "id",
            text: "#",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <>
                    {!user.img ? (
                        <div className="avatar-xs">
                            <span className="avatar-title rounded-circle">
                                {/* {user.name.charAt(0)} */}
                                {user.id}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle avatar-xs"
                                src={images[user.img]}
                                alt=""
                            />
                        </div>
                    )}
                </>
            ),
            csvExport: false
        },
        {
            dataField: "mpd",
            text: "MPD Task ID",
            sort: true,
            // filter: textFilter()
        },
        {
            dataField: "task_ref",
            text: "Task Card Ref ID",
            sort: true,
            // filter: textFilter()
        },
        {
            dataField: "service_class",
            text: "Task",
            sort: true,
            // filter: textFilter()
        },
        {
            dataField: "service_desc",
            text: "Task Description",
            hidden: true,
        },
        {
            dataField: "interval_thresh",
            text: "Interval Threshold",
            sort: true,
            formatter: (cell, row) => {
                const threshFormat = row.thresh_format === 'Hours' ? 'FH' : 'FC';
                return <div>{`${row.interval_thresh} ${threshFormat}`}</div>;
            },
            hidden: true
        },
        {
            dataField: "interval_repeat",
            text: "Interval Repeat",
            sort: true,
            formatter: (cell, row) => {
                const repeatFormat = row.repeat_format === 'Hours' ? 'FH' : 'FC';
                return <div>{`${row.interval_repeat} ${repeatFormat}`}</div>;
            },
            hidden: true
        },
        {
            dataField: "last_date",
            text: "Last Carried",
            formatter: (cell, row) => {
                const dueDate = row.last_date ? handleValidDate(row.last_date) : null
                return <>{dueDate}</>;
            },
            hidden: true
        },
        {
            dataField: "last_hours",
            text: "Last Carried Hrs",
            hidden: true
        },
        {
            dataField: "last_cycles",
            text: "Last Carried Cycles",
            hidden: true
        },
        {
            dataField: "next_date",
            text: "Next Due",
            sort: true,
            formatter: (cell, row) => {
                const dueDate = row.next_date ? handleValidDate(row.next_date) : null
                // const dueDate = new Date(row?.next_date).toDateString()
                return <>{dueDate}</>;
            },
            // filter: dateFilter()
            // filter: dateFilter({
            //     defaultValue: { date: new Date(2018, 0, 1), comparator: Comparator.EQ }
            // })
        },
        {
            dataField: "next_hours",
            text: "Next Due Hours",
            hidden: true
        },
        {
            dataField: "next_cycles",
            text: "Next Due Cycles",
            hidden: true
        },
        {
            dataField: "remaining_date",
            text: "Remaining Date",
            formatter: (cell, row) => {
                const dueDate = row.remaining_date ? handleValidDate(row.remaining_date) : null
                return <>{dueDate}</>;
            },
            hidden: true
        },
        {
            dataField: "remaining_hours",
            text: "Rem Hours",
            sort: true,
        },
        {
            dataField: "remaining_cycles",
            text: "Rem Cycles",
            sort: true
        },
        {
            dataField: "est_price",
            text: "Estimated Price",
            hidden: true,
        },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "Update",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, user) => (
                <div className="d-flex gap-3">
                    <Link className="text-success" to="#">
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={() => handleUserClick(user)}
                        ></i>
                    </Link>
                    {/* <Link className="text-danger" to="#">
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={() => handleDeleteUser(user)}
                        ></i>
                    </Link> */}
                </div>
            ),
            csvExport: false
        },
    ];
    const expandRow = {
        onlyOneExpanding: true,
        renderer: row => (
            <>
                <div className="table-responsive">
                    <Table className="table table-sm table-bordered m-0">
                        <thead>
                            <tr>
                                <th colSpan="3" style={{ textAlign: "center" }}>Last Carried Out</th>
                                <th colSpan="3" style={{ textAlign: "center" }}>Next Due</th>
                                <th colSpan="3" style={{ textAlign: "center" }}>Remaining</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Date</th>
                                <th>Hours</th>
                                <th>Cycles</th>
                                <th>Date</th>
                                <th>Hours</th>
                                <th>Cycles</th>
                                <th>Date</th>
                                <th>Hours</th>
                                <th>Cycles</th>
                                <th>Interval Threshold</th>
                                <th>Interval Repeat</th>
                            </tr>
                            <tr>
                                <td>{handleValidDate(row.last_date)}</td>
                                <td>{row.last_hours}</td>
                                <td>{row.last_cycles}</td>

                                <td>{handleValidDate(row.next_date)}</td>
                                <td>{row.next_hours}</td>
                                <td>{row.next_cycles}</td>

                                <td>{ }</td>
                                <td>{row.remaining_hours}</td>
                                <td>{row.remaining_cycles}</td>

                                <td>
                                    {row.thresh_format === 'Hours' ? row.interval_thresh + 'FH' : row.interval_thresh + 'FC'}
                                </td>
                                <td>
                                    {row.repeat_format === 'Hours' ? row.interval_repeat + 'FH' : row.interval_repeat + 'FC'}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="table-responsive">
                    <Table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Zone</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{
                                    whiteSpace: '-moz-pre-wrap !important', whiteSpace: '-pre-wrap',
                                    whiteSpace: '-o-pre-wrap', whiteSpace: 'pre-wrap', wordWrap: 'break-word',
                                    whiteSpace: '-webkit-pre-wrap', wordBreak: 'break-all', whiteSpace: 'normal'
                                }}>
                                    {row.service_desc}
                                </td>
                                <td>{row?.zone}</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </>
        ),
        showExpandColumn: true,
    };

    // useEffect(() => {
    //     if (users && !users.length) {
    //         dispatch(onGetUsers());
    //         setIsEdit(false);
    //     }
    // }, [dispatch, users]);

    // useEffect(() => {
    //     setUserList(users);
    //     setIsEdit(false);
    // }, [users]);

    // useEffect(() => {
    //     if (!isEmpty(users) && !!isEdit) {
    //         setUserList(users);
    //         setIsEdit(false);
    //     }
    // }, [users]);

    // useEffect(() => {
    //     getForecast()
    //         .then(resp => {
    //             setForecast(resp)
    //         })
    // }, []);

    const [schedulerData, setSchedulerData] = useState(null)

    useEffect(() => {
        if (isSubmitting) {
            getGone()
                .then(resp => {
                    const foundGone = resp.find(x => x.id === parseInt(params.id))
                    const aircraftModel = foundGone && foundGone.aircraft_name;
                    const reg_id = foundGone && foundGone.reg_id;
                    const msn = foundGone && foundGone.msn;
                    let total_ac;
                    let total_journey;
                    let journeyLogs;
                    getJourney()
                        .then(resp2 => {
                            journeyLogs = resp2;
                            const aircraftCycles = (foundGone?.aircraft_cycles)
                            const aircraftHrs = (foundGone?.aircraft_hours).split('-');
                            const abc = aircraftHrs.map(x => parseInt(x))
                            const ac_hrs = abc[0] * 60;
                            const ac_min = abc[1];
                            total_ac = ac_hrs + ac_min
                            const aircraftName = foundGone?.aircraft_name;
                            const aircraftReg = foundGone?.reg_id;
                            const foundJourney = journeyLogs?.filter(x => x.aircraft_type === aircraftName)
                            // const journeyHour = foundJourney.reduce((a, b) => a + parseInt(b.current_fh_hr), 0) * 60;
                            // const journeyMin = foundJourney.reduce((a, b) => a + parseInt(b.current_fh_min), 0);
                            // const journeyCycles = foundJourney.reduce((a, b) => a + parseInt(b.current_fc), 0);
                            // total_journey = parseInt(journeyHour) + parseInt(journeyMin)
                            // const foundJourney = journeyLogs?.filter(x => x.registration === aircraftReg)
                            let journeyarr = [];
                            foundJourney.forEach((item, i) => {
                                const flightHours = (item.total_flight_hrs)?.split('-');
                                const abc = flightHours?.map(x => parseInt(x))
                                const fc_hrs = abc && abc[0] ? abc[0] * 60 : item.total_flight_hrs * 60
                                const fc_min = abc && abc[1] ? abc[1] : 0
                                journeyarr.push({
                                    ...item,
                                    journey_hr: fc_hrs,
                                    journey_min: fc_min,
                                })
                            })
                            const journeyHour = journeyarr.reduce((a, b) => a + parseInt(b.journey_hr), 0);
                            const journeyMin = journeyarr.reduce((a, b) => a + parseInt(b.journey_min), 0);
                            const journeyCycles = journeyarr.reduce((a, b) => a + parseInt(b.total_flight_cyc), 0);
                            total_journey = parseInt(journeyHour) + parseInt(journeyMin)

                            const totalFH = Math.floor((total_ac + total_journey) / 60);
                            const totalMin = Math.floor((total_ac + total_journey) % 60);
                            const totalFlightHours = totalFH + '-' + totalMin;
                            const addTotalFH = totalFlightHours ? totalFlightHours : filterGone?.aircraft_hours //total FH
                            const totalFlightCycles = parseInt(aircraftCycles) + journeyCycles;
                            setAircraftDetails({
                                aircraftModel: aircraftModel, reg_id: reg_id, msn: msn,
                                add_total_fh: addTotalFH, add_total_fc: totalFlightCycles
                            })
                        })
                    getGthree()
                        .then(resp2 => {
                            const filterGthree = resp2.filter(x => x.aircraft_model === aircraftModel)
                            getScheduler()
                                .then(resp5 => {
                                    const filterScheduler = resp5.filter(x => x.aircraft_type === aircraftModel)
                                    getGtwo()
                                        .then(resp4 => {
                                            const gTwoArr = resp4;
                                            getForecast()
                                                .then(resp3 => {
                                                    let forecastResp = resp3;
                                                    let arr = [];

                                                    filterGthree.map(n => {
                                                        const findForecast = (forecastResp || []).find(s => s.gthree_id === n.id && s.status === "true")
                                                        const totalAc = total_ac + total_journey
                                                        const last_carried = (parseInt(findForecast?.last_hours) + parseInt(findForecast?.interval_repeat)) * 60
                                                        const totalFlightHrs = Math.floor((last_carried - totalAc) / 60);
                                                        const totalFlightMin = Math.floor((last_carried - totalAc) % 60);
                                                        const totalRemHours = totalFlightHrs + '-' + totalFlightMin;
                                                        const remHours = findForecast?.thresh_format === 'Hours' ? totalRemHours : null;

                                                        const serAssigned = typeof n.service_assigned === 'string' ?
                                                            JSON.parse(n.service_assigned) : n.service_assigned;

                                                        // const estPrice = gTwoArr && gTwoArr.filter(x =>
                                                        //     ((typeof x?.aircraft_model === 'string' ?
                                                        //         (JSON.parse(x?.aircraft_model).map(n => n.value)) :
                                                        //         (x?.aircraft_model).map(n => n.value)).includes(aircraftModel))
                                                        //     && serAssigned.map(p => p.value).includes(x.comp_name)).reduce((a, b) => a + parseFloat(b?.est_price), 0)
                                                        //     + parseFloat(n.est_price);
                                                        const estPrice = gTwoArr && gTwoArr.filter(x =>
                                                            x?.aircraft_model !== null ? ((typeof x?.aircraft_model === 'string' ?
                                                                (JSON.parse(x?.aircraft_model).map(n => n.value)) :
                                                                (x?.aircraft_model).map(n => n.value)).includes(aircraftModel)) : null
                                                            && serAssigned.map(p => p.value).includes(x.comp_name)).reduce((a, b) => a + parseFloat(b?.est_price), 0)
                                                            + parseFloat(n.est_price);
                                                        // console.log("estPrice", estPrice)
                                                        n.est_price = estPrice;

                                                        let hsum = 0;
                                                        let csum = 0;
                                                        let schedulearr = [];
                                                        const serviceHours = n.repeat_format === 'Hours' ? ((n.interval_repeat) * 60) : (n.interval_repeat);
                                                        const repeatFormat = n.repeat_format;
                                                        const lastDateFilter = filterScheduler.filter(x => new Date(x.aircraft_date).setHours(0, 0, 0, 0) > new Date(findForecast?.last_date).setHours(0, 0, 0, 0))
                                                        // console.log("lastDateFilter", lastDateFilter)
                                                        lastDateFilter.forEach((p, i) => {
                                                            const foundJourney = journeyLogs.find(x =>
                                                                new Date(x.date).setHours(0, 0, 0, 0) === new Date(p.aircraft_date).setHours(0, 0, 0, 0) &&
                                                                x.aircraft_type === p.aircraft_type);
                                                            // console.log("foundJourney", foundJourney)
                                                            const actualHours = foundJourney && foundJourney.total_flight_hrs; //2-30
                                                            const actualJrnHrs = (actualHours)?.split('-');
                                                            const act = actualJrnHrs?.map(x => parseInt(x))
                                                            const act_hrs = act && act[0] ? act[0] * 60 : parseInt(actualHours) * 60
                                                            const act_min = act && act[1] ? act[1] : 0
                                                            const total_act = parseInt(act_hrs ? act_hrs : 0) + parseInt(act_min ? act_min : 0)

                                                            const scheduleJrnHrs = (p.aircraft_hours)?.split('-');
                                                            const sch = scheduleJrnHrs?.map(x => parseInt(x))
                                                            const sch_hrs = sch && sch[0] ? sch[0] * 60 : parseInt(p.aircraft_hours) * 60
                                                            const sch_min = sch && sch[1] ? sch[1] : 0
                                                            const total_sch = parseInt(sch_hrs ? sch_hrs : 0) + parseInt(sch_min ? sch_min : 0);
                                                            const actualCycles = foundJourney && foundJourney.total_flight_cyc;
                                                            const hoursConsider = actualHours ? total_act : total_sch
                                                            const cyclesConsider = actualCycles ? parseInt(actualCycles) : parseInt(p.aircraft_cycles);

                                                            hsum = hsum + parseInt(hoursConsider);
                                                            csum = csum + parseInt(cyclesConsider);

                                                            if (repeatFormat === 'Hours') {
                                                                schedulearr.push({
                                                                    ...p,
                                                                    actual_hrs: total_act,
                                                                    hours_consider: hoursConsider,
                                                                    cumulative: hsum,
                                                                    status: hsum > serviceHours ? true : false
                                                                })
                                                            } else {
                                                                // console.log("Else part working")
                                                                schedulearr.push({
                                                                    ...p,
                                                                    actual_cyc: actualCycles,
                                                                    cycles_consider: cyclesConsider,
                                                                    cumulative: csum,
                                                                    status: csum > serviceHours ? true : false
                                                                })
                                                            }
                                                        })
                                                        const falseArr = schedulearr?.filter(x => x.status === false)
                                                        const trueArr = schedulearr?.filter(x => x.status === true)

                                                        const aircraftDate = falseArr?.length > 0 ?
                                                            new Date(falseArr[falseArr?.length - 1].aircraft_date) : null

                                                        const nextDue = trueArr?.length > 0 ? aircraftDate : null;


                                                        const nextDueDate = !findForecast ? null :
                                                            (nextDue ? nextDue : new Date(findForecast.next_date))
                                                        console.log("schedulearr", schedulearr)
                                                        console.log("nextDueDate", nextDueDate)

                                                        arr.push({
                                                            ...n,
                                                            last_date: findForecast ? new Date(findForecast.last_date) : null,
                                                            last_hours: findForecast ? findForecast.last_hours : null,
                                                            last_cycles: findForecast ? findForecast.last_cycles : null,
                                                            // next_date: findForecast ? new Date(findForecast.next_date) : null,
                                                            next_date: nextDueDate,
                                                            next_hours: findForecast ? findForecast.next_hours : null,
                                                            next_cycles: findForecast ? findForecast.next_cycles : null,
                                                            remaining_date: findForecast ? new Date(findForecast.remaining_date) : null,
                                                            // remaining_hours: findForecast ? findForecast.remaining_hours : null,
                                                            remaining_hours: remHours ? remHours : null,
                                                            remaining_cycles: findForecast ? findForecast.remaining_cycles : null,
                                                            avg_hours: findForecast ? findForecast.avg_hours : null,
                                                            avg_cycles: findForecast ? findForecast.avg_cycles : null,
                                                            status: findForecast ? findForecast.status : null,
                                                        })
                                                    })
                                                    //setGthree(filterGthree)
                                                    setGthree(arr)
                                                    const avgTrue = arr.filter(x => x.status === "true");
                                                    setAvgHours(avgTrue[avgTrue.length - 1]?.avg_hours ? avgTrue[avgTrue.length - 1]?.avg_hours : '')
                                                    setAvgCycles(avgTrue[avgTrue.length - 1]?.avg_cycles ? avgTrue[avgTrue.length - 1]?.avg_cycles : '')
                                                    setIsSubmitting(false)
                                                })
                                        })
                                })
                        })
                })

        }

    }, [isSubmitting]);

    // useEffect(() => {
    //     if (gthree?.length > 0) {
    //         console.log("Inside gThreee", gthree)
    //         // let arr = [];
    //         getScheduler()
    //             .then(resp => {
    //                 const filterScheduler = resp.filter(x => x.aircraft_type === gthree[0]?.aircraft_model)
    //                 getJourney()
    //                     .then(resp2 => {
    //                         let sum = 0;
    //                         let schedulearr = [];
    //                         filterScheduler.forEach((n, i) => {
    //                             const foundJourney = resp2.find(x => new Date(x.date).getDate() === new Date(n.aircraft_date).getDate());
    //                             console.log("foundJourney", foundJourney)
    //                             const actualHours = foundJourney && foundJourney.total_flight_hrs;
    //                             console.log("actualHours", actualHours)
    //                             const hoursConsider = actualHours ? actualHours : n.aircraft_hours;
    //                             const abc = gthree.find(x => resp2.map(s => s.aircraft_type).includes(x.aircraft_model))
    //                             const serviceHours = 10;
    //                             sum = sum + parseInt(hoursConsider);
    //                             console.log("hoursConsider", hoursConsider)
    //                             // console.log("abc", abc)
    //                             console.log("sum", sum)
    //                             if (sum < serviceHours) {
    //                                 console.log("false")
    //                                 schedulearr.push({
    //                                     ...n,
    //                                     actual_hrs: actualHours,
    //                                     hours_consider: hoursConsider,
    //                                     cumulative: sum,
    //                                     status: false
    //                                 })
    //                             } else {
    //                                 console.log("true")
    //                             }
    //                         })
    //                         console.log("arraaay", arr)
    //                         console.log("nextDue", arr[arr.length - 1])

    //                     })
    //             })

    //     }
    // }, [gthree])

    // useEffect(() => {
    //     if (isSubmitting) {
    //         getGone()
    //             .then(resp => {
    //                 const foundGone = resp.find(x => x.id === parseInt(params.id))
    //                 const aircraftModel = foundGone && foundGone.aircraft_name;
    //                 const reg_id = foundGone && foundGone.reg_id;
    //                 const msn = foundGone && foundGone.msn;
    //                 let total_ac;
    //                 let total_journey;
    //                 getJourney()
    //                     .then(resp2 => {
    //                         const journeyLogs = resp2;
    //                         const aircraftCycles = (foundGone?.aircraft_cycles)
    //                         const aircraftHrs = (foundGone?.aircraft_hours).split('-');
    //                         const abc = aircraftHrs.map(x => parseInt(x))
    //                         const ac_hrs = abc[0] * 60;
    //                         const ac_min = abc[1];
    //                         total_ac = ac_hrs + ac_min
    //                         const aircraftName = foundGone?.aircraft_name;
    //                         const aircraftReg = foundGone?.reg_id;
    //                         const foundJourney = journeyLogs?.filter(x => x.aircraft_type === aircraftName)
    //                         let journeyarr = [];
    //                         foundJourney.forEach((item, i) => {
    //                             const flightHours = (item.total_flight_hrs)?.split('-');
    //                             const abc = flightHours?.map(x => parseInt(x))
    //                             const fc_hrs = abc && abc[0] ? abc[0] * 60 : item.total_flight_hrs
    //                             const fc_min = abc && abc[1] ? abc[1] : 0
    //                             journeyarr.push({
    //                                 ...item,
    //                                 journey_hr: fc_hrs,
    //                                 journey_min: fc_min,
    //                             })
    //                         })
    //                         const journeyHour = journeyarr.reduce((a, b) => a + parseInt(b.journey_hr), 0);
    //                         const journeyMin = journeyarr.reduce((a, b) => a + parseInt(b.journey_min), 0);
    //                         const journeyCycles = journeyarr.reduce((a, b) => a + parseInt(b.total_flight_cyc), 0);
    //                         total_journey = parseInt(journeyHour) + parseInt(journeyMin)

    //                         const totalFH = Math.floor((total_ac + total_journey) / 60);
    //                         const totalMin = Math.floor((total_ac + total_journey) % 60);
    //                         const totalFlightHours = totalFH + '-' + totalMin;
    //                         const addTotalFH = totalFlightHours ? totalFlightHours : filterGone?.aircraft_hours //total FH
    //                         const totalFlightCycles = parseInt(aircraftCycles) + journeyCycles;
    //                         setAircraftDetails({
    //                             aircraftModel: aircraftModel, reg_id: reg_id, msn: msn,
    //                             add_total_fh: addTotalFH, add_total_fc: totalFlightCycles
    //                         })
    //                     })
    //                 getGthree()
    //                     .then(resp2 => {
    //                         const filterGthree = resp2.filter(x => x.aircraft_model === aircraftModel)
    //                         getGtwo()
    //                             .then(resp4 => {
    //                                 const gTwoArr = resp4;
    //                                 getForecast()
    //                                     .then(resp3 => {
    //                                         let forecastResp = resp3;
    //                                         let arr = [];
    //                                         filterGthree.map(n => {
    //                                             const findForecast = (forecastResp || []).find(s => s.gthree_id === n.id && s.status === "true")
    //                                             const totalAc = total_ac + total_journey
    //                                             const last_carried = (parseInt(findForecast?.last_hours) + parseInt(findForecast?.interval_repeat)) * 60
    //                                             const totalFlightHrs = Math.floor((last_carried - totalAc) / 60);
    //                                             const totalFlightMin = Math.floor((last_carried - totalAc) % 60);
    //                                             const totalRemHours = totalFlightHrs + '-' + totalFlightMin;
    //                                             const remHours = findForecast?.thresh_format === 'Hours' ? totalRemHours : null;

    //                                             const serAssigned = typeof n.service_assigned === 'string' ?
    //                                                 JSON.parse(n.service_assigned) : n.service_assigned;


    //                                             const estPrice = gTwoArr?.filter(x =>
    //                                                 ((typeof x.aircraft_model === 'string' ?
    //                                                     (JSON.parse(x.aircraft_model).map(n => n.value)) :
    //                                                     (x.aircraft_model).map(n => n.value)).includes(aircraftModel))
    //                                                 && serAssigned.map(p => p.value).includes(x.comp_name)).reduce((a, b) => a + parseFloat(b.est_price), 0)
    //                                                 + parseFloat(n.est_price);
    //                                             console.log("estPrice", estPrice)
    //                                             n.est_price = estPrice;
    //                                             arr.push({
    //                                                 ...n,
    //                                                 last_date: findForecast ? new Date(findForecast.last_date) : null,
    //                                                 last_hours: findForecast ? findForecast.last_hours : null,
    //                                                 last_cycles: findForecast ? findForecast.last_cycles : null,
    //                                                 next_date: findForecast ? new Date(findForecast.next_date) : null,
    //                                                 next_hours: findForecast ? findForecast.next_hours : null,
    //                                                 next_cycles: findForecast ? findForecast.next_cycles : null,
    //                                                 remaining_date: findForecast ? new Date(findForecast.remaining_date) : null,

    //                                                 remaining_hours: remHours ? remHours : null,
    //                                                 remaining_cycles: findForecast ? findForecast.remaining_cycles : null,
    //                                                 avg_hours: findForecast ? findForecast.avg_hours : null,
    //                                                 avg_cycles: findForecast ? findForecast.avg_cycles : null,
    //                                                 status: findForecast ? findForecast.status : null,
    //                                             })
    //                                         })
    //                                         setGthree(arr)
    //                                         const avgTrue = arr.filter(x => x.status === "true");
    //                                         setAvgHours(avgTrue[avgTrue.length - 1]?.avg_hours ? avgTrue[avgTrue.length - 1]?.avg_hours : '')
    //                                         setAvgCycles(avgTrue[avgTrue.length - 1]?.avg_cycles ? avgTrue[avgTrue.length - 1]?.avg_cycles : '')
    //                                         setIsSubmitting(false)
    //                                     })
    //                             })
    //                     })
    //             })

    //     }

    // }, [isSubmitting]);

    function handleChange(e) {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    function calculateAvgHours(value) {
        const aircraftHrs = (aircraftDetails.add_total_fh).split('-');
        const abc = aircraftHrs.map(x => parseInt(x))
        const ac_hrs = abc[0] * 60;
        const ac_min = abc[1];
        const total_ac = ac_hrs + ac_min
        gthree.forEach((n, i) => {
            const last_carried = (parseInt(n.last_hours) + parseInt(n.interval_repeat)) * 60
            const totalFH = Math.floor((last_carried - total_ac) / 60);
            const totalMin = Math.floor((last_carried - total_ac) % 60);
            const totalRemHours = totalFH + '-' + totalMin;
            const remHours = n.thresh_format === 'Hours' ? totalRemHours : null;
            const numDays = remHours ? Math.floor(totalFH / value) : null;
            const nextDue = new Date();
            nextDue.setDate(nextDue.getDate() + numDays);
            const data = {
                gthree_id: n.id,
                next_date: nextDue,
                avg_hours: value,
                thresh_format: n.thresh_format
            }
            updateNextDue(data)
        })
    }

    function handleAvgChange(value) {
        if (value) {
            calculateAvgHours(value)
            setAvgHours(value)
            setIsSubmitting(true)
        }
    }

    function calculateAvgCycles(value) {
        const aircraftCycles = (aircraftDetails.add_total_fc)
        gthree.forEach((n, i) => {
            const last_carried = parseInt(n.last_cycles) + parseInt(n.interval_repeat)
            const totalRemCycles = last_carried - parseInt(aircraftCycles)
            const remHours = n.thresh_format === 'Cycles' ? totalRemCycles : null;
            const numDays = remHours ? Math.floor(totalRemCycles / value) : null;
            const nextDue = new Date();
            nextDue.setDate(nextDue.getDate() + numDays);
            const data = {
                gthree_id: n.id,
                next_date: nextDue,
                avg_cycles: value,
                thresh_format: n.thresh_format
            }
            updateNextDue(data)
        })
    }

    function handleAvgCycleChange(value) {
        if (value) {
            calculateAvgCycles(value)
            setAvgCycles(value)
            setIsSubmitting(true)
        }
    }

    // function handleAvgChange(e) {
    //     const value = e.target.value;
    //     calculateAvgHours(value)
    //     setAvgHours(e.target.value)
    // }

    const toggle = () => {
        setModal(!modal);
        setTaskFile(null)
        setEnableDetails(false)
        setLoading(false)
    };

    // const handleUserClick = arg => {
    //     const user = arg;

    //     setUserList({
    //         id: user.id,
    //         name: user.name,
    //         designation: user.designation,
    //         email: user.email,
    //         tags: user.tags,
    //         projects: user.projects,
    //     });
    //     setIsEdit(true);

    //     toggle();
    // };

    const handleUserClick = arg => {
        setForm({
            gthree_id: arg.id,
            aircraft_model: arg.aircraft_model,
            service_name: arg.service_name,
            mpd: arg.mpd,
            amm: arg.amm,
            task_ref: arg.task_ref,
            service_assigned: arg.service_assigned,
            service_desc: arg.service_desc,
            interval_thresh: arg.interval_thresh,
            thresh_format: arg.thresh_format,
            interval_repeat: arg.interval_repeat,
            repeat_format: arg.repeat_format
        })
        setIsEdit(true);
        toggle();
    }

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };

    const handleDeleteUser = user => {
        if (user.id !== undefined) {
            dispatch(onDeleteUser(user));
            onPaginationPageChange(1);
        }
    };

    /**
     * Handling submit user on user form
     */
    const handleValidUserSubmit = (e, values) => {
        if (isEdit) {
            const updateUser = {
                id: userList.id,
                name: values.name,
                designation: values.designation,
                tags: values.tags,
                email: values.email,
                projects: values.projects,
            };

            // update user
            dispatch(onUpdateUser(updateUser));
            setIsEdit(false);
        } else {
            const newUser = {
                id: Math.floor(Math.random() * (30 - 20)) + 20,
                name: values["name"],
                designation: values["designation"],
                email: values["email"],
                tags: values["tags"],
                projects: values["projects"],
            };
            // save new user
            dispatch(onAddNewUser(newUser));
        }
        toggle();
    };

    const handleNewPanel = () => {
        history.push('/panelg3form')
    }

    const handleUserClicks = () => {
        setUserList("");
        setIsEdit(false);
        toggle();
    };


    const handleSubmit = () => {
        // const acTSN = 7000; //aircraftDetails.add_total_fh
        const aircraftHrs = (aircraftDetails.add_total_fh).split('-');
        const abc = aircraftHrs.map(x => parseInt(x))
        const ac_hrs = abc[0] * 60;
        const ac_min = abc[1];
        const total_ac = ac_hrs + ac_min
        const last_carried = (parseInt(form.last_hours) + parseInt(form.interval_repeat)) * 60
        const totalFH = Math.floor((last_carried - total_ac) / 60);
        const totalMin = Math.floor((last_carried - total_ac) % 60);
        const totalRemHours = totalFH + '-' + totalMin;
        const remHours = form.thresh_format === 'Hours' ? totalRemHours : null;
        const aircraftCycles = parseInt(aircraftDetails.add_total_fc);
        const last_carried_cycles = (parseInt(form.last_cycles) + parseInt(form.interval_repeat))
        const remCycles = last_carried_cycles - aircraftCycles;
        const avgHours = avg_hours ? avg_hours : 10;
        const avgCycles = avg_cycles ? avg_cycles : 2;
        const numDays = remHours ? Math.floor(totalFH / avgHours) : remCycles ? Math.floor(remCycles / avgCycles) : null;
        const nextDue = new Date();
        nextDue.setDate(nextDue.getDate() + numDays);
        const data = {
            gthree_id: form.gthree_id,
            aircraft_model: form.aircraft_model,
            service_name: form.service_name,
            mpd: form.mpd,
            amm: form.amm,
            task_ref: form.task_ref,
            service_desc: form.service_desc,
            interval_thresh: form.interval_thresh,
            thresh_format: form.thresh_format,
            interval_repeat: form.interval_repeat,
            repeat_format: form.repeat_format,
            last_date: form.last_date,
            last_hours: form.last_hours,
            last_cycles: form.last_cycles,
            next_date: nextDue,
            next_hours: form.thresh_format === 'Hours' ?
                (parseInt(form.last_hours) + parseInt(form.interval_repeat)) : null,
            next_cycles: form.thresh_format === 'Cycles' ?
                (parseInt(form.last_cycles) + parseInt(form.interval_repeat)) : null,
            remaining_date: new Date(),
            remaining_hours: form.thresh_format === 'Hours' ? remHours : null,
            remaining_cycles: form.thresh_format === 'Cycles' ? remCycles : null,
            avg_hours: avgHours,
            avg_cycles: avgCycles,
            status: "true"
        }
        console.log("data", data)
        postForecast(data)
            .then(resp => {
                getGthree()
                    .then(resp2 => {
                        const filterGthree = resp2.filter(x => x.aircraft_model === (aircraftDetails.aircraftModel))
                        getForecast()
                            .then(resp3 => {
                                let forecastResp = resp3;
                                let arr = [];
                                filterGthree.map(n => {
                                    const findForecast = (forecastResp || []).find(s => s.gthree_id === n.id && s.status === "true")
                                    arr.push({
                                        ...n,
                                        last_date: findForecast ? findForecast.last_date : null,
                                        last_hours: findForecast ? findForecast.last_hours : null,
                                        last_cycles: findForecast ? findForecast.last_cycles : null,
                                        next_date: findForecast ? findForecast.next_date : null,
                                        next_hours: findForecast ? findForecast.next_hours : null,
                                        next_cycles: findForecast ? findForecast.next_cycles : null,
                                        remaining_date: findForecast ? findForecast.remaining_date : null,
                                        remaining_hours: findForecast ? findForecast.remaining_hours : null,
                                        remaining_cycles: findForecast ? findForecast.remaining_cycles : null,
                                        avg_hours: findForecast ? findForecast.avg_hours : null,
                                        avg_cycles: findForecast ? findForecast.avg_cycles : null,
                                        status: findForecast ? findForecast.status : null,
                                    })
                                })
                                //setGthree(filterGthree)
                                setGthree(arr)
                                setIsSubmitting(true)
                                setIsEdit(true);
                                toggle();
                            })
                        //setGthree(resp2)

                    })
            })
            .catch(err => console.log("err", err))
    }

    const keyField = "id";

    const printTaskCard = () => {
        //window.print()
        history.push('/print-forecast', { selectedIds: selectedIds })
    }

    const handleGetJourneyLogs = async () => {
        //1. Upload the task card, and from the script fetch the date
        //2. After finding the date, check "Journey Logs" and find the FH and FC
        //3. Then set the last_date, last_hours and last_cycles to the form
        //4. Call the handleSubmit fuction to calculate remaining hours as usual
        setLoading(true)
        const formData = new FormData();
        formData.append("file", taskFile)
        // const fileType = "Journey Log";
        try {
            const resp = await uploadTaskFile(formData)
            if (resp && resp[0] !== 'error') {
                setEnableDetails(true)
                var respData = resp;
                var dd = respData[0];
                var mm = respData[1];
                var yyyy = "20" + respData[2];
                const date = new Date(yyyy, parseInt(mm - 1), dd);
                getJourneyLogs()
                    .then(resp2 => {
                        const logs = resp2.find(x => new Date(x.aircraft_date).getTime() === date.getTime())
                        setForm({ ...form, last_date: date, last_hours: logs && logs.aircraft_hours, last_cycles: logs && logs.aircraft_cycles })
                        // handleSubmit()
                        setLoading(false)
                    })
            } else {
                setError("Something went wrong")
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    function handleDateChange(date) {
        getJourneyLogs()
            .then(resp2 => {
                const logs = resp2.find(x => new Date(x.aircraft_date).getTime() === date.getTime())
                setForm({ ...form, last_date: date, last_hours: logs && logs.aircraft_hours, last_cycles: logs && logs.aircraft_cycles })
                // handleSubmit()
            })
    }

    function previewImage() {
        var file = document.getElementById("file").files;
        setTaskFile(file[0])
        if (file.length > 0) {
            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                document.getElementById("preview").setAttribute("src", event.target.result);
            };
            fileReader.readAsDataURL(file[0])
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Fleet Configuration | Forecast</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Tier 4" breadcrumbItem="Forecast Panel" />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField={keyField}
                                        columns={contactListColumns}
                                        data={gthree}
                                        expandRow={expandRow}
                                    >
                                        {({ paginationProps, paginationTableProps }) => {
                                            return (
                                                <ToolkitProvider
                                                    keyField={keyField}
                                                    data={gthree}
                                                    columns={contactListColumns}
                                                    expandRow={expandRow}
                                                    bootstrap4
                                                    search
                                                    exportCSV={{ fileName: 'Forecast.csv' }}
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col sm="4" md="4" lg="4">
                                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar {...toolkitProps.searchProps} />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="ms-2">
                                                                        <Link to={"/powerbi"} style={{ width: '200px' }}
                                                                            className="btn btn-info me-2">
                                                                            <i className="fa fa-chart-line" />{' '}
                                                                            Dashboard
                                                                        </Link>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="6" md="6" lg="6">
                                                                    <div className="table-responsive">
                                                                        <Table className="table table-bordered mb-0">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th>Aircraft Model</th>
                                                                                    <td>{aircraftDetails.aircraftModel}</td>
                                                                                    <th>MSN</th>
                                                                                    <td>{aircraftDetails.msn}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Total FH</th>
                                                                                    <td>{aircraftDetails.add_total_fh}</td>
                                                                                    <th>Total FC</th>
                                                                                    <td>{aircraftDetails.add_total_fc}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    {/* <th scope="row">Reg ID</th>
                                                                                    <td>{aircraftDetails.reg_id}</td> */}
                                                                                    <th>Avg FH</th>
                                                                                    <td>
                                                                                        <Editable
                                                                                            alwaysEditing={false}
                                                                                            disabled={false}
                                                                                            editText={avg_hours ? avg_hours : 10}
                                                                                            id={null}
                                                                                            isValueClickable={false}
                                                                                            mode="popover"
                                                                                            placement="bottom"
                                                                                            showText
                                                                                            type="textfield"
                                                                                            onSubmit={(value) => handleAvgChange(value)}
                                                                                            renderConfirmElement={confirmElement}
                                                                                            renderCancelElement={cancelElement}
                                                                                        />
                                                                                    </td>
                                                                                    <th>Avg FC</th>
                                                                                    {/* <td colSpan={2}>
                                                                                        <input type="number" name="avg_hours"
                                                                                            value={avg_hours} onChange={(e) => handleAvgChange(e)}
                                                                                        />
                                                                                    </td> */}
                                                                                    <td>
                                                                                        <Editable
                                                                                            alwaysEditing={false}
                                                                                            disabled={false}
                                                                                            editText={avg_cycles ? avg_cycles : 2}
                                                                                            id={null}
                                                                                            isValueClickable={false}
                                                                                            mode="popover"
                                                                                            placement="bottom"
                                                                                            showText
                                                                                            type="textfield"
                                                                                            onSubmit={(value) => handleAvgCycleChange(value)}
                                                                                            renderConfirmElement={confirmElement}
                                                                                            renderCancelElement={cancelElement}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                    {/* <div className="text-sm-end">
                                                                        <Button
                                                                            color="primary"
                                                                            className="font-16 btn-block btn btn-primary"
                                                                            onClick={handleNewPanel}

                                                                          onClick={() =>
                                                                            history.push(
                                                                              `/ecommerce-product-detail/${product.id}`
                                                                            )
                                                                          }
                                                                          onClick={handleUserClicks}
                                                                        >
                                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                                            Create New
                                                                        </Button>
                                                                    </div> */}
                                                                </Col>
                                                                <Col sm="2" md="2" lg="2">
                                                                    <div className="float-end">
                                                                        {enablePrint ?
                                                                            <Link to={"/print-forecast/" + selectedIds}
                                                                                className="btn btn-success me-2"
                                                                                style={{ width: '150px' }}>
                                                                                <i className="fa fa-print" />{' '}
                                                                                Print Task Card
                                                                            </Link>
                                                                            :
                                                                            <Link to="#" style={{ width: '150px' }}
                                                                                className="btn btn-secondary disabled me-2">
                                                                                <i className="fa fa-print" />{' '}
                                                                                Print Task Card
                                                                            </Link>
                                                                        }
                                                                        {/* <Link to={"/print-forecast/" + selectedIds}
                                                                            className="btn btn-success me-2"
                                                                            style={{ width: '150px' }}>
                                                                            <i className="fa fa-print" />{' '}
                                                                            Print Task Card
                                                                        </Link> */}
                                                                    </div>
                                                                    <div className="float-end">
                                                                        <ExportCSVButton {...toolkitProps.csvProps}
                                                                            className="btn btn-info me-2 mt-2"
                                                                            style={{ width: '150px' }}>
                                                                            <i className="fa fa-download" />{' '}
                                                                            Export
                                                                        </ExportCSVButton>
                                                                    </div>
                                                                    <div className="float-end">
                                                                        <Link to={`/scheduler/${id}`} style={{ width: '150px' }}
                                                                            className="btn btn-primary me-2 mt-2">
                                                                            <i className="fa fa-cloud" />{' '}
                                                                            Upload Scheduler
                                                                        </Link>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={keyField}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            selectRow={selectRow}
                                                                            defaultSorted={defaultSorted}
                                                                            expandRow={expandRow}
                                                                            filter={filterFactory()}
                                                                            filterPosition="top"
                                                                            classes={
                                                                                "table align-middle table-nowrap table-hover"
                                                                            }
                                                                            bordered={false}
                                                                            striped={false}
                                                                            responsive
                                                                            ref={node}
                                                                        />

                                                                        <Modal isOpen={modal} toggle={toggle}>
                                                                            <ModalHeader toggle={toggle} tag="h4">
                                                                                {!!isEdit ? "Last Carried Out" : "Last Carried Out"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <AvForm
                                                                                // onValidSubmit={handleSubmit}
                                                                                >
                                                                                    <Row form>
                                                                                        <Col xs={12}>
                                                                                            <Row className="mb-3">
                                                                                                <div className="text-center mb-4">
                                                                                                    {loading ?
                                                                                                        <div className="row justify-content-center">
                                                                                                            <div className="col-xl-10">
                                                                                                                <h4 className="text-primary">Processing...</h4>
                                                                                                                <Alert color="danger">
                                                                                                                    Please{" "}
                                                                                                                    <Link to="#" className="alert-link">
                                                                                                                        do not refresh the page
                                                                                                                    </Link>
                                                                                                                    {" "}and wait while we are fetching the details
                                                                                                                </Alert>
                                                                                                                <Progress
                                                                                                                    className="mt-5"
                                                                                                                    value={100}
                                                                                                                    color="success"
                                                                                                                    style={{ width: '100%' }}
                                                                                                                    animated
                                                                                                                ></Progress>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        : error !== null ?
                                                                                                            <div className="row justify-content-center">
                                                                                                                <div className="col-xl-10">
                                                                                                                    <h4 className="text-danger">{error}</h4>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            <div className="row justify-content-center">
                                                                                                                <div className="col-xl-10">
                                                                                                                    <h4 className="text-primary">Upload Task Card</h4>
                                                                                                                    <Form>
                                                                                                                        <div className="input-group rounded bg-light mt-5">
                                                                                                                            {/* <Input type="file" className="form-control" name="file" id="file"
                                                                                                                            value={taskFile} onChange={previewImage} /> */}
                                                                                                                            {/* <Label className="input-group-text" htmlFor="inputGroupFile02">Upload</Label> */}
                                                                                                                            <input
                                                                                                                                onChange={previewImage}
                                                                                                                                accept="image/jpeg"
                                                                                                                                className="form-control"
                                                                                                                                id="file"
                                                                                                                                type="file"
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        {taskFile !== null && !enableDetails ?
                                                                                                                            <div className="mb-3 mt-3" style={{ alignItems: "center" }} >
                                                                                                                                <img id="preview"
                                                                                                                                    thumbnail=""
                                                                                                                                    height="150"
                                                                                                                                    className="avatar-sm-5 rounded bg-light"
                                                                                                                                ></img>
                                                                                                                            </div>
                                                                                                                            : null
                                                                                                                        }
                                                                                                                    </Form>
                                                                                                                    {!enableDetails ?
                                                                                                                        <Button className="mt-3" color="primary" type="button" id="button-addon2"
                                                                                                                            onClick={handleGetJourneyLogs}>
                                                                                                                            <i className="bx bxs-paper-plane"></i>{' '}
                                                                                                                            Submit
                                                                                                                        </Button>
                                                                                                                        : null
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </Row>

                                                                                            <Row className="mb-3">
                                                                                                <label
                                                                                                    htmlFor="example-text-input"
                                                                                                    className="col-md-4 col-form-label"
                                                                                                >
                                                                                                    Date
                                                                                                </label>
                                                                                                <div className="col-md-8">
                                                                                                    <DatePicker
                                                                                                        className="form-control"
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        placeholderText="DD/MM/YYYY"
                                                                                                        selected={form.last_date}
                                                                                                        onChange={(date) => {
                                                                                                            setForm({ ...form, last_date: date })
                                                                                                            handleDateChange(date)
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Row>
                                                                                            <Row className="mb-3">
                                                                                                <label
                                                                                                    htmlFor="example-text-input"
                                                                                                    className="col-md-4 col-form-label"
                                                                                                >
                                                                                                    Hours
                                                                                                </label>
                                                                                                <div className="col-md-8">
                                                                                                    <AvField type="text" name="last_hours" value={form.last_hours}
                                                                                                        onChange={e => {
                                                                                                            handleChange(e)
                                                                                                        }}
                                                                                                        onPaste={(e) => {
                                                                                                            e.preventDefault()
                                                                                                            return false;
                                                                                                        }}
                                                                                                        onCopy={(e) => {
                                                                                                            e.preventDefault()
                                                                                                            return false;
                                                                                                        }}
                                                                                                    // placeholder="HHHH"
                                                                                                    // errorMessage="Enter Last carried hours"
                                                                                                    // validate={{
                                                                                                    //     required: { value: true },
                                                                                                    // }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Row>
                                                                                            <Row className="mb-3">
                                                                                                <label
                                                                                                    htmlFor="example-text-input"
                                                                                                    className="col-md-4 col-form-label"
                                                                                                >
                                                                                                    Cycles
                                                                                                </label>
                                                                                                <div className="col-md-8">
                                                                                                    <AvField type="number" name="last_cycles" value={form.last_cycles}
                                                                                                        onChange={e => {
                                                                                                            handleChange(e)
                                                                                                        }}
                                                                                                        onPaste={(e) => {
                                                                                                            e.preventDefault()
                                                                                                            return false;
                                                                                                        }}
                                                                                                        onCopy={(e) => {
                                                                                                            e.preventDefault()
                                                                                                            return false;
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <div className="text-center">
                                                                                                <button
                                                                                                    type="submit"
                                                                                                    className="btn btn-success save-user"
                                                                                                    onClick={handleSubmit}
                                                                                                >
                                                                                                    Save
                                                                                                </button>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </AvForm>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone
                                                                        {...paginationProps}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            );
                                        }}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
Forecast.propTypes = {
    history: PropTypes.object,
    match: PropTypes.any,
}

export default withRouter(Forecast);
